#indexTop{
	@if $type == sp{
		width: 100%;
		height: 250px;
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;

		&.club{
			background-image: url(../images/club/index-top.jpg);
		}

		&.about{
			background-image: url(../images/about/top-bg.jpg);
		}

		&.charity{
			background-image: url(../images/charity/top-img.jpg);
		}

		#pageMenu{
			width: 100%;
			height: 50px;
			background-color: rgba(#000, .6);
			
			h2{
				font-weight: 400;
			}

			ul{
				width: 100%;
				height: 50px;
				list-style: none;
				display: flex;
				justify-content: center;
				align-items: center;

				li{
					font-size: 14px;
					position: relative;
					white-space: nowrap;
					padding-left: 5px;
					color: #fff;

					&:not(:last-of-type){
						&:after{
							content: "/";
							display: inline-block;
							width: 12px;
							height: 12px;
						}
					}

					a{
						color: #fff;
						text-decoration: none;
						display: inline-block;
						margin-right: 12px;
					}
				}
			}
		}

		h2{
			font-size: 30px;
			color: #fff;
			width: 90%;
			height: 250px - 50px;
			margin: 0 auto;
			display: flex;
			align-items: center;
		}
	}

	@if $type == pc{
		width: 100%;
		height: 355px;
		background-position: center top;
		background-size: cover;
		
		&.club{
			background-image: url(../images/club/index-top.jpg);
		}
		
		&.about{
			background-image: url(../images/about/top-bg.jpg);
		}
		
		&.charity{
			background-image: url(../images/charity/top-img.jpg);
		}

		&.noImg{
			height: 100px;
		}
		
		#pageMenu{
			width: 100%;
			height: 72px;
			background-color: rgba(#000, .6);
			font-weight: 400;
			
			h2{
				font-weight: 400;
			}
			
			ul{
				width: 100%;
				height: 72px;
				list-style: none;
				display: flex;
				justify-content: center;
				align-items: center;

				li{
					font-size: 18px;
					position: relative;
					white-space: nowrap;
					padding-left: 18px;
					color: #fff;
					
					&:not(:last-of-type){
						&:after{
							content: "/";
							display: inline-block;
							width: 18px;
							height: 18px;
						}
					}

					a{
						color: #fff;
						text-decoration: none;
						display: inline-block;
						margin-right: 18px;
						transition: all .3s;
						
						&:hover{
							color: $green;
						}
					}
				}
			}
		}
		
		h2{
			font-size: 34px;
			color: #fff;
			max-width: 1200px;
			width: 95%;
			height: 355px - 72px;
			margin: 0 auto;
			display: flex;
			align-items: center;
		}
	}
}


#clubTop{
	@if $type == sp{
		width: 90%;
		margin: 0 auto;
		
		#photos{
			display: none;
		}

		.point{
			width: 100%;
			background-color: #f2f2f2;
			box-shadow: 3px	3px 0 $green;
			padding: 0 5px 10px;
			margin-top: 30px;

			.num{
				width: 40px;
				height: 24px;
				font-size: 24px;
				position: absolute;
				color: $green;
				top: -5px;
				left: -10px;
				position: relative;
			}
			

			h3{
				font-size: 20px;
				text-align: center;
				position: relative;
				padding-bottom: 15px;

				&:after{
					content: "";
					display: block;
					width: 200px;
					height: 2px;
					background-color: $green;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}

			p{
				margin-top: 15px;
				font-size: 14px;
				line-height: 1.8;
			}

			&#point1{
				top: -50px;
				right: 10%;
			}

			&#point2{
				bottom: 100px;
				left: 5%;
			}
		}
	}

	@if $type == pc{
		width: 100%;
		height: 1000px;
		position: relative;
		
		#photos{
			width: 100%;
			height: 1000px;
			overflow: hidden;
			position: relative;
			list-style: none;
			
		//	display: flex;
		//	flex-wrap: wrap;
			
			li{
			//	width: 25%;
			//	height: 25%;
				position: absolute;
				opacity: 0;
				transition: all .8s;
				
				&.view{
					opacity: 1;
					transform: rotate()
				}
			}
		}
		
		.point{
			width: 570px;
		//	height: 270px;
			background-color: #f2f2f2;
			box-shadow: 3px	3px 0 $green;
			padding: 40px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			position: absolute;
			
			.num{
				width: 40px;
				height: 24px;
				font-size: 24px;
				position: absolute;
				color: $green;
				top: 20px;
				left: 20px;
			}
			
			h3{
				font-size: 20px;
				text-align: center;
				position: relative;
				padding-bottom: 15px;
				
				&:after{
					content: "";
					display: block;
					width: 200px;
					height: 2px;
					background-color: $green;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}
			
			p{
				margin-top: 15px;
				font-size: 14px;
				line-height: 1.8;
			}
			
			&#point1{
				top: -50px;
				right: 10%;
			}
			
			&#point2{
				top: 300px;
				left: 10%;
			}
			
			&#point3{
				bottom: 30px;
				right: 5%;
			}
		}
	}
}


#clubService{
	@if $type == sp{
		width: 90%;
		margin: 40px auto 0;

		h2{
			font-size: 22px;
			line-height: 1;
			color: $green;
			text-align: center;
			font-weight: 400;

			.sten{
				font-size: 40px;
				display: inline-block;
				padding: 0 10px;
			}

			.ja{
				color: #000;
				font-size: 14px;
				display: block;
				margin: 10px auto 0;
			}
		}

		ul{
			list-style: none;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			margin-top: 40px;

			li{
				background-color: $green;
				color: #fff;
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
				width: 50%;
				padding: 40px 15px 25px;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0;
				transition: all .6s;

				&:nth-of-type(1){transition-delay: 0}
				&:nth-of-type(2){transition-delay: .1s}
				&:nth-of-type(3){transition-delay: 0}
				&:nth-of-type(4){transition-delay: .1s}
				&:nth-of-type(5){transition-delay: 0}
				&:nth-of-type(6){transition-delay: .1s}
				&:nth-of-type(7){transition-delay: 0}
				&:nth-of-type(8){transition-delay: .1s}

				&.view{
					opacity: 1;
				}

				.num{
					width: 30px;
					height: 30px;
					font-size: 20px;
					position: absolute;
					top: 5px;
					right: 5px;
					border-bottom: 2px solid #fff;
				}

				p{
					font-size: 16px;
					font-weight: 700;

					span{
						display: inline-block;
						font-size: 12px;
					}
				}
			}
		}
	}

	@if $type == pc{
		max-width: 1200px;
		width: 95%;
		margin: 0 auto;
		
		h2{
			font-size: 34px;
			line-height: 1;
			color: $green;
			text-align: center;
			font-weight: 400;
			
			.sten{
				font-size: 60px;
				display: inline-block;
				padding: 0 10px;
			}
			
			.ja{
				color: #000;
				font-size: 18px;
				display: block;
				margin: 10px auto 0;
			}
		}
		
		ul{
			list-style: none;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			margin-top: 60px;
			
			li{
				background-color: $green;
				color: #fff;
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
				width: calc(100% / 3);
				padding: 80px 30px;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				opacity: 0;
				transition: all .6s;
				
				&:nth-of-type(1){transition-delay: 0}
				&:nth-of-type(2){transition-delay: .1s}
				&:nth-of-type(3){transition-delay: .2s}
				&:nth-of-type(4){transition-delay: .3s}
				&:nth-of-type(5){transition-delay: .4s}
				&:nth-of-type(6){transition-delay: .5s}
				&:nth-of-type(7){transition-delay: .6s}
				&:nth-of-type(8){transition-delay: .7s}
				
				&.view{
					opacity: 1;
				}
				
				.num{
					width: 45px;
					height: 45px;
					font-size: 34px;
					position: absolute;
					top: 15px;
					right: 15px;
					border-bottom: 2px solid #fff;
				}
				
				p{
					font-size: 18px;
					font-weight: 700;
					
					span{
						display: inline-block;
						font-size: 14px;
					}
				}
			}
		}
	}
}

#division{
	@if $type == sp{
		margin-top: 40px;
		overflow: hidden;
		position: relative;
		padding-top: 150px;

		#devLogo{
			width: 80px;
			height: 82px;
			position: absolute;
			top: 34px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		#screen{
			width: 100%;
			height: 100vh;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;

			.slideImg{
				width: 100%;
				height: 100vh;
				position: relative;
				overflow: hidden;
				background-size: cover;
				background-position: 50% 50%;
				background-attachment: fixed;
				transform: scale(1);
				transition: all 20s;
					

				&:nth-of-type(1){background-image: url(../images/home/top1.jpg);}
				&:nth-of-type(2){background-image: url(../images/home/2_aux.jpg);}
				&:nth-of-type(3){background-image: url(../images/home/4_iiwan.jpg);}
				&:nth-of-type(4){background-image: url(../images/home/4_upcon.jpg);}
			}
		}

		h2{
			font-size: 22px;
			text-align: center;
			color: #fff;
			background-color: #000;
			padding-top: 30px;
		}

		.members{
			background-color: #000;
			margin: 0 auto;
			padding-top: 40px;

			&:last-of-type{
				padding-bottom: 50px;
			}

			dl{
				width: 90%;
				margin: 0 auto;
				background-image: url(../images/club/icon-dev.png);
				background-repeat: no-repeat;
				font-size: 16px;
				color: #fff;
				font-weight: 700;
				padding-left: 40px;
				min-height: 36px;

				dt{
					position: relative;
					padding-right: 40px;
				}

				dd{
					font-size: 14px;
					font-weight: 500;
				}
			}

			.priceTable{
				width: 90%;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				padding-top: 15px;
				background-color: #000;
			}

			.price{
				background-color: #fff;
				margin: 0 2px 2px 0;
				width: 100%;
				font-size: 18px;
				font-weight: 700;
				text-align: center;

				&:nth-of-type(2){transition-delay: .1s}

				.sten.price{
					font-size: 40px;

					.op0{
						opacity: 0;
					}
				}
			}

			ul{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				list-style: none;

				li{
					background-color: #fff;
					margin: 0 2px 2px 0;
					width: calc((100% / 4) - 2px);
					font-size: 10px;
					text-align: center;
					padding: 10px 0;

					span{
						display: block;
						width: 30px;
						height: 30px;
						margin: 5px auto 0;
					}
				}
			}
		}
	}

	@if $type == pc{
	//	background-color: #000;
	//	background-image: url(../images/club/tokuten.jpg);
	//	background-repeat: no-repeat;
	//	background-position: center top;
	//	padding-top: 300px;
	//	padding-bottom: 50px;
		margin-top: 60px;
		overflow: hidden;
		position: relative;
	//	padding-top: 250px;
		
		#devLogo{
			width: 80px;
			height: 82px;
			position: absolute;
			top: 84px;
			left: 0;
			right: 0;
			margin: 0 auto;
			z-index: 100;
		}
		
		#screen{
			width: 100%;
			height: 250px;
		//	position: absolute;
		//	top: 0;
		//	left: 0;
		//	z-index: -1;
			
			.slideImg{
				width: 100%;
				height: 250px;
				position: relative;
				overflow: hidden;
				background-size: cover;
				background-position: 50% 50%;
				background-attachment: fixed;
				
				img{
					transition: all 20s;
				}
				/*
				&:nth-of-type(1){background-image: url(../images/home/top1.jpg);}
				&:nth-of-type(2){background-image: url(../images/home/2_aux.jpg);}
				&:nth-of-type(3){background-image: url(../images/home/4_iiwan.jpg);}
				&:nth-of-type(4){background-image: url(../images/home/4_upcon.jpg);}
				*/
				&.CurrentImg{
					
					img{
						max-width: none;
						max-height: none;
						transform: scale(1.5);
					}
				}
			}
		}
		
		h2{
			font-size: 34px;
			text-align: center;
			color: #fff;
			background-color: #000;
			padding-top: 70px;
		}
		
		.members{
			background-color: #000;
			margin: 0 auto;
			padding-top: 40px;
			
			&:last-of-type{
				padding-bottom: 120px;
			}
			
			dl{
				max-width: 1200px;
				width: 95%;
				margin: 0 auto;
				background-image: url(../images/club/icon-dev.png);
				background-repeat: no-repeat;
				display: flex;
				font-size: 18px;
				color: #fff;
				font-weight: 700;
				padding-left: 40px;
				min-height: 36px;
				
				dt{
					position: relative;
					padding-right: 40px;
				//	width: 150px;
					
					&:after{
						width: 2px;
						height: 40px;
						content: "";
						display: block;
						background-color: #fff;
						transform: rotate(35deg);
						position: absolute;
						right: 10px;
						top: -5px;
					}
				}
				
				dd{
					flex: 1;
					padding-left: 20px;
				}
			}
			
			.priceTable{
				max-width: 1200px;
				width: 95%;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				padding-top: 15px;
				background-color: #000;
			}
			
			.price{
				background-color: #fff;
				margin: 0 2px 2px 0;
				width: calc(50% - 2px);
				font-size: 18px;
				font-weight: 700;
				text-align: center;
				padding: 10px 0;
				
				&:nth-of-type(2){transition-delay: .1s}
				
				.sten.price{
					font-size: 40px;
					
					.op0{
						opacity: 0;
					}
				}
			}
			
			ul{
				width: 100%;
				display: flex;
				list-style: none;
				
				li{
					background-color: #fff;
					margin: 0 2px 2px 0;
					width: calc((100% / 8) - 2px);
					font-size: 12px;
					text-align: center;
					padding: 10px 0;

					&:nth-of-type(1){transition-delay: 0}
					&:nth-of-type(2){transition-delay: .1s}
					&:nth-of-type(3){transition-delay: .2s}
					&:nth-of-type(4){transition-delay: .3s}
					&:nth-of-type(5){transition-delay: .4s}
					&:nth-of-type(6){transition-delay: .5s}
					&:nth-of-type(7){transition-delay: .6s}
					&:nth-of-type(8){transition-delay: .7s}

					span{
						display: block;
						width: 30px;
						height: 30px;
						margin: 5px auto 0;
					}
				}
			}
		}
	}
}

#seminar{
	@if $type == sp{

		#seminarHead{
			width: 100%;
			min-height: 100px;
			position: relative;
			padding-top: 40px;

			#svgContainer{
				width: 320px;
				height: 40px;
				position: absolute;
				top: 5px;
				right: 0;
				z-index: 0;
				text-align: right;

				svg{
					width: 320px;
					height: 40px;
				}
			}

			#headObject{
				max-width: 700px;
				width: 95%;
				margin: 0 auto;
				position: relative;
				z-index: 5;

				h2{
					font-size: 34px;
					text-align: center;

					span{
						font-size: 16px;
						display: block;
						margin: 0 auto;
					}
				}
				p{
					font-size: 15px;
					line-height: 1.8;
					margin-top: 30px;
				}
			}
		}
	}

	@if $type == pc{
		margin-top: 60px;
		
		#seminarHead{
			width: 100%;
			min-height: 100px;
			position: relative;
			
			#svgContainer{
				width: 50%;
				height: 100px;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 0;
				
				svg{
					width: 100%;
				}
			}
			
			#headObject{
				max-width: 700px;
				width: 95%;
				margin: 0 auto;
				position: relative;
				z-index: 5;
				
				h2{
					font-size: 34px;
					text-align: center;
					
					span{
						font-size: 16px;
						display: block;
						margin: 0 auto;
					}
				}
				p{
					font-size: 15px;
					line-height: 1.8;
					margin-top: 30px;
				}
			}
		}
	}
	
	#seminarInfo{
		@if $type == sp{
			display: flex;
			flex-direction: column;

			.img{
				order: 1;
				width: 50%;
				position: relative;
				overflow: hidden;

				img{
					display: block;
					min-width: 100%;
					min-height: 100%;
					width: auto;
					height: auto;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
			}

			.txt{
				order: 0;
				background-color: #000;
				color: #fff;
				width: 100%;
				padding: 20px;
				line-height: 1.3;

				h2{
					font-size: 26px;
					font-weight: 700;
					line-height: 1;
				}

				.date{
					font-size: 22px;
					font-weight: 700;
					line-height: 1.4;
					margin-top: 10px;
				}

				.conditions{
					font-size: 16px;
					margin-top: 15px;
				}

				.more{
					width: 100%;
					height: 40px;
					background-color: #fff;
					color: #000;
					text-decoration: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					position: relative;
					margin-top: 40px;

					&:after{
						content: "";
						display: block;
						width: 18px;
						height: 18px;
						background-image: url(../images/member/arrow-black.png);
						position: absolute;
						top: 50%;
						right: 15px;
						transform: translateY(-9px);
					}
				}

				.download{

					a{
						font-size: 14px;
						margin-top: 10px;
						display: inline-block;
						color: #fff;
						text-decoration: none;
						position: relative;

						&:after{
							content: "";
							display: inline-block;
							width: 21px;
							height: 22px;
							background-image: url(../images/member/icon-pdf-white.png);
							position: absolute;
						}
					}
				}
			}
		}

		@if $type == pc{
			display: flex;
			max-width: 1200px;
			width: 95%;
			margin: 40px auto 0;

			.img{
				width: 50%;
				position: relative;
				overflow: hidden;

				img{
					display: block;
					min-width: 100%;
					min-height: 100%;
					width: auto;
					height: auto;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
			}

			.txt{
				background-color: #000;
				color: #fff;
				width: 50%;
				padding: 30px 30px 30px 80px;
				line-height: 1.3;
				transition-delay: .2s;

				h2{
					font-size: 18px;
					font-weight: 700;
					line-height: 1;
					color: #404040;
				}

				.date{
					font-size: 70px;
					font-weight: 400;
					line-height: 1.4;
					margin-top: 5px;
				}

				.conditions{
					font-size: 18px;
					margin-top: 5px;
				}

				.more{
					width: 247px;
					height: 60px;
					background-color: #fff;
					color: #000;
					text-decoration: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					position: relative;
					margin-top: 80px;

					&:after{
						content: "";
						display: block;
						width: 18px;
						height: 18px;
						background-image: url(../images/member/arrow-black.png);
						position: absolute;
						top: 21px;
						right: 15px;
					}
				}

				.download{

					a{
						font-size: 16px;
						margin-top: 20px;
						display: inline-block;
						color: #fff;
						text-decoration: none;
						position: relative;

						&:after{
							content: "";
							display: inline-block;
							width: 21px;
							height: 22px;
							background-image: url(../images/member/icon-pdf-white.png);
							position: absolute;
							top: -5px;
							right: -25px;
						}
					}
				}
			}
		}
	}
	
	#special{
		@if $type == sp{
			width: 90%;
			margin: 40px auto 0;
		}

		@if $type == pc{
			max-width: 1200px;
			width: 95%;
			margin: 60px auto 0;
		}
	}
	
	#fiveContents{
		@if $type == sp{
			width: 100%;
			list-style: none;
			

			li{
				width: 100%;
				padding: 20px;
				border: 1px solid $green;
				margin-top: 20px;

				/*
				&:nth-of-type(1){transition-delay: 0}
				&:nth-of-type(2){transition-delay: .1s}
				&:nth-of-type(3){transition-delay: .2s}
				&:nth-of-type(4){transition-delay: .3s}
				&:nth-of-type(5){transition-delay: .4s}
				*/

				&:not(:last-of-type){
					border-right: 1px solid $green;
				}

				.img{
					width: 100%;

					img{
						width: 100%;
						height: auto;
					}
				}

				.txt{
					margin-top: 20px;

					h3{
						font-size: 18px;
						color: $green;
					}

					p{
						margin-top: 10px;
						font-size: 15px;
						line-height: 1.6;
					}
				}
			}
		}

		@if $type == pc{
			width: 100%;
			list-style: none;
			display: flex;
			border: 2px solid $green;
			
			li{
				width: 20%;
				padding: 20px;

				&:nth-of-type(1){transition-delay: 0}
				&:nth-of-type(2){transition-delay: .1s}
				&:nth-of-type(3){transition-delay: .2s}
				&:nth-of-type(4){transition-delay: .3s}
				&:nth-of-type(5){transition-delay: .4s}

				&:not(:last-of-type){
					border-right: 2px solid $green;
				}
				
				.img{
					width: 100%;
					
					img{
						width: 100%;
						height: auto;
					}
				}

				.txt{
					margin-top: 20px;

					h3{
						font-size: 18px;
						color: $green;
					}

					p{
						margin-top: 10px;
						font-size: 15px;
						line-height: 1.6;
					}
				}
			}
		}
	}
	
	.info{
		@if $type == sp{
			margin-top: 20px;

			ul{
				list-style: none;
				font-size: 14px;
			}

			.contact{

				a{
					width: 100%;
					padding: 20px 0;
					background-color: $green;
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: 2px 3px 0 #b4e585;
					color: #fff;
					text-decoration: none;
					font-size: 18px;
					position: relative;

					&:after{
						content: "";
						display: block;
						width: 15px;
						height: 15px;
						background-image: url(../images/second/btn-arrow.png);
						position: absolute;
						top: 50%;
						right: 15px;
						transform: translateY(-7px);
					}
				}
			}
		}

		@if $type == pc{
			display: flex;
			justify-content: space-between;
			margin-top: 30px;
			
			ul{
				list-style: none;
				font-size: 12px;
				line-height: 2;
			}
			
			.contact{
				
				a{
					width: 487px;
					height: 70px;
					background-color: $green;
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: 2px 3px 0 #b4e585;
					color: #fff;
					text-decoration: none;
					font-size: 18px;
					transition: all .3s;
					position: relative;
					
					&:after{
						content: "";
						display: block;
						width: 15px;
						height: 15px;
						background-image: url(../images/second/btn-arrow.png);
						position: absolute;
						top: 50%;
						right: 15px;
						transform: translateY(-7px);
					}
					
					&:hover{
						box-shadow: 4px 6px 0 #b4e585;
						opacity: .8;
					}
				}
			}
		}
	}
}


#rules{
	@if $type == sp{
		margin-top: 40px;

		h2{
			text-align: center;
			font-size: 22px;
			font-weight: 400;
			background-color: #000;
			color: #fff;
			line-height: 1;
			padding: 15px 0;
			text-transform: uppercase;
		}

		#rulesInner{
			width: 90%;
			margin: 0 auto 0;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			dl{
				width: 48%;
				margin-top: 30px;

				dt{
					font-size: 16px;
					line-height: 1;
					text-transform: uppercase;

					span{
						font-size: 20px;
						color: $green;
						display: inline-block;
						padding-bottom: 2px;
						border-bottom: 1px solid $green;
					}
				}

				dd{
					font-size: 16px;
					line-height: 1.6;
					margin-top: 15px;
					font-weight: 700;
				}
			}
		}
	}
	
	@if $type == pc{
		margin-top: 60px;
		
		h2{
			text-align: center;
			font-size: 30px;
			font-weight: 400;
			background-color: #000;
			color: #fff;
			line-height: 1;
			padding: 20px 0;
			text-transform: uppercase;
		}
		
		#rulesInner{
			max-width: 1200px;
			width: 95%;
			margin: 0 auto 0;
			display: flex;
			flex-wrap: wrap;
			
			dl{
				width: 25%;
				margin-top: 40px;
				
				dt{
					font-size: 18px;
					line-height: 1;
					text-transform: uppercase;
					
					span{
						font-size: 30px;
						color: $green;
						display: inline-block;
						padding-bottom: 2px;
						border-bottom: 1px solid $green;
					}
				}
				
				dd{
					font-size: 18px;
					line-height: 1.6;
					margin-top: 15px;
					font-weight: 700;
				}
			}
		}
	}
	&.seminarRules{
		@if $type == sp{
		margin-bottom: 40px;
		border: 1px solid #000;
		padding-bottom: 40px;
		}
		@if $type == pc{
		margin-bottom: 60px;
		border: 1px solid #000;
		padding-bottom: 60px;
		}
	}
}




#jobMap{
	background-color: #e5e5e5;
	
	@if $type == sp{
		border-top: 5px dashed #000;
		border-bottom: 5px dashed #000;
		width: 100%;
		position: relative;
		margin-top: 40px;
		padding-top: 3px;
		padding-bottom: 30px;

		h2{
			background-color: #000;
			color: #fff;
			font-size: 14px;
			margin: 0 auto;
			position: relative;
			text-align: center;
			padding: 15px 10px;

			.eng, .sten{
				font-size: 20px;
				margin-left: 10px;
				display: inline-block;
				white-space: nowrap;
			}

			.icon{
				display: none;
			}
		}

		#jpmap{
			display: none;
			
			width: 399px;
			height: 487px;
			background-image: url(../images/club/jobmap-jp.png);
			position: absolute;
			top: 233px;
			left: 0;
			right: 0;
			margin: 0 auto;

			.flag{
				width: 20px;
				height: 32px;
				position: absolute;
				overflow: hidden;

				img{
					display: block;
					width: 20px;
					height: 32px;
					position: absolute;
					left: 0;
					top: 32px;
					transition: all .4s;
				}

				&.isView{

					img{
						top: 0;
					}
				}

				&#flag1{
					top: 168px;
					left: 272px;
				}

				&#flag2{
					top: 214px;
					left: 243px;

					img{
						transition-delay: .2s;
					}
				}

				&#flag3{
					top: 271px;
					left: 249px;

					img{
						transition-delay: .4s;
					}
				}

				&#flag4{
					top: 312px;
					left: 160px;

					img{
						transition-delay: .6s;
					}
				}

				&#flag5{
					top: 345px;
					left: 55px;

					img{
						transition-delay: .8s;
					}
				}
			}
		}

		.diagram{
			width: 90%;
			//border: 2px solid $green;
			//background-color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			transform: scale(0);
			transition: all .4s;
			margin: 30px auto 0;
			border: 2px solid $green;
			background-color: #000;

			&.isView{
				transform: scale(1);
			}


			&>img{
				opacity: .7;
				width: 100%;
				height: auto;
			}

			h3{
				font-size: 22px;
				line-height: 1;
				color: #fff;
				width: 100%;
				height: 18px;
				text-align: center;
				position: absolute;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto 0;
				font-weight: 400;
				display: table;
			}

			&:after{
				display: block;
				content: "";
				width: 0;
				height: 0;
				border-bottom: 10px solid $green;
				border-right: 10px solid $green;
				border-top: 10px solid transparent;
				border-left: 10px solid transparent;
				position: absolute;
				bottom: 0;
				right: 0;
			}

			.symbol{
				width: 90px;
				height: 90px;
				position: absolute;
				top: -5px;
				left: -5px;
				z-index: 10;

				img{
					width: 100%;
					height: auto;
				}
			}

			&#iwate{
				top: 295px;
				left: calc(50% + 238px);
			}

			&#yamagata{
				top: 540px;
				left: calc(50% + 435px);
				transition-delay: .2s;
			}

			&#tokyo{
				top: 615px;
				left: calc(50% + 108px);
				transition-delay: .4s;
			}

			&#osaka{
				top: 295px;
				right: calc(50% + 115px);
				transition-delay: .6s;
			}

			&#fukuoka{
				top: 555px;
				right: calc(50% + 300px);
				transition-delay: .8s;
			}
		}
	}

	@if $type == pc{
		border-top: 5px dashed #000;
		border-bottom: 5px dashed #000;
		height: 830px;
		width: 100%;
		position: relative;
		margin-top: 60px;
		padding-top: 65px;
		
		h2{
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #000;
			color: #fff;
			width: 820px;
			height: 70px;
			font-size: 16px;
			margin: 0 auto;
			position: relative;
			
			&:before{
				content: "";
				display: block;
				width: 0;
				height: 70px;
				border-left: 30px solid transparent;
				border-bottom: 70px solid #000;
				position: absolute;
				bottom: 0;
				left: -30px;
			}
			
			&:after{
				content: "";
				display: block;
				width: 0;
				height: 70px;
				border-right: 30px solid transparent;
				border-top: 70px solid #000;
				position: absolute;
				top: 0;
				right: -30px;
			}
			
			.eng, .sten{
				font-size: 34px;
				margin-left: 10px;
			}
			
			.icon{
				width: 20px;
				height: 32px;
				margin-left: 10px;
			}
		}
		
		#jpmap{
			width: 399px;
			height: 487px;
			background-image: url(../images/club/jobmap-jp.png);
			position: absolute;
			top: 233px;
			left: 0;
			right: 0;
			margin: 0 auto;
			
			.flag{
				width: 20px;
				height: 32px;
				position: absolute;
				overflow: hidden;
				
				img{
					display: block;
					width: 20px;
					height: 32px;
					position: absolute;
					left: 0;
					top: 32px;
					transition: all .4s;
				}
				
				&.isView{
					
					img{
						top: 0;
					}
				}
				
				&#flag1{
					top: 168px;
					left: 272px;
				}
				
				&#flag2{
					top: 214px;
					left: 243px;
					
					img{
						transition-delay: .2s;
					}
				}
				
				&#flag3{
					top: 271px;
					left: 249px;

					img{
						transition-delay: .4s;
					}
				}
				
				&#flag4{
					top: 312px;
					left: 160px;

					img{
						transition-delay: .6s;
					}
				}
				
				&#flag5{
					top: 345px;
					left: 55px;

					img{
						transition-delay: .8s;
					}
				}
			}
		}

		.diagram{
			width: 207px;
			height: 154px;
			border: 2px solid $green;
			position: absolute;
			background-color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			transform: scale(0);
			transition: all .4s;

			&.isView{
				transform: scale(1);
			}

			
			&>img{
				opacity: .7;
			}
			
			h3{
				font-size: 18px;
				line-height: 1;
				color: #fff;
				width: 100%;
				height: 18px;
				text-align: center;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				font-weight: 400;
			}

			&:after{
				display: block;
				content: "";
				width: 0;
				height: 0;
				border-bottom: 10px solid $green;
				border-right: 10px solid $green;
				border-top: 10px solid transparent;
				border-left: 10px solid transparent;
				position: absolute;
				bottom: 0;
				right: 0;
			}

			.symbol{
				width: 130px;
				height: 130px;
				position: absolute;
				top: -65px;
				left: -65px;
				z-index: 10;

				img{
					width: 100%;
					height: auto;
				}
			}

			&#iwate{
				top: 295px;
				left: calc(50% + 238px);
			}

			&#yamagata{
				top: 540px;
				left: calc(50% + 435px);
				transition-delay: .2s;
			}

			&#tokyo{
				top: 615px;
				left: calc(50% + 108px);
				transition-delay: .4s;
			}

			&#osaka{
				top: 295px;
				right: calc(50% + 115px);
				transition-delay: .6s;
			}

			&#fukuoka{
				top: 555px;
				right: calc(50% + 300px);
				transition-delay: .8s;
			}
		}
	}
}


/* ---------------- join us ------------- */

.sectJoinUs{
	@if $type == sp{

		h2{
			font-size: 22px;
			font-weight: 400;
			text-align: center;
			margin-top: 40px;

			.ja{
				font-size: 15px;
				text-align: center;
				display: block;
			}
		}

		&.steps{
			margin-top: 40px;

			h2{
				padding-bottom: 30px;
			}

			h3{
				width: 90%;
				margin: 0 auto;
				background-color: #efefef;
				font-size: 16px;
				font-weight: 700;
				padding: 5px 10px;
				line-height: 1;

				.eng{
					display: inline-block;
					background-color: $green;
					padding: 5px 40px;
					color: #fff;
					font-weight: 400;
					margin-right: 30px;
				}
			}

			.stepList{
				background-color: #e5e5e5;
				padding: 10px;
				width: 90%;
				margin: 0 auto;
				list-style: none;

				li{
					background-color: #fff;
					position: relative;
					font-size: 16px;
					font-weight: 700;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 15px 10px 10px;

					.num{
						display: block;
						width: 25px;
						height: 25px;
						position: absolute;
						font-size: 20px;
						color: $green;
						line-height: 1;
						border-bottom: 1px solid $green;
						top: -10px;
						left: 0px;
					}

					&:not(:last-of-type){
						margin-bottom: 50px;

						&:after{
							content: "";
							display: block;
							width: 15px;
							height: 15px;
							transform: rotate(45deg);
							border-bottom: 4px solid #000;
							border-right: 4px solid #000;
							position: absolute;
							bottom: -30px;
							right: 0;
							left: 0;
							margin: 0 auto;
						}
					}
				}
			}

			.cap{
				background-color: #e5e5e5;
				padding: 10px;
				width: 90%;
				margin: 0 auto;
				
				ul{
					list-style: none;
					font-size: 14px;
				}
			}
		}
	}

	@if $type == pc{
		
		h2{
			font-weight: 700;
			font-size: 36px;
			text-align: center;
			margin-top: 60px;
			
			.ja{
				font-size: 16px;
				text-align: center;
				display: block;
			}
		}
		
		&.steps{
			margin-top: 40px;
			
			h2{
				padding-bottom: 30px;
			}
			
			h3{
				max-width: 1200px;
				width: 95%;
				margin: 0 auto;
				background-color: #efefef;
				font-size: 24px;
				font-weight: 700;
				padding: 20px 40px;
				line-height: 1;
				
				.eng{
					display: inline-block;
					background-color: $green;
					padding: 5px 40px;
					color: #fff;
					font-weight: 400;
					margin-right: 30px;
				}
			}
			
			.stepList{
				background-color: #e5e5e5;
				padding: 60px;
				max-width: 1200px;
				width: 95%;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				list-style: none;
				
				li{
					flex: 1;
					background-color: #fff;
					position: relative;
				//	height: 180px;
					font-size: 18px;
					font-weight: 700;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 40px;
					
					.num{
						display: block;
						width: 36px;
						height: 40px;
						position: absolute;
						font-size: 36px;
						color: $green;
						line-height: 1;
						border-bottom: 1px solid $green;
						top: -15px;
						right: -10px;
						font-weight: 400;
					}
					
					&:not(:last-of-type){
						margin-right: 50px;

						&:after{
							content: "";
							display: block;
							width: 15px;
							height: 15px;
							transform: rotate(45deg);
							border-top: 4px solid #000;
							border-right: 4px solid #000;
							position: absolute;
							top: 50%;
							right: -30px;
						}
					}
				}
			}
			
			.cap{
				background-color: #e5e5e5;
				padding: 0px 60px 60px 60px;
				max-width: 1200px;
				width: 95%;
				margin: -45px auto 0;
				font-size: 12px;
				
				ul{
					list-style: none;
				}
			}
		}
	}
}

#kiyaku{
	@if $type == sp{

		#kiyakuScroll{
			width: 90%;
			height: 240px;
			border: 2px solid #000;
			margin: 30px auto 0;
			overflow-y: scroll;

			.kiyakuInner{
				padding: 15px;

				p{
					font-size: 12px;
				}
			}
		}
	}

	@if $type == pc{
		
		#kiyakuScroll{
			max-width: 1200px;
			width: 95%;
			height: 240px;
			border: 2px solid #000;
			margin: 30px auto 0;
			overflow-y: scroll;
			
			.kiyakuInner{
				padding: 30px 50px;
				
				p{
					font-size: 12px;
				}
			}
		}
	}
}

#course{
	width: 90%;
	margin: 40px auto 0;
	
	p{
		text-align: center;
		margin-top: 30px;
	}
}

#course, #modal{
	.btns{
		@if $type == sp{
			padding: 30px 0;

			.button{
				width: 100%;
				min-height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 16px;
				font-weight: 700;
				background-color: $green;
				margin: 20px 0 0 0;
				box-shadow: 0 5px 0 #a2ce77;
				position: relative;
				transition: all .3s;
				cursor: pointer;

				&:before{
					content: "";
					display: block;
					width: 20px;
					height: 20px;
					background-image: url(../images/club/btn-arrow.png);
					background-size: cover;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 5px;
				}
			}
		}
	

		@if $type == pc{
			display: flex;
			justify-content: center;
			padding: 50px 0;
			
			.button{
				max-width: 485px;
				width: 40%;
				min-height: 70px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 18px;
				font-weight: 700;
				background-color: #fff;
				margin: 0 20px;
				box-shadow: 0 5px 0 #a2ce77;
				position: relative;
				transition: all .3s;
				cursor: pointer;
				box-shadow: 4px 6px 0 #b4e585;
				border: 2px solid $green;
				z-index: 1;
				
				&:after{
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					right: 0;
					z-index: -1;
					background-color: $green;
					transition: all .3s;
				}
				
				&:hover{
					color: $green;
					
					&:after{
						width: 0;
					}
				}
			}
		}
	}
}

#modal{
	opacity: 0;
	transition: all .3s;
	width: 100%;
	height: 100vh;
	background-color: rgba(#000, .7);

	@if $type == sp{
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		z-index: -999;

		&.isShow{
			align-items: center;
			opacity: 1;
			z-index: 999;
		}

		.modInner{
			background-color: #fff;
			position: relative;
			width: 90%;
			margin: 0 auto;
			padding: 40px 0;
			z-index: 9999;
			
			.btns{
				width: 90%;
				margin: 0 auto;
			}

			.closeBtn{
				width: 33px;
				height: 33px;
				position: absolute;
				top: 10px;
				right: 10px;
				cursor: pointer;
			}

			h2{
				font-size: 22px;
				text-align: center;
			}

			p{
				text-align: center;
				margin-top: 30px;
			}
		}
	}

	@if $type == pc{
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		z-index: -999;

		&.isShow{
			align-items: center;
			opacity: 1;
			z-index: 999;
		}
		
		.modInner{
			background-color: #fff;
			position: relative;
			max-width: 1200px;
			width: 95%;
			margin: 0 auto;
			padding: 40px 0;
			z-index: 9999;
			
			.closeBtn{
				width: 33px;
				height: 33px;
				position: absolute;
				top: 10px;
				right: 10px;
				cursor: pointer;
			}
			
			h2{
				font-size: 34px;
				text-align: center;
			}

			p{
				text-align: center;
				margin-top: 30px;
			}
		}
	}
}


.sample{
	@if $type == sp{
	}

	@if $type == pc{
	}
}
