@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/*日本語用（）*/
/*英語用*/
@media screen and (max-width: 650px) {
  s
#secondMain footer {
    margin-top: 120px; }
  #secondHeader {
    padding-top: 85px; }
    #secondHeader #pageName {
      background-color: #1b1b1b;
      height: 42px;
      color: #fff;
      font-size: 18px;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
  header #menuBtn .txt {
    color: #888788; }
  /* ----------------------- works ----------------- */
  .secondRead {
    padding: 50px 0 0; }
    .secondRead h1, .secondRead .readTitle {
      font-size: 22px;
      text-align: center; }
    .secondRead p {
      width: 90%;
      margin: 30px auto 0;
      font-size: 16px;
      line-height: 1.8; }
    .secondRead .eng {
      display: block;
      font-size: 34px; }
    .secondRead .ja {
      display: block;
      font-size: 16px; }
  .worksDetail {
    width: 90%;
    margin: 60px auto 0; }
    .worksDetail .thumbnail .slick-track {
      transform: none !important;
      width: 102% !important;
      text-align: right;
      margin-left: -5px; }
    .worksDetail .thumbnail .slick-slide {
      overflow: hidden;
      cursor: pointer;
      margin: 10px 5px 0px;
      width: calc(20% - 10px) !important; }
      .worksDetail .thumbnail .slick-slide img {
        width: 200px;
        position: relative;
        margin-left: -50px; }
        .worksDetail .thumbnail .slick-slide img:hover {
          opacity: 0.75; }
    .worksDetail .slide.slick-dotted.slick-slider {
      margin-bottom: 0; }
    .worksDetail .slide .slideItem {
      width: 100%;
      overflow: hidden;
      position: relative;
      height: calc(100vw * 0.6); }
      .worksDetail .slide .slideItem img {
        width: auto;
        height: calc(100vw * 0.6);
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%); }
    .worksDetail .slide .slick-dots {
      bottom: 15px;
      text-align: right;
      padding-right: 15px; }
      .worksDetail .slide .slick-dots li {
        width: 9px;
        height: 9px; }
        .worksDetail .slide .slick-dots li button {
          width: 9px;
          height: 9px;
          background-color: #fff;
          border-radius: 50%; }
        .worksDetail .slide .slick-dots li.slick-active button {
          background-color: #71bd29; }
    .worksDetail h2 {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      padding: 10px 10px 10px 0; }
    .worksDetail .infoWrap {
      display: none; }
    .worksDetail .info {
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      border-bottom: 1px solid #a4a4a4; }
      .worksDetail .info .comment p {
        font-size: 16px;
        line-height: 1.8;
        font-weight: 500; }
      .worksDetail .info .comment a.url {
        display: table;
        font-size: 15px;
        line-height: 1;
        color: #a4a4a4;
        text-decoration: none;
        border-bottom: 1px solid transparent;
        margin-top: 20px; }
        .worksDetail .info .comment a.url:hover {
          border-bottom: 1px solid #a4a4a4; }
      .worksDetail .info .spec {
        display: table;
        margin-top: 20px; }
        .worksDetail .info .spec dl {
          display: table-row;
          font-size: 14px; }
          .worksDetail .info .spec dl dt, .worksDetail .info .spec dl dd {
            display: table-cell;
            font-weight: 500; }
          .worksDetail .info .spec dl dt {
            padding-right: 2em; }
    .worksDetail .more .btn {
      width: 290px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: 2px solid #7a7a7a;
      margin: 20px auto 0;
      cursor: pointer;
      transition: all .3s; }
      .worksDetail .more .btn:before {
        content: "";
        width: 14px;
        height: 2px;
        background-color: #000;
        display: block;
        position: absolute;
        top: 24px;
        left: 15px;
        transition: all .4s; }
      .worksDetail .more .btn:after {
        content: "";
        width: 2px;
        height: 14px;
        background-color: #000;
        display: block;
        position: absolute;
        top: 18px;
        left: 21px;
        transition: all .4s; }
      .worksDetail .more .btn:hover {
        opacity: .7; }
      .worksDetail .more .btn.isOpened:before {
        transform: rotate(360deg); }
      .worksDetail .more .btn.isOpened:after {
        transform: rotate(-360deg);
        opacity: 0; }
  /* ---------------- lecture ------------------- */
  #lecture {
    margin: 10px auto 0;
    border-top: 1px solid #ddd; }
    #lecture .lecMenu {
      margin: 30px auto 0;
      width: 90%; }
      #lecture .lecMenu .about {
        padding: 10px; }
        #lecture .lecMenu .about h2 {
          font-size: 18px;
          font-weight: 700; }
        #lecture .lecMenu .about p {
          font-size: 15px;
          line-height: 1.8;
          margin-top: 20px; }
      #lecture .lecMenu .price {
        padding: 30px 0;
        background-color: #ddd;
        border-bottom: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 1; }
        #lecture .lecMenu .price span {
          font-size: 14px;
          font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif"; }
    #lecture p.caption {
      font-size: 14px;
      line-height: 1.3;
      width: 90%;
      margin: .5em auto 0; }
  .askMe {
    width: 90%;
    margin: 40px auto 0;
    padding-bottom: 30px; }
    .askMe h3 {
      font-size: 14px;
      font-weight: 500;
      text-align: center; }
    .askMe .contactBtn {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      line-height: 1;
      color: #fff;
      font-weight: 700;
      text-decoration: none;
      background-color: #000;
      margin: 15px auto 0; }
      .askMe .contactBtn.green {
        background-color: #71bd29; }
  #year {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-top: 30px;
    flex-wrap: wrap; }
    #year li {
      width: calc(50% - 2px);
      margin-top: 4px; }
      #year li a {
        display: block;
        line-height: 1;
        color: #fff;
        background-color: #000;
        padding: 8px 40px 8px 15px;
        position: relative;
        text-decoration: none;
        transition: all .3s; }
        #year li a:after {
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          transform: rotate(45deg);
          border-bottom: 2px solid #fff;
          border-right: 2px solid #fff;
          position: absolute;
          right: 10px;
          top: 12px; }
  /* ---------------------------- SECRET SEMINER -------------------- */
  #seminarHeader .img img {
    width: 100%;
    height: auto; }
  #seminarHeader .info {
    background-color: #000;
    padding: 15px; }
    #seminarHeader .info .title {
      color: #404040;
      font-size: 16px;
      font-weight: 700; }
    #seminarHeader .info .month {
      color: #fff;
      font-size: 40px; }
    #seminarHeader .info .dress {
      font-size: 16px;
      color: #fff; }
    #seminarHeader .info .pdf {
      display: inline-block;
      font-size: 12px;
      color: #fff;
      font-weight: 400;
      margin-top: 30px;
      padding-right: 30px;
      min-height: 22px;
      background-image: url(../images/member/icon-pdf-white.png);
      background-repeat: no-repeat;
      background-position: right 50%; }
  #schedule {
    background-color: #e5e5e5;
    padding: 20px 0; }
    #schedule #scheduleTitle {
      font-size: 22px;
      width: 90%;
      margin: 0 auto;
      position: relative; }
      #schedule #scheduleTitle:after {
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        transform: rotate(45deg);
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        position: absolute;
        right: 0;
        top: 35%;
        transition: all .4s; }
      #schedule #scheduleTitle.isOpen:after {
        transform: rotate(225deg); }
    #schedule ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 90%;
      margin: 0 auto;
      list-style: none;
      padding: 10px 0;
      display: none; }
      #schedule ul:after {
        content: "";
        display: block;
        width: 1px;
        height: 0;
        overflow: hidden;
        clear: both; }
      #schedule ul li {
        width: 45%;
        display: flex;
        position: relative;
        margin-top: 15px;
        float: left; }
        #schedule ul li:not(:last-of-type):nth-of-type(odd):after {
          content: "/";
          display: block;
          width: 10%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          position: absolute;
          top: 0;
          right: -20px;
          font-size: 30px;
          font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif"; }
        #schedule ul li:nth-of-type(even) {
          float: right; }
        #schedule ul li .day {
          width: 40px;
          height: 40px;
          background-color: #fff;
          font-size: 22px;
          line-height: 40px;
          text-align: center; }
        #schedule ul li .place {
          background-image: url(../images/club/pointer.png);
          background-repeat: no-repeat;
          background-position: 0 50%;
          font-size: 18px;
          padding-left: 30px;
          height: 40px;
          line-height: 40px;
          margin-left: 15px;
          text-decoration: none;
          color: #000; }
  #seminarArchive .inner {
    width: 90%;
    margin: 40px auto 0;
    padding-bottom: 30px; }
    #seminarArchive .inner h2 {
      font-weight: 700;
      font-size: 22px;
      text-align: center; }
      #seminarArchive .inner h2 span {
        display: block;
        font-size: 14px; }
    #seminarArchive .inner .archiveItem {
      margin-top: 80px;
      margin-bottom: 35px;
      border-bottom: 2px solid #e5e5e5;
      padding-bottom: 35px; }
      #seminarArchive .inner .archiveItem .poster {
        width: 100%;
        position: relative; }
        #seminarArchive .inner .archiveItem .poster img {
          width: 100%;
          height: auto; }
        #seminarArchive .inner .archiveItem .poster .date {
          position: absolute;
          background-color: #71bd29;
          top: -25px;
          left: 50%;
          transform: translateX(-50%);
          height: 50px;
          line-height: 50px;
          width: 225px; }
          #seminarArchive .inner .archiveItem .poster .date .tag {
            display: inline-block;
            color: #fff;
            font-size: 16px;
            transform: rotate(-90deg);
            width: 50px;
            text-align: center;
            height: 16px;
            line-height: 1;
            position: absolute;
            top: 0;
            left: -45px;
            transform-origin: right top; }
          #seminarArchive .inner .archiveItem .poster .date .mon {
            display: block;
            font-size: 36px;
            color: #fff;
            height: 50px;
            line-height: 50px;
            text-align: center; }
        #seminarArchive .inner .archiveItem .poster a {
          color: #71bd29;
          font-size: 13px;
          padding-top: 5px; }
      #seminarArchive .inner .archiveItem .infoInner {
        display: none; }
      #seminarArchive .inner .archiveItem .info h3 {
        font-size: 24px;
        color: #71bd29;
        font-weight: 400;
        border: 1px solid #71bd29;
        padding: 5px 10px;
        position: relative; }
        #seminarArchive .inner .archiveItem .info h3:before {
          content: "";
          display: block;
          width: 2px;
          height: 20px;
          background-color: #71bd29;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          transition: all .4s; }
        #seminarArchive .inner .archiveItem .info h3:after {
          content: "";
          display: block;
          width: 20px;
          height: 2px;
          background-color: #71bd29;
          position: absolute;
          top: 50%;
          right: 6px;
          transform: translateY(-50%);
          transition: all .4s; }
        #seminarArchive .inner .archiveItem .info h3.isOpen:before {
          transform: translateY(-50%) rotate(270deg); }
        #seminarArchive .inner .archiveItem .info h3.isOpen:after {
          transform: translateY(-50%) rotate(-270deg);
          opacity: 0; }
      #seminarArchive .inner .archiveItem .info .read {
        font-size: 16px;
        font-weight: 700;
        margin-top: 30px; }
      #seminarArchive .inner .archiveItem .info h4 {
        font-size: 16px;
        margin-top: 20px;
        color: #aaa;
        font-weight: 400; }
      #seminarArchive .inner .archiveItem .info .sche {
        display: table;
        list-style: none;
        margin-top: 20px; }
        #seminarArchive .inner .archiveItem .info .sche li {
          display: table-row;
          font-size: 12px; }
          #seminarArchive .inner .archiveItem .info .sche li span {
            display: table-cell; }
            #seminarArchive .inner .archiveItem .info .sche li span:nth-of-type(1) {
              padding-right: 10px; }
      #seminarArchive .inner .archiveItem .info .dressCode {
        font-size: 14px;
        margin-top: 20px; }
  /* ------------------- MEDIA ------------------- */
  #media {
    width: 90%;
    margin: 0 auto; }
  #mediaArchve {
    margin: 40px auto 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 30px; }
    #mediaArchve .mediaItem {
      width: 46%;
      position: relative;
      margin-top: 20px; }
      #mediaArchve .mediaItem.adjust {
        height: 0;
        overflow: hidden; }
      #mediaArchve .mediaItem .img {
        max-width: 80%; }
        #mediaArchve .mediaItem .img img {
          width: 100%;
          height: auto; }
      #mediaArchve .mediaItem .info {
        background-color: rgba(255, 255, 255, 0.8);
        width: 90%;
        padding: 10px;
        border-right: 2px solid #71bd29;
        border-bottom: 2px solid #71bd29;
        margin-top: -50px;
        margin-left: 10%;
        position: relative;
        z-index: 10; }
        #mediaArchve .mediaItem .info .date {
          font-size: 18px;
          line-height: 1;
          color: #71bd29;
          text-align: right; }
        #mediaArchve .mediaItem .info p {
          font-size: 14px;
          line-height: 1.8;
          margin-top: 10px; }
  /* ---------------------- HOW TO USE ------------------- */
  #memberBody.howTo #intro h1 {
    font-size: 22px;
    font-weight: 700;
    text-align: center; }
  #memberBody.howTo #point9 {
    font-feature-settings: "palt" 1;
    margin-top: 20px; }
    #memberBody.howTo #point9 .point9Item {
      border: 3px solid #71bd29;
      border-width: 0 3px 3px 3px;
      background-image: url(../images/howto/check-bg.png);
      margin-top: 20px; }
      #memberBody.howTo #point9 .point9Item .itemHead {
        background-color: #71bd29;
        width: 100%;
        position: relative;
        background-image: url(../images/howto/star-bg.png);
        background-position: right bottom;
        background-repeat: no-repeat;
        color: #fff; }
        #memberBody.howTo #point9 .point9Item .itemHead .num {
          padding-left: 10px; }
          #memberBody.howTo #point9 .point9Item .itemHead .num .numInner {
            font-size: 24px;
            white-space: nowrap; }
            #memberBody.howTo #point9 .point9Item .itemHead .num .numInner span {
              display: inline-block;
              font-size: 18px;
              margin-right: 10px; }
        #memberBody.howTo #point9 .point9Item .itemHead h2 {
          font-size: 16px;
          border-bottom: 2px solid #fff;
          padding: 10px;
          line-height: 1.3; }
        #memberBody.howTo #point9 .point9Item .itemHead h3 {
          font-size: 18px;
          padding: 10px;
          line-height: 1.3; }
      #memberBody.howTo #point9 .point9Item .itemCont {
        padding: 20px;
        font-size: 15px; }
        #memberBody.howTo #point9 .point9Item .itemCont ul, #memberBody.howTo #point9 .point9Item .itemCont ol {
          padding-left: 1.5em;
          line-height: 2; }
          #memberBody.howTo #point9 .point9Item .itemCont ul ul, #memberBody.howTo #point9 .point9Item .itemCont ol ul {
            list-style: disc; }
  /* --------------------- BOOKS -------------------- */
  .bookCat {
    font-size: 22px;
    text-align: center;
    margin-top: 60px; }
  .bookList {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 30px; }
    .bookList .bookItem {
      margin-top: 30px; }
      .bookList .bookItem .num {
        font-size: 14px;
        line-height: 1;
        color: #fff;
        background-color: #71bd29;
        padding: 5px 10px; }
        .bookList .bookItem .num span {
          font-size: 18px; }
      .bookList .bookItem .about h3 {
        font-size: 20px;
        color: #71bd29; }
      .bookList .bookItem .about .author {
        font-size: 16px;
        font-weight: 700;
        margin-top: 10px; }
      .bookList .bookItem .about .info {
        font-size: 14px;
        line-height: 1.8;
        margin-top: 15px; }
      .bookList .bookItem .bookImg {
        width: 160px;
        order: 1;
        margin: 15px auto 0; }
        .bookList .bookItem .bookImg img {
          border: 1px solid #000;
          width: 100%;
          height: auto; }
      .bookList .bookItem .buyBtn {
        width: 100%;
        order: 2;
        text-align: center;
        padding: 10px 0; }
        .bookList .bookItem .buyBtn a {
          display: inline-block;
          width: 160px;
          height: 40px;
          background-color: #000;
          color: #fff;
          font-weight: 700;
          text-align: center;
          line-height: 40px;
          text-decoration: none;
          position: relative;
          transition: all .3s; }
          .bookList .bookItem .buyBtn a:after {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            transform: rotate(45deg);
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            position: absolute;
            top: 16px;
            right: 15px; }
  /* --------------- sns ---------------------- */
  #sns {
    width: 90%;
    margin: 0 auto 0; }
    #sns #snsList {
      list-style: none; }
      #sns #snsList .snsLink {
        width: 100%;
        display: flex;
        margin-top: 40px;
        padding-bottom: 15px;
        border-bottom: 1px solid #000; }
        #sns #snsList .snsLink .img {
          max-width: 298px;
          width: 40%; }
          #sns #snsList .snsLink .img img {
            width: 100%;
            height: auto;
            border: 1px solid #f2f2f2; }
        #sns #snsList .snsLink .txt {
          flex: 1;
          margin-left: 10px;
          padding: 0 15px;
          position: relative;
          min-height: 100%;
          height: 100%; }
          #sns #snsList .snsLink .txt p {
            font-size: 16px;
            font-weight: 700;
            text-indent: -1.1em;
            padding-left: 2em; }
            #sns #snsList .snsLink .txt p .num {
              font-size: 20px;
              font-weight: 400;
              color: #71bd29;
              display: inline-block;
              padding-right: .1em; }
          #sns #snsList .snsLink .txt .icons {
            display: flex;
            list-style: none;
            margin-top: 15px; }
            #sns #snsList .snsLink .txt .icons li {
              margin-right: 10px;
              width: 30px;
              height: 30px; }
              #sns #snsList .snsLink .txt .icons li a {
                display: block;
                transition: all .3s; }
                #sns #snsList .snsLink .txt .icons li a img {
                  width: 100%;
                  height: auto; }
  /* ----------------- contact ------------------ */
  .contactBtn {
    width: 90%;
    margin: 10px auto 0;
    padding: 20px 0 20px;
    background-color: #e5e5e5; }
    .contactBtn a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      margin: 0 auto 0;
      background-color: #71bd29;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      text-decoration: none;
      box-shadow: 4px 6px 0 #b4e585;
      position: relative;
      transition: all .3s;
      padding: 20px; }
      .contactBtn a:not(:nth-of-type(1)) {
        margin-top: 20px; }
      .contactBtn a:after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        background-image: url(../images/second/btn-arrow.png);
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%); }
      .contactBtn a:hover {
        opacity: .7; }
  /* ---------------- charity ----------------- */
  #charityArchive {
    width: 90%;
    margin: 0 auto;
    padding-top: 20px; }
    #charityArchive .slick-dots {
      bottom: 15px;
      text-align: right;
      padding-right: 15px; }
      #charityArchive .slick-dots li {
        width: 9px;
        height: 9px; }
        #charityArchive .slick-dots li.slick-active button {
          background-color: #71bd29; }
        #charityArchive .slick-dots li button {
          width: 9px;
          height: 9px;
          background-color: #fff;
          border-radius: 50%; }
    #charityArchive .charityList {
      width: 100%;
      margin-top: 40px;
      padding-bottom: 30px;
      border-bottom: 1px solid #e5e5e5; }
      #charityArchive .charityList .img {
        width: 100%; }
        #charityArchive .charityList .img img {
          width: 100%;
          height: auto; }
      #charityArchive .charityList .txt h2 {
        font-size: 30px;
        font-weight: 700; }
      #charityArchive .charityList .txt .info {
        font-size: 16px;
        line-height: 1.8;
        margin-top: 40px; }
      #charityArchive .charityList .link {
        font-size: 15px;
        color: #a4a4a4;
        margin-top: 40px;
        display: inline-block;
        position: relative;
        text-decoration: none; }
        #charityArchive .charityList .link:hover {
          text-decoration: underline; }
        #charityArchive .charityList .link .blank {
          width: 15px;
          height: 15px;
          display: block;
          position: absolute;
          top: 0;
          right: -20px; }
          #charityArchive .charityList .link .blank svg {
            width: auto;
            height: 13px;
            fill: #a4a4a4; }
      #charityArchive .charityList .movie {
        margin-top: 40px;
        background-color: #e5e5e5;
        padding: 15px;
        display: block; }
        #charityArchive .charityList .movie .movInfo {
          padding-right: 20px;
          margin-top: 15px; }
          #charityArchive .charityList .movie .movInfo:first-child {
            margin-top: 0; }
          #charityArchive .charityList .movie .movInfo .title {
            background-image: url(../images/common/icon-movie.png);
            background-repeat: no-repeat;
            background-position: 0 5px;
            padding-left: 30px;
            font-size: 15px; }
          #charityArchive .charityList .movie .movInfo .url {
            color: #a4a4a4;
            font-size: 15px;
            text-decoration: none; }
            #charityArchive .charityList .movie .movInfo .url:hover {
              text-decoration: underline; }
        #charityArchive .charityList .movie .tn {
          width: 100px; }
          #charityArchive .charityList .movie .tn img {
            width: 100%;
            height: auto; }
  /* --------------- base layout -------------- */
  .txtCont {
    width: 90%;
    margin: 40px auto 0;
    padding-bottom: 30px; }
    .txtCont h2 {
      font-size: 22px;
      font-weight: 400;
      text-align: center; }
      .txtCont h2 .ja {
        font-size: 15px;
        font-weight: 700;
        display: block;
        margin: 0 auto; }
    .txtCont h3 {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      margin-top: 30px; }
    .txtCont p {
      font-size: 15px;
      line-height: 1.8;
      margin-top: 40px; }
    .txtCont .tableLayout {
      border-bottom: 2px solid #000;
      margin-top: 40px; }
      .txtCont .tableLayout dl {
        border-top: 2px solid #000;
        padding: 15px 0;
        font-size: 15px;
        line-height: 1.8; }
        .txtCont .tableLayout dl dt {
          font-weight: 700; }
        .txtCont .tableLayout dl dd.flex > span {
          display: block; }
          .txtCont .tableLayout dl dd.flex > span:not(:nth-of-type(1)) {
            margin-top: 1em; }
          .txtCont .tableLayout dl dd.flex > span.map {
            width: 100%; }
            .txtCont .tableLayout dl dd.flex > span.map img {
              width: 100%;
              height: auto; }
    .txtCont a.btn {
      background-color: #000;
      display: inline-block;
      color: #fff;
      text-decoration: none;
      line-height: 1;
      padding: 5px 35px 5px 15px;
      position: relative;
      margin-top: 10px; }
      .txtCont a.btn:after {
        display: block;
        content: "";
        width: 15px;
        height: 15px;
        background-image: url(../images/second/btn-arrow.png);
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%); }
  .basic_list {
    list-style: none;
    width: 90%;
    margin: .5em auto 0; }
    .basic_list li {
      padding-left: 20px;
      position: relative; }
      .basic_list li::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 7px;
        left: 3px;
        background-color: #71bd29; }
  .team .inner {
    width: 90%;
    margin: 30px auto 0; }
    .team .inner h2 {
      grid-row: 1;
      grid-column: 2;
      font-size: 22px;
      line-height: 1;
      background-image: url(../images/ryumurao-team/name-bg-gray.png);
      background-repeat: no-repeat;
      background-position: 90% 0; }
      .team .inner h2 .kana {
        display: block;
        font-size: 18px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 10px 0;
        margin-top: 10px; }
    .team .inner .img {
      margin-top: 10px; }
      .team .inner .img img {
        width: 100%;
        height: auto; }
    .team .inner .info {
      margin-top: 1em; }
      .team .inner .info p {
        font-size: 15px;
        line-height: 1.8; }
      .team .inner .info .web {
        text-align: right;
        font-size: 15px;
        font-weight: 700;
        margin-top: 40px; }
        .team .inner .info .web a {
          color: #737373;
          font-size: 18px; }
  .team2col {
    background-color: #e5e5e5;
    padding: 0px 0 30px;
    margin-top: 40px; }
    .team2col .inner {
      width: 90%;
      margin: 0 auto 0;
      padding-top: 1px; }
      .team2col .inner .member {
        background-color: #fff;
        background-position: right top;
        background-repeat: no-repeat;
        background-size: auto 110px;
        width: 100%;
        padding: 15px;
        margin-top: 30px; }
        .team2col .inner .member h2 {
          font-size: 22px;
          line-height: 1;
          height: 100px;
          padding-top: 40px;
          background-image: url(../images/ryumurao-team/name-bg-gray.png);
          background-repeat: no-repeat;
          background-position: 90px 0; }
          .team2col .inner .member h2 span {
            font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif";
            font-size: 15px;
            display: block;
            margin-top: 5px; }
        .team2col .inner .member p {
          margin-top: 20px; }
        .team2col .inner .member#kentaro {
          background-image: url(../images/ryumurao-team/kentaro.jpg); }
        .team2col .inner .member#miyuki {
          background-image: url(../images/ryumurao-team/miyuki.jpg); } }
  @media screen and (max-width: 650px) and (max-width: 900px) and (min-width: 600px) {
    .team2col {
      padding: 0px 0 30px;
      margin-top: 40px; }
      .team2col .inner {
        display: block;
        width: 90%;
        margin: 0 auto 0;
        padding-top: 1px; }
        .team2col .inner .member {
          background-color: #fff;
          background-position: right top;
          background-repeat: no-repeat;
          background-size: auto 110px;
          width: 100%;
          padding: 15px;
          margin-top: 30px; }
          .team2col .inner .member h2 {
            font-size: 22px;
            line-height: 1;
            height: 100px;
            padding-top: 40px;
            background-image: url(../images/ryumurao-team/name-bg-gray.png);
            background-repeat: no-repeat;
            background-position: 90px 0; }
            .team2col .inner .member h2 span {
              font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif";
              font-size: 15px;
              display: block;
              margin-top: 5px; }
          .team2col .inner .member p {
            width: 100%;
            margin-top: 20px; }
          .team2col .inner .member#kentaro {
            background-image: url(../images/ryumurao-team/kentaro.jpg); }
          .team2col .inner .member#miyuki {
            background-image: url(../images/ryumurao-team/miyuki.jpg); } }

@media screen and (max-width: 650px) {
  #ourTeam {
    margin-top: 40px; }
    #ourTeam .inner {
      width: 90%;
      margin: 0 auto; }
      #ourTeam .inner h2 {
        font-size: 22px;
        font-weight: 400;
        text-align: center; }
      #ourTeam .inner .teamTable {
        margin-top: 20px;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        width: 100%; }
        #ourTeam .inner .teamTable .our {
          border-bottom: 1px solid #ddd; }
          #ourTeam .inner .teamTable .our a {
            color: #000;
            text-decoration: none;
            display: block;
            padding: 15px; }
            #ourTeam .inner .teamTable .our a .logo {
              width: 134px;
              text-align: center;
              margin: 0 auto; }
            #ourTeam .inner .teamTable .our a .txt {
              margin-top: 15px; }
              #ourTeam .inner .teamTable .our a .txt h3 {
                font-size: 16px;
                display: table;
                padding-right: 30px;
                margin: 0 auto;
                background-image: url(../images/member/icon-blank.png);
                background-repeat: no-repeat;
                background-position: right 50%; }
              #ourTeam .inner .teamTable .our a .txt p {
                font-size: 14px;
                margin-top: 15px;
                line-height: 1.8; }
  #memberHeader {
    padding-top: 70px; }
    #memberHeader #pageName {
      background-color: #71bd29;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #fff;
      font-weight: 700;
      line-height: 1; }
    #memberHeader #pageName {
      height: 70px;
      font-size: 22px;
      padding-left: calc((20px + 43px) / 2); }
      #memberHeader #pageName:after {
        content: "";
        display: block;
        width: 43px;
        height: 44px;
        background-image: url(../images/member/title-img.png);
        background-size: 43px auto;
        margin-left: 20px; }
  #memberBody {
    width: 90%;
    margin: 0 auto; }
    #memberBody #intro {
      padding-top: 30px; }
      #memberBody #intro h2 {
        font-size: 22px;
        text-align: center;
        font-weight: 400; }
        #memberBody #intro h2 span {
          display: block;
          text-align: center;
          font-size: 15px; }
      #memberBody #intro p {
        font-size: 15px;
        line-height: 1.8;
        margin: 20px auto 0;
        display: table; }
    #memberBody .toMenu {
      display: block;
      margin: 20px auto;
      width: 100%;
      text-align: center; }
      #memberBody .toMenu a {
        display: block;
        text-decoration: none;
        cursor: pointer;
        padding: 10px;
        transition: all .3s;
        color: #000;
        font-weight: 700;
        border: 2px solid #71bd29;
        position: relative; }
        #memberBody .toMenu a::after {
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          background-image: url(../images/member/arrow-black.png);
          position: absolute;
          right: 20px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
    #memberBody #loginForm {
      width: 100%;
      background-color: #e5e5e5;
      padding: 40px 0 0;
      margin-top: 60px; }
      #memberBody #loginForm #pass h2 {
        color: #71bd29;
        font-size: 22px;
        font-weight: 700;
        text-align: center; }
        #memberBody #loginForm #pass h2 span {
          font-size: 14px;
          font-weight: 700;
          padding-left: 30px;
          color: #000;
          display: block; }
      #memberBody #loginForm #pass form {
        display: block;
        margin: 20px auto 0; }
        #memberBody #loginForm #pass form .inputTxt {
          width: 90%;
          margin: 0 auto;
          padding-bottom: 20px; }
        #memberBody #loginForm #pass form input[type=password] {
          width: 100%;
          height: 50px;
          outline: none;
          border: 2px solid #d0d0d0; }
      #memberBody #loginForm #pass #login {
        background-color: #fff;
        text-align: center;
        padding: 15px 0; }
        #memberBody #loginForm #pass #login button {
          width: 100%;
          height: 40px;
          color: #fff;
          background-color: #000;
          border: none; }
    #memberBody #seminar {
      display: flex;
      flex-direction: column; }
      #memberBody #seminar .img {
        order: 1;
        width: 50%;
        position: relative;
        overflow: hidden; }
        #memberBody #seminar .img img {
          display: block;
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%); }
      #memberBody #seminar .txt {
        order: 0;
        background-color: #000;
        color: #fff;
        width: 100%;
        padding: 20px;
        line-height: 1.3; }
        #memberBody #seminar .txt h2 {
          font-size: 26px;
          font-weight: 700;
          line-height: 1; }
        #memberBody #seminar .txt .date {
          font-size: 22px;
          font-weight: 700;
          line-height: 1.4;
          margin-top: 10px; }
        #memberBody #seminar .txt .conditions {
          font-size: 16px;
          margin-top: 15px; }
        #memberBody #seminar .txt .more {
          width: 100%;
          height: 40px;
          background-color: #fff;
          color: #000;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          position: relative;
          margin-top: 40px; }
          #memberBody #seminar .txt .more:after {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            background-image: url(../images/member/arrow-black.png);
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-9px); }
        #memberBody #seminar .txt .download a {
          font-size: 14px;
          margin-top: 10px;
          display: inline-block;
          color: #fff;
          text-decoration: none;
          position: relative; }
          #memberBody #seminar .txt .download a:after {
            content: "";
            display: inline-block;
            width: 21px;
            height: 22px;
            background-image: url(../images/member/icon-pdf-white.png);
            position: absolute; }
    #memberBody #merit {
      width: 100%;
      margin: 10px auto 0; }
      #memberBody #merit a {
        border: 2px solid #71bd29;
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 16px;
        line-height: 1.8;
        font-weight: 700;
        color: #000;
        text-decoration: none;
        padding: 10px;
        position: relative;
        margin-top: 20px; }
        #memberBody #merit a:after {
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          background-image: url(../images/member/arrow-black.png);
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%); }
    #memberBody #membersInfo {
      margin-top: 40px;
      padding-top: 30px;
      border-top: 2px solid #e5e5e5; }
      #memberBody #membersInfo h2 {
        font-size: 22px;
        font-weight: 700;
        text-align: center; }
      #memberBody #membersInfo ul {
        list-style: none;
        margin: 20px auto 0; }
        #memberBody #membersInfo ul li {
          position: relative;
          margin-top: 10px; }
          #memberBody #membersInfo ul li a {
            display: block;
            font-size: 16px;
            font-weight: 700;
            line-height: 17px;
            color: #000;
            text-decoration: none;
            padding: 15px 25px 15px 10px;
            background-image: url(../images/member/icon-blank.png);
            background-repeat: no-repeat;
            background-position: 97% 50%;
            border: 1px solid #000; }
      #memberBody #membersInfo #askMe {
        margin-top: 40px;
        padding-bottom: 30px; }
        #memberBody #membersInfo #askMe h3 {
          font-size: 14px;
          font-weight: 500;
          text-align: center; }
        #memberBody #membersInfo #askMe .contactBtn {
          width: 100%;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 16px;
          line-height: 1;
          color: #fff;
          font-weight: 700;
          text-decoration: none;
          background-color: #000;
          margin: 15px auto 0; }
  /* ------------------ faq ------------------ */
  #faqList, #priceList {
    border: 1px solid #000;
    margin-top: 40px; }
    #faqList h3, #priceList h3 {
      font-size: 18px;
      position: relative;
      border-bottom: 1px solid #000;
      padding: 0 0 5px;
      margin-bottom: -1px; }
      #faqList h3:not(:nth-of-type(1)), #priceList h3:not(:nth-of-type(1)) {
        margin-top: 10px; }
      #faqList h3:before, #priceList h3:before {
        content: "";
        display: block;
        width: 25px;
        height: 3px;
        background-color: #000;
        position: absolute;
        top: 31px;
        right: 10px;
        transition: all .4s; }
      #faqList h3:after, #priceList h3:after {
        content: "";
        display: block;
        width: 3px;
        height: 25px;
        background-color: #000;
        position: absolute;
        top: 20px;
        right: 21px;
        transition: all .4s; }
      #faqList h3 .tag, #priceList h3 .tag {
        font-size: 18px;
        font-weight: 700;
        padding: 5px 10px;
        line-height: 1;
        display: block;
        transition: all .4s; }
      #faqList h3 .ja, #priceList h3 .ja {
        flex: 1;
        margin-top: 5px;
        display: block;
        padding: 0 10px; }
      #faqList h3.isOpen .tag, #priceList h3.isOpen .tag {
        background-color: #71bd29;
        color: #fff; }
      #faqList h3.isOpen:before, #priceList h3.isOpen:before {
        transform: rotate(270deg);
        opacity: 0; }
      #faqList h3.isOpen:after, #priceList h3.isOpen:after {
        transform: rotate(270deg); }
    #faqList .faqCat, #faqList .priceCat, #priceList .faqCat, #priceList .priceCat {
      padding: 0 20px 40px;
      display: none; }
      #faqList .faqCat dl, #faqList .priceCat dl, #priceList .faqCat dl, #priceList .priceCat dl {
        margin-top: 30px;
        padding-bottom: 40px;
        border-bottom: 1px solid #000; }
        #faqList .faqCat dl dt, #faqList .priceCat dl dt, #priceList .faqCat dl dt, #priceList .priceCat dl dt {
          font-size: 16px;
          font-weight: 700; }
          #faqList .faqCat dl dt:before, #faqList .priceCat dl dt:before, #priceList .faqCat dl dt:before, #priceList .priceCat dl dt:before {
            content: "Q";
            font-size: 20px;
            font-family: "Allerta Stencil", sans-serif;
            color: #71bd29;
            display: inline-block; }
        #faqList .faqCat dl dd, #faqList .priceCat dl dd, #priceList .faqCat dl dd, #priceList .priceCat dl dd {
          font-size: 15px;
          margin-top: 30px; }
    #faqList .priceCat dl dt span.num, #priceList .priceCat dl dt span.num {
      font-size: 20px;
      font-family: "Allerta Stencil", sans-serif;
      color: #71bd29;
      display: inline-block; }
    #faqList .priceCat dl dt:before, #priceList .priceCat dl dt:before {
      display: none; }
    #faqList .priceCat dl dd.fee, #priceList .priceCat dl dd.fee {
      background-color: #e5e5e5;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 700; }
      #faqList .priceCat dl dd.fee span.sten, #priceList .priceCat dl dd.fee span.sten {
        display: inline-block;
        font-size: 24px;
        font-weight: 400; }
  #priceList h3 {
    padding: 0 0; }
    #priceList h3 .tag {
      padding: 20px 0 20px 10px; }
    #priceList h3:before {
      top: 26px; }
    #priceList h3:after {
      top: 15px; }
    #priceList h3 .ja {
      display: none; }
  #packPrice {
    margin: 30px auto 0; }
    #packPrice h2 {
      background-color: #efefef;
      font-size: 16px;
      line-height: 1;
      padding: 10px; }
    #packPrice .btns a {
      margin-top: 20px;
      display: block;
      width: 100%;
      border: 2px solid #71bd29;
      color: #000;
      text-decoration: none;
      font-size: 14px;
      font-weight: 700;
      padding: 10px 30px 10px 10px;
      position: relative;
      transition: all .3s; }
      #packPrice .btns a:after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        background-image: url(../images/second/btn-arrow-green.png);
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%); }
  #indexTop {
    width: 100%;
    height: 250px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover; }
    #indexTop.club {
      background-image: url(../images/club/index-top.jpg); }
    #indexTop.about {
      background-image: url(../images/about/top-bg.jpg); }
    #indexTop.charity {
      background-image: url(../images/charity/top-img.jpg); }
    #indexTop #pageMenu {
      width: 100%;
      height: 50px;
      background-color: rgba(0, 0, 0, 0.6); }
      #indexTop #pageMenu h2 {
        font-weight: 400; }
      #indexTop #pageMenu ul {
        width: 100%;
        height: 50px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center; }
        #indexTop #pageMenu ul li {
          font-size: 14px;
          position: relative;
          white-space: nowrap;
          padding-left: 5px;
          color: #fff; }
          #indexTop #pageMenu ul li:not(:last-of-type):after {
            content: "/";
            display: inline-block;
            width: 12px;
            height: 12px; }
          #indexTop #pageMenu ul li a {
            color: #fff;
            text-decoration: none;
            display: inline-block;
            margin-right: 12px; }
    #indexTop h2 {
      font-size: 30px;
      color: #fff;
      width: 90%;
      height: 200px;
      margin: 0 auto;
      display: flex;
      align-items: center; }
  #clubTop {
    width: 90%;
    margin: 0 auto; }
    #clubTop #photos {
      display: none; }
    #clubTop .point {
      width: 100%;
      background-color: #f2f2f2;
      box-shadow: 3px 3px 0 #71bd29;
      padding: 0 5px 10px;
      margin-top: 30px; }
      #clubTop .point .num {
        width: 40px;
        height: 24px;
        font-size: 24px;
        position: absolute;
        color: #71bd29;
        top: -5px;
        left: -10px;
        position: relative; }
      #clubTop .point h3 {
        font-size: 20px;
        text-align: center;
        position: relative;
        padding-bottom: 15px; }
        #clubTop .point h3:after {
          content: "";
          display: block;
          width: 200px;
          height: 2px;
          background-color: #71bd29;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto; }
      #clubTop .point p {
        margin-top: 15px;
        font-size: 14px;
        line-height: 1.8; }
      #clubTop .point#point1 {
        top: -50px;
        right: 10%; }
      #clubTop .point#point2 {
        bottom: 100px;
        left: 5%; }
  #clubService {
    width: 90%;
    margin: 40px auto 0; }
    #clubService h2 {
      font-size: 22px;
      line-height: 1;
      color: #71bd29;
      text-align: center;
      font-weight: 400; }
      #clubService h2 .sten {
        font-size: 40px;
        display: inline-block;
        padding: 0 10px; }
      #clubService h2 .ja {
        color: #000;
        font-size: 14px;
        display: block;
        margin: 10px auto 0; }
    #clubService ul {
      list-style: none;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px; }
      #clubService ul li {
        background-color: #71bd29;
        color: #fff;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        width: 50%;
        padding: 40px 15px 25px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all .6s; }
        #clubService ul li:nth-of-type(1) {
          transition-delay: 0; }
        #clubService ul li:nth-of-type(2) {
          transition-delay: .1s; }
        #clubService ul li:nth-of-type(3) {
          transition-delay: 0; }
        #clubService ul li:nth-of-type(4) {
          transition-delay: .1s; }
        #clubService ul li:nth-of-type(5) {
          transition-delay: 0; }
        #clubService ul li:nth-of-type(6) {
          transition-delay: .1s; }
        #clubService ul li:nth-of-type(7) {
          transition-delay: 0; }
        #clubService ul li:nth-of-type(8) {
          transition-delay: .1s; }
        #clubService ul li.view {
          opacity: 1; }
        #clubService ul li .num {
          width: 30px;
          height: 30px;
          font-size: 20px;
          position: absolute;
          top: 5px;
          right: 5px;
          border-bottom: 2px solid #fff; }
        #clubService ul li p {
          font-size: 16px;
          font-weight: 700; }
          #clubService ul li p span {
            display: inline-block;
            font-size: 12px; }
  #division {
    margin-top: 40px;
    overflow: hidden;
    position: relative;
    padding-top: 150px; }
    #division #devLogo {
      width: 80px;
      height: 82px;
      position: absolute;
      top: 34px;
      left: 0;
      right: 0;
      margin: 0 auto; }
    #division #screen {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }
      #division #screen .slideImg {
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: 50% 50%;
        background-attachment: fixed;
        transform: scale(1);
        transition: all 20s; }
        #division #screen .slideImg:nth-of-type(1) {
          background-image: url(../images/home/top1.jpg); }
        #division #screen .slideImg:nth-of-type(2) {
          background-image: url(../images/home/2_aux.jpg); }
        #division #screen .slideImg:nth-of-type(3) {
          background-image: url(../images/home/4_iiwan.jpg); }
        #division #screen .slideImg:nth-of-type(4) {
          background-image: url(../images/home/4_upcon.jpg); }
    #division h2 {
      font-size: 22px;
      text-align: center;
      color: #fff;
      background-color: #000;
      padding-top: 30px; }
    #division .members {
      background-color: #000;
      margin: 0 auto;
      padding-top: 40px; }
      #division .members:last-of-type {
        padding-bottom: 50px; }
      #division .members dl {
        width: 90%;
        margin: 0 auto;
        background-image: url(../images/club/icon-dev.png);
        background-repeat: no-repeat;
        font-size: 16px;
        color: #fff;
        font-weight: 700;
        padding-left: 40px;
        min-height: 36px; }
        #division .members dl dt {
          position: relative;
          padding-right: 40px; }
        #division .members dl dd {
          font-size: 14px;
          font-weight: 500; }
      #division .members .priceTable {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;
        background-color: #000; }
      #division .members .price {
        background-color: #fff;
        margin: 0 2px 2px 0;
        width: 100%;
        font-size: 18px;
        font-weight: 700;
        text-align: center; }
        #division .members .price:nth-of-type(2) {
          transition-delay: .1s; }
        #division .members .price .sten.price {
          font-size: 40px; }
          #division .members .price .sten.price .op0 {
            opacity: 0; }
      #division .members ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        list-style: none; }
        #division .members ul li {
          background-color: #fff;
          margin: 0 2px 2px 0;
          width: calc((100% / 4) - 2px);
          font-size: 10px;
          text-align: center;
          padding: 10px 0; }
          #division .members ul li span {
            display: block;
            width: 30px;
            height: 30px;
            margin: 5px auto 0; }
  #seminar #seminarHead {
    width: 100%;
    min-height: 100px;
    position: relative;
    padding-top: 40px; }
    #seminar #seminarHead #svgContainer {
      width: 320px;
      height: 40px;
      position: absolute;
      top: 5px;
      right: 0;
      z-index: 0;
      text-align: right; }
      #seminar #seminarHead #svgContainer svg {
        width: 320px;
        height: 40px; }
    #seminar #seminarHead #headObject {
      max-width: 700px;
      width: 95%;
      margin: 0 auto;
      position: relative;
      z-index: 5; }
      #seminar #seminarHead #headObject h2 {
        font-size: 34px;
        text-align: center; }
        #seminar #seminarHead #headObject h2 span {
          font-size: 16px;
          display: block;
          margin: 0 auto; }
      #seminar #seminarHead #headObject p {
        font-size: 15px;
        line-height: 1.8;
        margin-top: 30px; }
  #seminar #seminarInfo {
    display: flex;
    flex-direction: column; }
    #seminar #seminarInfo .img {
      order: 1;
      width: 50%;
      position: relative;
      overflow: hidden; }
      #seminar #seminarInfo .img img {
        display: block;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%); }
    #seminar #seminarInfo .txt {
      order: 0;
      background-color: #000;
      color: #fff;
      width: 100%;
      padding: 20px;
      line-height: 1.3; }
      #seminar #seminarInfo .txt h2 {
        font-size: 26px;
        font-weight: 700;
        line-height: 1; }
      #seminar #seminarInfo .txt .date {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.4;
        margin-top: 10px; }
      #seminar #seminarInfo .txt .conditions {
        font-size: 16px;
        margin-top: 15px; }
      #seminar #seminarInfo .txt .more {
        width: 100%;
        height: 40px;
        background-color: #fff;
        color: #000;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        position: relative;
        margin-top: 40px; }
        #seminar #seminarInfo .txt .more:after {
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          background-image: url(../images/member/arrow-black.png);
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-9px); }
      #seminar #seminarInfo .txt .download a {
        font-size: 14px;
        margin-top: 10px;
        display: inline-block;
        color: #fff;
        text-decoration: none;
        position: relative; }
        #seminar #seminarInfo .txt .download a:after {
          content: "";
          display: inline-block;
          width: 21px;
          height: 22px;
          background-image: url(../images/member/icon-pdf-white.png);
          position: absolute; }
  #seminar #special {
    width: 90%;
    margin: 40px auto 0; }
  #seminar #fiveContents {
    width: 100%;
    list-style: none; }
    #seminar #fiveContents li {
      width: 100%;
      padding: 20px;
      border: 1px solid #71bd29;
      margin-top: 20px;
      /*
				&:nth-of-type(1){transition-delay: 0}
				&:nth-of-type(2){transition-delay: .1s}
				&:nth-of-type(3){transition-delay: .2s}
				&:nth-of-type(4){transition-delay: .3s}
				&:nth-of-type(5){transition-delay: .4s}
				*/ }
      #seminar #fiveContents li:not(:last-of-type) {
        border-right: 1px solid #71bd29; }
      #seminar #fiveContents li .img {
        width: 100%; }
        #seminar #fiveContents li .img img {
          width: 100%;
          height: auto; }
      #seminar #fiveContents li .txt {
        margin-top: 20px; }
        #seminar #fiveContents li .txt h3 {
          font-size: 18px;
          color: #71bd29; }
        #seminar #fiveContents li .txt p {
          margin-top: 10px;
          font-size: 15px;
          line-height: 1.6; }
  #seminar .info {
    margin-top: 20px; }
    #seminar .info ul {
      list-style: none;
      font-size: 14px; }
    #seminar .info .contact a {
      width: 100%;
      padding: 20px 0;
      background-color: #71bd29;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 2px 3px 0 #b4e585;
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      position: relative; }
      #seminar .info .contact a:after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        background-image: url(../images/second/btn-arrow.png);
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-7px); }
  #rules {
    margin-top: 40px; }
    #rules h2 {
      text-align: center;
      font-size: 22px;
      font-weight: 400;
      background-color: #000;
      color: #fff;
      line-height: 1;
      padding: 15px 0;
      text-transform: uppercase; }
    #rules #rulesInner {
      width: 90%;
      margin: 0 auto 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      #rules #rulesInner dl {
        width: 48%;
        margin-top: 30px; }
        #rules #rulesInner dl dt {
          font-size: 16px;
          line-height: 1;
          text-transform: uppercase; }
          #rules #rulesInner dl dt span {
            font-size: 20px;
            color: #71bd29;
            display: inline-block;
            padding-bottom: 2px;
            border-bottom: 1px solid #71bd29; }
        #rules #rulesInner dl dd {
          font-size: 16px;
          line-height: 1.6;
          margin-top: 15px;
          font-weight: 700; }
    #rules.seminarRules {
      margin-bottom: 40px;
      border: 1px solid #000;
      padding-bottom: 40px; }
  #jobMap {
    background-color: #e5e5e5;
    border-top: 5px dashed #000;
    border-bottom: 5px dashed #000;
    width: 100%;
    position: relative;
    margin-top: 40px;
    padding-top: 3px;
    padding-bottom: 30px; }
    #jobMap h2 {
      background-color: #000;
      color: #fff;
      font-size: 14px;
      margin: 0 auto;
      position: relative;
      text-align: center;
      padding: 15px 10px; }
      #jobMap h2 .eng, #jobMap h2 .sten {
        font-size: 20px;
        margin-left: 10px;
        display: inline-block;
        white-space: nowrap; }
      #jobMap h2 .icon {
        display: none; }
    #jobMap #jpmap {
      display: none;
      width: 399px;
      height: 487px;
      background-image: url(../images/club/jobmap-jp.png);
      position: absolute;
      top: 233px;
      left: 0;
      right: 0;
      margin: 0 auto; }
      #jobMap #jpmap .flag {
        width: 20px;
        height: 32px;
        position: absolute;
        overflow: hidden; }
        #jobMap #jpmap .flag img {
          display: block;
          width: 20px;
          height: 32px;
          position: absolute;
          left: 0;
          top: 32px;
          transition: all .4s; }
        #jobMap #jpmap .flag.isView img {
          top: 0; }
        #jobMap #jpmap .flag#flag1 {
          top: 168px;
          left: 272px; }
        #jobMap #jpmap .flag#flag2 {
          top: 214px;
          left: 243px; }
          #jobMap #jpmap .flag#flag2 img {
            transition-delay: .2s; }
        #jobMap #jpmap .flag#flag3 {
          top: 271px;
          left: 249px; }
          #jobMap #jpmap .flag#flag3 img {
            transition-delay: .4s; }
        #jobMap #jpmap .flag#flag4 {
          top: 312px;
          left: 160px; }
          #jobMap #jpmap .flag#flag4 img {
            transition-delay: .6s; }
        #jobMap #jpmap .flag#flag5 {
          top: 345px;
          left: 55px; }
          #jobMap #jpmap .flag#flag5 img {
            transition-delay: .8s; }
    #jobMap .diagram {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(0);
      transition: all .4s;
      margin: 30px auto 0;
      border: 2px solid #71bd29;
      background-color: #000; }
      #jobMap .diagram.isView {
        transform: scale(1); }
      #jobMap .diagram > img {
        opacity: .7;
        width: 100%;
        height: auto; }
      #jobMap .diagram h3 {
        font-size: 22px;
        line-height: 1;
        color: #fff;
        width: 100%;
        height: 18px;
        text-align: center;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        font-weight: 400;
        display: table; }
      #jobMap .diagram:after {
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-bottom: 10px solid #71bd29;
        border-right: 10px solid #71bd29;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        position: absolute;
        bottom: 0;
        right: 0; }
      #jobMap .diagram .symbol {
        width: 90px;
        height: 90px;
        position: absolute;
        top: -5px;
        left: -5px;
        z-index: 10; }
        #jobMap .diagram .symbol img {
          width: 100%;
          height: auto; }
      #jobMap .diagram#iwate {
        top: 295px;
        left: calc(50% + 238px); }
      #jobMap .diagram#yamagata {
        top: 540px;
        left: calc(50% + 435px);
        transition-delay: .2s; }
      #jobMap .diagram#tokyo {
        top: 615px;
        left: calc(50% + 108px);
        transition-delay: .4s; }
      #jobMap .diagram#osaka {
        top: 295px;
        right: calc(50% + 115px);
        transition-delay: .6s; }
      #jobMap .diagram#fukuoka {
        top: 555px;
        right: calc(50% + 300px);
        transition-delay: .8s; }
  /* ---------------- join us ------------- */
  .sectJoinUs h2 {
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    margin-top: 40px; }
    .sectJoinUs h2 .ja {
      font-size: 15px;
      text-align: center;
      display: block; }
  .sectJoinUs.steps {
    margin-top: 40px; }
    .sectJoinUs.steps h2 {
      padding-bottom: 30px; }
    .sectJoinUs.steps h3 {
      width: 90%;
      margin: 0 auto;
      background-color: #efefef;
      font-size: 16px;
      font-weight: 700;
      padding: 5px 10px;
      line-height: 1; }
      .sectJoinUs.steps h3 .eng {
        display: inline-block;
        background-color: #71bd29;
        padding: 5px 40px;
        color: #fff;
        font-weight: 400;
        margin-right: 30px; }
    .sectJoinUs.steps .stepList {
      background-color: #e5e5e5;
      padding: 10px;
      width: 90%;
      margin: 0 auto;
      list-style: none; }
      .sectJoinUs.steps .stepList li {
        background-color: #fff;
        position: relative;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 10px 10px; }
        .sectJoinUs.steps .stepList li .num {
          display: block;
          width: 25px;
          height: 25px;
          position: absolute;
          font-size: 20px;
          color: #71bd29;
          line-height: 1;
          border-bottom: 1px solid #71bd29;
          top: -10px;
          left: 0px; }
        .sectJoinUs.steps .stepList li:not(:last-of-type) {
          margin-bottom: 50px; }
          .sectJoinUs.steps .stepList li:not(:last-of-type):after {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            transform: rotate(45deg);
            border-bottom: 4px solid #000;
            border-right: 4px solid #000;
            position: absolute;
            bottom: -30px;
            right: 0;
            left: 0;
            margin: 0 auto; }
    .sectJoinUs.steps .cap {
      background-color: #e5e5e5;
      padding: 10px;
      width: 90%;
      margin: 0 auto; }
      .sectJoinUs.steps .cap ul {
        list-style: none;
        font-size: 14px; }
  #kiyaku #kiyakuScroll {
    width: 90%;
    height: 240px;
    border: 2px solid #000;
    margin: 30px auto 0;
    overflow-y: scroll; }
    #kiyaku #kiyakuScroll .kiyakuInner {
      padding: 15px; }
      #kiyaku #kiyakuScroll .kiyakuInner p {
        font-size: 12px; }
  #course {
    width: 90%;
    margin: 40px auto 0; }
    #course p {
      text-align: center;
      margin-top: 30px; }
  #course .btns, #modal .btns {
    padding: 30px 0; }
    #course .btns .button, #modal .btns .button {
      width: 100%;
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      background-color: #71bd29;
      margin: 20px 0 0 0;
      box-shadow: 0 5px 0 #a2ce77;
      position: relative;
      transition: all .3s;
      cursor: pointer; }
      #course .btns .button:before, #modal .btns .button:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-image: url(../images/club/btn-arrow.png);
        background-size: cover;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px; }
  #modal {
    opacity: 0;
    transition: all .3s;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -999; }
    #modal.isShow {
      align-items: center;
      opacity: 1;
      z-index: 999; }
    #modal .modInner {
      background-color: #fff;
      position: relative;
      width: 90%;
      margin: 0 auto;
      padding: 40px 0;
      z-index: 9999; }
      #modal .modInner .btns {
        width: 90%;
        margin: 0 auto; }
      #modal .modInner .closeBtn {
        width: 33px;
        height: 33px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer; }
      #modal .modInner h2 {
        font-size: 22px;
        text-align: center; }
      #modal .modInner p {
        text-align: center;
        margin-top: 30px; }
  section.mailForm {
    width: 90%;
    margin: 0 auto; }
    section.mailForm .aform-header, section.mailForm .h2 {
      font-size: 22px;
      font-family: "Oswald", "sans-serif", "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "meiryo";
      text-align: center;
      margin-top: 30px;
      padding-bottom: 10px;
      font-weight: 700; }
    section.mailForm .aform-content dl {
      border-top: 2px solid #000;
      padding: 15px 0;
      font-size: 15px;
      line-height: 1.8; }
      section.mailForm .aform-content dl dt {
        border-bottom: 1px solid #71bd29; }
        section.mailForm .aform-content dl dt label .aform-required {
          font-size: 10px;
          color: #71bd29;
          border: 1px solid #71bd29;
          padding: 1px 4px;
          line-height: 1;
          display: inline-block;
          margin-left: 5px; }
      section.mailForm .aform-content dl dd {
        padding-top: 10px; }
        section.mailForm .aform-content dl dd textarea {
          display: block;
          width: 100%; }
        section.mailForm .aform-content dl dd input.aform-input,
        section.mailForm .aform-content dl dd select {
          width: 100%;
          height: 40px;
          padding: 0 15px; }
          section.mailForm .aform-content dl dd input.aform-input:focus,
          section.mailForm .aform-content dl dd select:focus {
            border: 1px solid #71bd29;
            outline: none; }
        section.mailForm .aform-content dl dd ul {
          list-style: none; }
        section.mailForm .aform-content dl dd .aform-error .error {
          font-size: 12px;
          color: #fff;
          background-color: #f00;
          padding: 5px;
          font-weight: 700; }
        section.mailForm .aform-content dl dd input[type=checkbox], section.mailForm .aform-content dl dd input[type=radio] {
          display: none; }
        section.mailForm .aform-content dl dd .aform-checkbox-ul label {
          position: relative;
          padding-left: 35px;
          display: inline-block; }
          section.mailForm .aform-content dl dd .aform-checkbox-ul label:before {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background-color: #fff;
            border: 1px solid #000;
            position: absolute;
            left: 8px;
            top: 6px; }
          section.mailForm .aform-content dl dd .aform-checkbox-ul label:hover {
            cursor: pointer; }
        section.mailForm .aform-content dl dd .aform-checkbox-ul.aform-field-40 label {
          display: block; }
        section.mailForm .aform-content dl dd .aform-checkbox-ul.aform-field-40 input[type=text] {
          display: block;
          width: calc(100% - 35px);
          margin-left: 35px; }
        section.mailForm .aform-content dl dd .aform-checkbox-ul li {
          display: block;
          width: 100%;
          margin-bottom: 10px; }
        section.mailForm .aform-content dl dd input[type=checkbox] + label:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0; }
        section.mailForm .aform-content dl dd input[type=checkbox]:checked + label:after {
          content: '';
          position: absolute;
          width: 6px;
          height: 15px;
          border-bottom: 1px solid;
          border-right: 1px solid;
          transform: rotate(45deg);
          left: 13px;
          top: 2px; }
        section.mailForm .aform-content dl dd .aform-field-option-text {
          display: inline-block; }
        section.mailForm .aform-content dl dd .aform-radio-ul label {
          padding-left: 25px;
          position: relative;
          cursor: pointer; }
          section.mailForm .aform-content dl dd .aform-radio-ul label::before {
            content: '';
            width: 16px;
            height: 16px;
            position: absolute;
            border: 1px solid;
            left: 0;
            top: 2px;
            border-radius: 50%; }
        section.mailForm .aform-content dl dd .aform-radio-ul input[type=radio] + label:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0; }
        section.mailForm .aform-content dl dd .aform-radio-ul input[type=radio]:checked + label:after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #000;
          top: 7px;
          left: 5px; }
    section.mailForm .aform-content .aform-button-area {
      text-align: center;
      padding: 30px 0; }
      section.mailForm .aform-content .aform-button-area input[type=submit] {
        width: 100%;
        height: 50px;
        background-color: #71bd29;
        border-right: 0;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        box-shadow: 0 5px 0 #a2ce77;
        margin-bottom: 10px; }
      section.mailForm .aform-content .aform-button-area input[type=button] {
        width: 100%;
        height: 50px;
        background-color: #ccc;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        box-shadow: 4px 6px 0 #eee;
        margin-bottom: 10px; }
    section.mailForm.mediaForm .dl_title {
      border-top: 2px solid #fff; }
    section.mailForm.mediaForm .aform-content dl {
      border-top: 2px solid #fff;
      background-color: #f2f2f2; } }

@media print, screen and (min-width: 651px) {
  s
#secondMain footer {
    margin-top: 180px; }
  #secondHeader {
    padding-top: 205px; }
    #secondHeader #pageName {
      background-color: #1b1b1b;
      height: 92px;
      color: #fff;
      font-size: 36px;
      font-weight: 700;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
  header #menuBtn .txt {
    color: #888788; }
  /* ----------------------- works ----------------- */
  .secondRead {
    padding: 80px 0 0; }
    .secondRead h1, .secondRead .readTitle {
      font-size: 32px;
      text-align: center; }
      .secondRead h1 .eng, .secondRead .readTitle .eng {
        display: block;
        font-size: 34px; }
      .secondRead h1 .ja, .secondRead .readTitle .ja {
        display: block;
        font-size: 16px; }
    .secondRead p {
      max-width: 800px;
      width: 95%;
      margin: 40px auto 0;
      font-size: 16px;
      line-height: 1.8;
      text-align: center; }
  .worksDetail {
    max-width: 1280px;
    width: 95%;
    margin: 110px auto 0; }
    .worksDetail .thumbnail .slick-track {
      transform: none !important;
      width: 100% !important;
      text-align: right;
      margin-left: -5px; }
    .worksDetail .thumbnail .slick-slide {
      overflow: hidden;
      cursor: pointer;
      margin: 10px 5px 0px;
      width: 100px !important; }
      .worksDetail .thumbnail .slick-slide img {
        width: 200px;
        position: relative;
        margin-left: -50px; }
        .worksDetail .thumbnail .slick-slide img:hover {
          opacity: 0.75; }
    .worksDetail .slide.slick-dotted.slick-slider {
      margin-bottom: 0; }
    .worksDetail .slide .slideItem img {
      width: 100%;
      height: auto; }
    .worksDetail .slide .slick-dots {
      bottom: 15px;
      text-align: right;
      padding-right: 15px; }
      .worksDetail .slide .slick-dots li {
        width: 9px;
        height: 9px; }
        .worksDetail .slide .slick-dots li button {
          width: 9px;
          height: 9px;
          background-color: #fff;
          border-radius: 50%; }
        .worksDetail .slide .slick-dots li.slick-active button {
          background-color: #71bd29; }
    .worksDetail h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 50px;
      padding: 0 20px 0 0;
      height: 50px;
      background-color: #fff;
      margin-top: -50px;
      position: relative;
      z-index: 100;
      display: table; }
    .worksDetail .infoWrap {
      display: none; }
    .worksDetail .info {
      display: flex;
      padding: 50px 0;
      border-bottom: 1px solid #a4a4a4; }
      .worksDetail .info .comment {
        flex: 1;
        padding-right: 100px; }
        .worksDetail .info .comment p {
          font-size: 16px;
          line-height: 1.8;
          font-weight: 500; }
        .worksDetail .info .comment a.url {
          display: table;
          font-size: 15px;
          line-height: 1;
          color: #a4a4a4;
          padding: 5px;
          text-decoration: none;
          border-bottom: 1px solid transparent;
          margin-top: 30px; }
          .worksDetail .info .comment a.url:hover {
            border-bottom: 1px solid #a4a4a4; }
      .worksDetail .info .spec {
        display: table; }
        .worksDetail .info .spec dl {
          display: table-row;
          font-size: 14px; }
          .worksDetail .info .spec dl dt, .worksDetail .info .spec dl dd {
            display: table-cell;
            font-weight: 500; }
          .worksDetail .info .spec dl dt {
            padding-right: 2em; }
    .worksDetail .more .btn {
      width: 290px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: 2px solid #7a7a7a;
      margin: 35px auto 0;
      cursor: pointer;
      transition: all .3s; }
      .worksDetail .more .btn:before {
        content: "";
        width: 14px;
        height: 2px;
        background-color: #000;
        display: block;
        position: absolute;
        top: 24px;
        left: 15px;
        transition: all .4s; }
      .worksDetail .more .btn:after {
        content: "";
        width: 2px;
        height: 14px;
        background-color: #000;
        display: block;
        position: absolute;
        top: 18px;
        left: 21px;
        transition: all .4s; }
      .worksDetail .more .btn:hover {
        opacity: .7; }
      .worksDetail .more .btn.isOpened:before {
        transform: rotate(360deg); }
      .worksDetail .more .btn.isOpened:after {
        transform: rotate(-360deg);
        opacity: 0; }
  /* ---------------- lecture ------------------- */
  #lecture {
    max-width: 1280px;
    width: 95%;
    margin: 60px auto 0;
    border-top: 1px solid #ddd; }
    #lecture .lecMenu {
      display: flex; }
      #lecture .lecMenu .about {
        flex: 1;
        border-bottom: 1px solid #ddd;
        padding: 40px 40px 40px 0; }
        #lecture .lecMenu .about h2 {
          font-size: 22px;
          font-weight: 700; }
        #lecture .lecMenu .about p {
          font-size: 15px;
          line-height: 1.8;
          margin-top: 20px; }
      #lecture .lecMenu .price {
        width: 370px;
        background-color: #ddd;
        border-bottom: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 1; }
        #lecture .lecMenu .price span {
          font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif";
          font-size: 16px; }
    #lecture p.caption {
      font-size: 15px;
      line-height: 1.3;
      margin-top: .5em; }
  .askMe {
    margin-top: 60px;
    padding-bottom: 30px; }
    .askMe h3 {
      font-size: 16px;
      font-weight: 500;
      text-align: center; }
    .askMe .contactBtn {
      width: 480px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      line-height: 1;
      color: #fff;
      font-weight: 700;
      text-decoration: none;
      background-color: #fff;
      border: 2px solid #000;
      transition: all .3s;
      margin: 30px auto 0;
      position: relative;
      box-shadow: 4px 6px 0 #d2d2d2;
      z-index: 1; }
      .askMe .contactBtn:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: #000;
        transition: all .3s; }
      .askMe .contactBtn:hover {
        color: #000; }
        .askMe .contactBtn:hover:after {
          width: 0; }
        .askMe .contactBtn:hover .btnArrow svg {
          fill: #000; }
      .askMe .contactBtn .btnArrow {
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        fill: #fff;
        transition: all .3s; }
        .askMe .contactBtn .btnArrow svg {
          fill: #fff; }
    .askMe.col2 {
      text-align: center; }
      .askMe.col2 .contactBtn {
        display: inline-flex;
        margin: 30px 15px 0; }
        .askMe.col2 .contactBtn.green {
          box-shadow: 4px 6px 0 #b4e585;
          border: 2px solid #71bd29; }
          .askMe.col2 .contactBtn.green:after {
            background-color: #71bd29; }
          .askMe.col2 .contactBtn.green:hover {
            color: #71bd29; }
            .askMe.col2 .contactBtn.green:hover:after {
              width: 0; }
            .askMe.col2 .contactBtn.green:hover .btnArrow svg {
              fill: #71bd29; }
  #year {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 40px; }
    #year li {
      margin: 0 3px; }
      #year li a {
        display: block;
        line-height: 1;
        color: #fff;
        background-color: #000;
        padding: 12px 40px;
        position: relative;
        text-decoration: none;
        transition: all .3s;
        width: 160px;
        height: 40px;
        text-align: center; }
        #year li a:after {
          content: "";
          display: block;
          width: 4px;
          height: 4px;
          transform: rotate(45deg);
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          position: absolute;
          right: 10px;
          top: 16px; }
        #year li a:hover {
          opacity: .7; }
  /* ---------------------------- SECRET SEMINER -------------------- */
  #seminarHeader {
    display: flex; }
    #seminarHeader .img {
      width: 50%;
      background-image: url(../images/club/seminar-top.jpg);
      background-size: cover;
      background-position: right top; }
    #seminarHeader .info {
      width: 50%;
      background-color: #000;
      padding: 50px 0 50px 80px; }
      #seminarHeader .info .title {
        color: #404040;
        font-size: 18px;
        font-weight: 700; }
      #seminarHeader .info .month {
        color: #fff;
        font-size: 70px; }
      #seminarHeader .info .dress {
        font-size: 18px;
        color: #fff; }
      #seminarHeader .info .pdf {
        display: inline-block;
        font-size: 16px;
        color: #fff;
        font-weight: 400;
        margin-top: 40px;
        padding-right: 30px;
        background-image: url(../images/member/icon-pdf-white.png);
        background-repeat: no-repeat;
        background-position: right 50%; }
  #schedule {
    background-color: #e5e5e5; }
    #schedule #scheduleTitle {
      display: none; }
    #schedule ul {
      display: flex;
      justify-content: center;
      max-width: 1280px;
      width: 90%;
      margin: 0 auto;
      list-style: none;
      padding: 40px 0; }
      #schedule ul li {
        display: flex;
        margin-right: 50px;
        position: relative; }
        #schedule ul li:not(:last-of-type):after {
          content: "/";
          display: block;
          width: 50px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          position: absolute;
          top: 0;
          right: -50px;
          font-size: 30px;
          font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif"; }
        #schedule ul li .day {
          width: 40px;
          height: 40px;
          background-color: #fff;
          font-size: 22px;
          line-height: 40px;
          text-align: center; }
        #schedule ul li .place {
          background-image: url(../images/club/pointer.png);
          background-repeat: no-repeat;
          background-position: 0 50%;
          font-size: 16px;
          padding-left: 34px;
          height: 40px;
          line-height: 40px;
          margin-left: 18px;
          text-decoration: none;
          color: #000;
          transition: all .3s; }
          #schedule ul li .place:hover {
            opacity: .7; }
  #seminarArchive .inner {
    max-width: 1280px;
    width: 95%;
    margin: 60px auto 0;
    padding-bottom: 50px; }
    #seminarArchive .inner h2 {
      font-weight: 700;
      font-size: 36px;
      text-align: center; }
      #seminarArchive .inner h2 span {
        display: block;
        font-size: 16px; }
    #seminarArchive .inner .archiveItem {
      display: flex;
      margin-top: 80px;
      margin-bottom: 35px;
      border-bottom: 2px solid #e5e5e5;
      padding-bottom: 35px; }
      #seminarArchive .inner .archiveItem .poster {
        max-width: 624px;
        width: 50%;
        position: relative;
        text-align: center; }
        #seminarArchive .inner .archiveItem .poster img {
          width: 100%;
          height: auto; }
        #seminarArchive .inner .archiveItem .poster .date {
          position: absolute;
          background-color: #71bd29;
          top: -25px;
          left: 50%;
          transform: translateX(-50%);
          height: 50px;
          line-height: 50px;
          width: 225px; }
          #seminarArchive .inner .archiveItem .poster .date .tag {
            display: inline-block;
            color: #fff;
            font-size: 16px;
            transform: rotate(-90deg);
            width: 50px;
            text-align: center;
            height: 16px;
            line-height: 1;
            position: absolute;
            top: 0;
            left: -45px;
            transform-origin: right top; }
          #seminarArchive .inner .archiveItem .poster .date .mon {
            display: block;
            font-size: 36px;
            color: #fff;
            height: 50px;
            line-height: 50px;
            text-align: center; }
        #seminarArchive .inner .archiveItem .poster a {
          color: #71bd29;
          font-size: 14px;
          padding-top: 10px;
          display: block; }
      #seminarArchive .inner .archiveItem .info {
        flex: 1;
        padding-left: 100px; }
        #seminarArchive .inner .archiveItem .info h3 {
          font-size: 34px;
          color: #71bd29;
          font-weight: 400; }
        #seminarArchive .inner .archiveItem .info .read {
          font-size: 16px;
          font-weight: 700;
          margin-top: 30px; }
        #seminarArchive .inner .archiveItem .info h4 {
          font-size: 16px;
          margin-top: 20px;
          color: #aaa;
          font-weight: 400; }
        #seminarArchive .inner .archiveItem .info .sche {
          display: table;
          list-style: none;
          margin-top: 20px; }
          #seminarArchive .inner .archiveItem .info .sche li {
            display: table-row;
            font-size: 14px; }
            #seminarArchive .inner .archiveItem .info .sche li span {
              display: table-cell; }
              #seminarArchive .inner .archiveItem .info .sche li span:nth-of-type(1) {
                padding-right: 20px; }
        #seminarArchive .inner .archiveItem .info .dressCode {
          font-size: 14px;
          margin-top: 20px; }
  /* ------------------- MEDIA ------------------- */
  #mediaArchve {
    max-width: 1280px;
    width: 95%;
    margin: 0 auto 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 60px; }
    #mediaArchve .mediaItem {
      width: 30%;
      position: relative;
      margin-top: 100px; }
      #mediaArchve .mediaItem.adjust {
        height: 0;
        overflow: hidden; }
      #mediaArchve .mediaItem .info {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.8);
        width: 300px;
        padding: 20px;
        top: 100px;
        right: 0;
        border-right: 2px solid #71bd29;
        border-bottom: 2px solid #71bd29; }
        #mediaArchve .mediaItem .info .date {
          font-size: 24px;
          line-height: 1;
          color: #71bd29;
          text-align: right; }
        #mediaArchve .mediaItem .info p {
          font-size: 16px;
          line-height: 1.8;
          font-weight: 700;
          margin-top: 20px; }
      #mediaArchve .mediaItem .img img {
        border: 1px solid #eee; }
  /* ---------------------- HOW TO USE ------------------- */
  #memberBody.howTo #intro h1 {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-top: 60px; }
  #memberBody.howTo #point9 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-feature-settings: "palt" 1;
    margin-top: 20px; }
    #memberBody.howTo #point9 .point9Item {
      width: 32%;
      border: 3px solid #71bd29;
      border-width: 0 3px 3px 3px;
      background-image: url(../images/howto/check-bg.png);
      margin-top: 40px; }
      #memberBody.howTo #point9 .point9Item .itemHead {
        background-color: #71bd29;
        width: 100%;
        min-height: 190px;
        position: relative;
        background-image: url(../images/howto/star-bg.png);
        background-position: right bottom;
        background-repeat: no-repeat;
        color: #fff;
        display: grid;
        grid-template-rows: 25% 1fr;
        grid-template-columns: 63px 1fr; }
        #memberBody.howTo #point9 .point9Item .itemHead .num {
          height: 190px;
          position: relative;
          grid-row: 1 / 3;
          grid-column: 1 / 2; }
          #memberBody.howTo #point9 .point9Item .itemHead .num .numInner {
            transform: rotate(-90deg);
            transform-origin: left top;
            font-size: 36px;
            white-space: nowrap;
            position: absolute;
            width: 190px;
            top: 190px;
            text-align: center; }
            #memberBody.howTo #point9 .point9Item .itemHead .num .numInner span {
              display: inline-block;
              font-size: 24px;
              margin-right: 10px; }
        #memberBody.howTo #point9 .point9Item .itemHead h2 {
          font-size: 16px;
          border-bottom: 3px solid #fff;
          border-left: 3px solid #fff;
          padding: 20px;
          display: flex;
          align-items: center;
          line-height: 1.3;
          grid-row: 1 / 2;
          grid-column: 2 / 3; }
        #memberBody.howTo #point9 .point9Item .itemHead h3 {
          border-left: 3px solid #fff;
          font-size: 20px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          line-height: 1.3;
          grid-row: 2 / 3;
          grid-column: 2 / 3; }
      #memberBody.howTo #point9 .point9Item .itemCont {
        padding: 20px;
        font-size: 15px; }
        #memberBody.howTo #point9 .point9Item .itemCont ul, #memberBody.howTo #point9 .point9Item .itemCont ol {
          padding-left: 1.5em;
          line-height: 2; }
          #memberBody.howTo #point9 .point9Item .itemCont ul ul, #memberBody.howTo #point9 .point9Item .itemCont ol ul {
            list-style: disc; }
  /* --------------------- BOOKS -------------------- */
  .bookCat {
    font-size: 34px;
    text-align: center;
    margin-top: 60px; }
  .bookList {
    max-width: 1280px;
    width: 95%;
    margin: 0 auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 60px; }
    .bookList .bookItem {
      width: 48%;
      position: relative;
      padding-left: 70px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 80px;
      position: relative;
      padding-bottom: 80px; }
      .bookList .bookItem:before {
        width: 2px;
        height: 300px;
        content: "";
        display: block;
        background-color: #71bd29;
        position: absolute;
        top: 0;
        left: 0; }
      .bookList .bookItem:after {
        width: 300px;
        height: 2px;
        content: "";
        display: block;
        background-color: #71bd29;
        position: absolute;
        bottom: 0;
        left: 40px; }
      .bookList .bookItem .num {
        font-size: 34px;
        line-height: 1;
        color: #fff;
        background-color: #71bd29;
        position: absolute;
        width: 185px;
        height: 50px;
        transform: rotate(-90deg);
        transform-origin: top left;
        top: 185px;
        left: 0;
        line-height: 50px;
        text-align: center; }
        .bookList .bookItem .num span {
          font-size: 36px; }
      .bookList .bookItem .about {
        flex: 1;
        order: 0;
        padding-right: 30px; }
        .bookList .bookItem .about h3 {
          font-size: 24px;
          color: #71bd29; }
        .bookList .bookItem .about .author {
          font-size: 16px;
          font-weight: 700;
          margin-top: 15px; }
        .bookList .bookItem .about .info {
          font-size: 14px;
          line-height: 1.8;
          margin-top: 20px; }
      .bookList .bookItem .bookImg {
        width: 160px;
        order: 1; }
        .bookList .bookItem .bookImg img {
          border: 1px solid #000;
          width: 100%;
          height: auto; }
      .bookList .bookItem .buyBtn {
        width: 100%;
        order: 2;
        text-align: right;
        padding: 0 0 20px;
        position: absolute;
        height: 60px;
        left: 0;
        bottom: 0; }
        .bookList .bookItem .buyBtn a {
          display: inline-block;
          width: 160px;
          height: 40px;
          background-color: #000;
          color: #fff;
          font-weight: 700;
          text-align: center;
          line-height: 40px;
          text-decoration: none;
          position: relative;
          transition: all .3s;
          border: 2px solid #000;
          background-color: #fff;
          z-index: 1;
          box-shadow: 2px 3px 0 #d2d2d2; }
          .bookList .bookItem .buyBtn a:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            background-color: #000;
            transition: all .3s; }
          .bookList .bookItem .buyBtn a:after {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            transform: rotate(45deg);
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            position: absolute;
            top: 16px;
            right: 15px; }
          .bookList .bookItem .buyBtn a:hover {
            color: #000; }
            .bookList .bookItem .buyBtn a:hover:before {
              width: 0; }
            .bookList .bookItem .buyBtn a:hover:after {
              animation: buyArw 2s infinite;
              border-color: #000; }
  @keyframes buyArw {
    50% {
      right: 5px; } }
  /* --------------- sns ---------------------- */
  #sns {
    max-width: 1200px;
    width: 95%;
    margin: 60px auto 0; }
    #sns #snsList {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      list-style: none; }
      #sns #snsList .snsLink {
        width: 48%;
        display: flex;
        margin-top: 60px; }
        #sns #snsList .snsLink .img {
          width: 298px;
          border: 1px solid #f2f2f2; }
        #sns #snsList .snsLink .txt {
          flex: 1;
          border-left: 1px solid #f2f2f2;
          padding: 30px 40px;
          position: relative; }
          #sns #snsList .snsLink .txt p {
            font-size: 18px;
            font-weight: 700;
            text-indent: -1.1em;
            padding-left: 1.3em; }
            #sns #snsList .snsLink .txt p .num {
              font-size: 24px;
              font-weight: 400;
              color: #71bd29;
              display: inline-block;
              padding-right: .1em; }
          #sns #snsList .snsLink .txt .icons {
            display: flex;
            list-style: none;
            position: absolute;
            position: absolute;
            left: 30px;
            bottom: 40px; }
            #sns #snsList .snsLink .txt .icons li {
              margin-right: 10px; }
              #sns #snsList .snsLink .txt .icons li a {
                display: block;
                transition: all .3s; }
                #sns #snsList .snsLink .txt .icons li a:hover {
                  opacity: .7; }
  /* ----------------- contact ------------------ */
  .contactBtn {
    max-width: 1200px;
    width: 95%;
    margin: 40px auto 0;
    display: flex;
    justify-content: center;
    background-color: #e5e5e5;
    padding: 40px 0 35px; }
    .contactBtn a {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 1%;
      max-width: 48%;
      width: 485px;
      min-height: 70px;
      background-color: #fff;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      text-decoration: none;
      box-shadow: 4px 6px 0 #b4e585;
      border: 2px solid #71bd29;
      position: relative;
      transition: all .3s;
      z-index: 1;
      overflow: hidden; }
      .contactBtn a:after {
        /*
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				background-image: url(../images/second/btn-arrow.png);
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
				*/
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #71bd29;
        z-index: -1;
        transition: all .3s; }
      .contactBtn a:hover {
        color: #71bd29; }
        .contactBtn a:hover:after {
          right: -100%; }
  /* ---------------- charity ----------------- */
  #charityArchive {
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;
    padding-top: 20px; }
    #charityArchive .slick-dots {
      bottom: 15px;
      text-align: right;
      padding-right: 15px; }
      #charityArchive .slick-dots li {
        width: 9px;
        height: 9px; }
        #charityArchive .slick-dots li.slick-active button {
          background-color: #71bd29; }
        #charityArchive .slick-dots li button {
          width: 9px;
          height: 9px;
          background-color: #fff;
          border-radius: 50%; }
    #charityArchive .charityList {
      width: 100%;
      display: flex;
      margin-top: 70px;
      padding-bottom: 60px;
      border-bottom: 1px solid #e5e5e5; }
      #charityArchive .charityList .img {
        width: 640px; }
        #charityArchive .charityList .img .slick-dots {
          top: 390px; }
      #charityArchive .charityList .txt {
        flex: 1;
        padding-left: 110px; }
        #charityArchive .charityList .txt h2 {
          font-size: 30px;
          font-weight: 700; }
        #charityArchive .charityList .txt .info {
          font-size: 16px;
          line-height: 1.8;
          margin-top: 40px; }
      #charityArchive .charityList .link {
        font-size: 15px;
        color: #a4a4a4;
        margin-top: 40px;
        display: inline-block;
        position: relative;
        text-decoration: none; }
        #charityArchive .charityList .link:hover {
          text-decoration: underline; }
        #charityArchive .charityList .link .blank {
          width: 15px;
          height: 15px;
          display: block;
          position: absolute;
          top: 0;
          right: -20px; }
          #charityArchive .charityList .link .blank svg {
            width: auto;
            height: 13px;
            fill: #a4a4a4; }
      #charityArchive .charityList .movie {
        margin-top: 40px;
        background-color: #e5e5e5;
        padding: 35px;
        display: block; }
        #charityArchive .charityList .movie .movInfo {
          padding-right: 20px;
          margin-top: 20px; }
          #charityArchive .charityList .movie .movInfo:first-child {
            margin-top: 0; }
          #charityArchive .charityList .movie .movInfo .title {
            background-image: url(../images/common/icon-movie.png);
            background-repeat: no-repeat;
            background-position: 0 5px;
            padding-left: 30px;
            font-size: 15px; }
          #charityArchive .charityList .movie .movInfo .url {
            color: #a4a4a4;
            font-size: 15px;
            text-decoration: none; }
            #charityArchive .charityList .movie .movInfo .url:hover {
              text-decoration: underline; }
        #charityArchive .charityList .movie .tn {
          width: 140px; }
  /* --------------- base layout -------------- */
  main.aboutIndex {
    background-image: url(../images/second/baku.png);
    background-repeat: no-repeat;
    background-position: 95% 632px; }
  .txtCont {
    max-width: 1200px;
    width: 95%;
    margin: 60px auto 0;
    padding-bottom: 30px; }
    .txtCont h2 {
      font-size: 34px;
      font-weight: 400;
      text-align: center; }
      .txtCont h2 .ja {
        font-size: 15px;
        font-weight: 700;
        display: block;
        margin: 0 auto; }
    .txtCont h3 {
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      margin-top: 40px; }
    .txtCont p {
      font-size: 15px;
      line-height: 1.8;
      margin-top: 40px; }
      .txtCont p.tac {
        text-align: center; }
    .txtCont .tableLayout {
      border-bottom: 2px solid #000;
      margin-top: 40px; }
      .txtCont .tableLayout dl {
        display: flex;
        border-top: 2px solid #000;
        padding: 30px 0;
        font-size: 15px;
        line-height: 1.8; }
        .txtCont .tableLayout dl dt {
          width: 30%;
          padding-left: 40px; }
        .txtCont .tableLayout dl dd {
          flex: 1;
          padding-right: 40px; }
          .txtCont .tableLayout dl dd.flex {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap; }
            .txtCont .tableLayout dl dd.flex > span {
              width: 48%; }
              .txtCont .tableLayout dl dd.flex > span.map {
                width: 100%;
                margin-top: 50px; }
                .txtCont .tableLayout dl dd.flex > span.map img {
                  max-width: 100%;
                  width: auto;
                  height: auto; }
    .txtCont a.btn {
      background-color: #000;
      display: inline-block;
      color: #fff;
      text-decoration: none;
      line-height: 1;
      padding: 5px 35px 5px 15px;
      position: relative;
      margin-top: 10px; }
      .txtCont a.btn:after {
        display: block;
        content: "";
        width: 15px;
        height: 15px;
        background-image: url(../images/second/btn-arrow.png);
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%); }
  .basic_list {
    list-style: none;
    margin-top: 20px; }
    .basic_list li {
      padding-left: 20px;
      position: relative; }
      .basic_list li::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 10px;
        left: 3px;
        background-color: #71bd29; }
  .team .inner {
    max-width: 1280px;
    width: 95%;
    margin: 40px auto 0;
    display: grid;
    grid-template-rows: 150px 1fr;
    grid-template-columns: 50% 50%; }
    .team .inner h2 {
      grid-row: 1;
      grid-column: 2;
      font-size: 32px;
      line-height: 1;
      padding-left: 40px;
      background-image: url(../images/ryumurao-team/name-bg-gray.png);
      background-repeat: no-repeat;
      background-position: 225px 0; }
      .team .inner h2 .kana {
        display: block;
        font-size: 24px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        padding: 20px 0;
        margin-top: 15px; }
    .team .inner .img {
      grid-row: 1 / 3;
      grid-column: 1 / 2; }
      .team .inner .img img {
        width: 100%;
        height: auto; }
    .team .inner .info {
      grid-row: 2;
      grid-column: 2;
      padding-left: 40px; }
      .team .inner .info p {
        font-size: 15px;
        line-height: 1.8; }
      .team .inner .info .web {
        text-align: right;
        font-size: 15px;
        font-weight: 700;
        margin-top: 40px; }
        .team .inner .info .web a {
          color: #737373;
          font-size: 18px; }
  .team2col {
    background-color: #e5e5e5;
    padding: 60px 0;
    margin-top: 80px; }
    .team2col .inner {
      max-width: 1280px;
      width: 95%;
      margin: 0 auto 0;
      display: flex;
      justify-content: space-between; }
      .team2col .inner .member {
        background-color: #fff;
        background-size: auto 100%;
        background-position: calc(100% + 25px) top;
        background-repeat: no-repeat;
        width: 48%;
        padding: 30px; }
        .team2col .inner .member h2 {
          font-size: 22px;
          line-height: 1;
          height: 80px;
          background-image: url(../images/ryumurao-team/name-bg-gray.png);
          background-repeat: no-repeat;
          background-position: 90px 0;
          padding-top: 16px; }
          .team2col .inner .member h2 span {
            font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif";
            font-size: 16px;
            display: block;
            margin-top: 15px; }
        .team2col .inner .member p {
          width: 45%;
          margin-top: 15px; }
        .team2col .inner .member#kentaro {
          background-image: url(../images/ryumurao-team/kentaro.jpg); }
        .team2col .inner .member#miyuki {
          background-image: url(../images/ryumurao-team/miyuki.jpg); } }
  @media screen and (min-width: 651px) and (max-width: 900px) and (min-width: 600px) {
    .team2col {
      padding: 0px 0 30px;
      margin-top: 40px; }
      .team2col .inner {
        display: block;
        width: 90%;
        margin: 0 auto 0;
        padding-top: 1px; }
        .team2col .inner .member {
          background-color: #fff;
          background-position: right top;
          background-repeat: no-repeat;
          background-size: auto 110px;
          width: 100%;
          padding: 15px;
          margin-top: 30px; }
          .team2col .inner .member h2 {
            font-size: 22px;
            line-height: 1;
            height: 100px;
            padding-top: 40px;
            background-image: url(../images/ryumurao-team/name-bg-gray.png);
            background-repeat: no-repeat;
            background-position: 90px 0; }
            .team2col .inner .member h2 span {
              font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, "sans-serif";
              font-size: 15px;
              display: block;
              margin-top: 5px; }
          .team2col .inner .member p {
            width: 100%;
            margin-top: 20px; }
          .team2col .inner .member#kentaro {
            background-image: url(../images/ryumurao-team/kentaro.jpg); }
          .team2col .inner .member#miyuki {
            background-image: url(../images/ryumurao-team/miyuki.jpg); } }

@media print, screen and (min-width: 651px) {
  #ourTeam {
    margin-top: 80px; }
    #ourTeam .inner {
      max-width: 1280px;
      width: 95%;
      margin: 0 auto; }
      #ourTeam .inner h2 {
        font-size: 28px;
        font-weight: 400;
        text-align: center; }
      #ourTeam .inner .teamTable {
        margin-top: 40px;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        display: flex;
        width: 100%; }
        #ourTeam .inner .teamTable .our {
          width: 50%;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd; }
          #ourTeam .inner .teamTable .our a {
            color: #000;
            text-decoration: none;
            display: block;
            display: flex;
            align-items: center;
            padding: 30px;
            position: relative;
            overflow: hidden;
            z-index: 1; }
            #ourTeam .inner .teamTable .our a:after {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background-color: #71bd29;
              transition: all .3s ease-out;
              position: absolute;
              top: 0;
              left: -100%;
              z-index: -1; }
            #ourTeam .inner .teamTable .our a:hover {
              color: #fff; }
              #ourTeam .inner .teamTable .our a:hover .svg {
                fill: #fff; }
              #ourTeam .inner .teamTable .our a:hover:after {
                left: 0; }
            #ourTeam .inner .teamTable .our a .logo {
              width: 134px;
              height: 100px;
              padding-left: 20px; }
              #ourTeam .inner .teamTable .our a .logo .svg {
                width: 100px;
                height: 100px; }
            #ourTeam .inner .teamTable .our a .txt {
              flex: 1;
              padding-left: 25px; }
              #ourTeam .inner .teamTable .our a .txt h3 {
                font-size: 22px;
                display: inline-block; }
                #ourTeam .inner .teamTable .our a .txt h3 .iconBlank {
                  width: 17px;
                  margin-left: 10px; }
                  #ourTeam .inner .teamTable .our a .txt h3 .iconBlank .svg {
                    width: auto;
                    height: 17px; }
              #ourTeam .inner .teamTable .our a .txt p {
                font-size: 15px; }
  #memberHeader {
    padding-top: 205px; }
    #memberHeader #pageName {
      background-color: #71bd29;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      color: #fff;
      font-weight: 700;
      line-height: 1; }
    #memberHeader #pageName {
      height: 155px;
      font-size: 44px;
      padding-left: calc((40px + 85px) / 2); }
      #memberHeader #pageName:after {
        content: "";
        display: block;
        width: 85px;
        height: 88px;
        background-image: url(../images/member/title-img.png);
        margin-left: 40px; }
  #memberBody {
    max-width: 1280px;
    width: 95%;
    margin: 0 auto; }
    #memberBody #intro {
      padding-top: 50px; }
      #memberBody #intro h2 {
        font-size: 34px;
        text-align: center;
        font-weight: 400; }
        #memberBody #intro h2 span {
          display: block;
          text-align: center;
          font-size: 15px; }
      #memberBody #intro p {
        font-size: 15px;
        line-height: 1.8;
        margin: 30px auto 0;
        display: table; }
    #memberBody .toMenu {
      display: block;
      margin: 20px auto;
      width: 480px;
      text-align: center; }
      #memberBody .toMenu a {
        display: block;
        text-decoration: none;
        cursor: pointer;
        padding: 20px;
        transition: all .3s;
        color: #fff;
        border: 2px solid #71bd29;
        position: relative;
        font-weight: 700;
        font-size: 18px;
        overflow: hidden;
        z-index: 1;
        box-shadow: 4px 6px 0 #b4e585; }
        #memberBody .toMenu a::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-color: #71bd29;
          position: absolute;
          right: 0;
          top: 0;
          transition: all .3s;
          z-index: -1; }
        #memberBody .toMenu a:hover {
          color: #71bd29; }
          #memberBody .toMenu a:hover:after {
            right: -100%; }
    #memberBody #loginForm {
      width: 100%;
      background-color: #e5e5e5;
      padding: 40px 0 0;
      margin-top: 60px; }
      #memberBody #loginForm #pass h2 {
        color: #71bd29;
        font-size: 34px;
        font-weight: 700;
        text-align: center; }
        #memberBody #loginForm #pass h2 span {
          font-size: 14px;
          font-weight: 700;
          padding-left: 30px;
          color: #000; }
      #memberBody #loginForm #pass form {
        display: block;
        margin: 40px auto 0; }
        #memberBody #loginForm #pass form .inputTxt {
          max-width: 644px;
          width: 100%;
          margin: 0 auto;
          padding-bottom: 30px; }
        #memberBody #loginForm #pass form input[type=password] {
          width: 100%;
          height: 50px;
          outline: none;
          border: 2px solid #d0d0d0; }
      #memberBody #loginForm #pass #login {
        background-color: #fff;
        text-align: center;
        padding: 30px 0; }
        #memberBody #loginForm #pass #login button {
          width: 485px;
          height: 70px;
          color: #fff;
          background-color: #000;
          border: none;
          cursor: pointer;
          transition: all .3s;
          box-shadow: 4px 6px 0 #d2d2d2;
          font-weight: 700; }
          #memberBody #loginForm #pass #login button:hover {
            opacity: .7; }
    #memberBody #seminar {
      display: flex; }
      #memberBody #seminar .img {
        width: 50%;
        position: relative;
        overflow: hidden; }
        #memberBody #seminar .img img {
          display: block;
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%); }
      #memberBody #seminar .txt {
        background-color: #000;
        color: #fff;
        width: 50%;
        padding: 30px 30px 30px 80px;
        line-height: 1.3; }
        #memberBody #seminar .txt h2 {
          font-size: 18px;
          font-weight: 700;
          line-height: 1;
          color: #404040; }
        #memberBody #seminar .txt .date {
          font-size: 70px;
          font-weight: 700;
          line-height: 1.4;
          margin-top: 5px; }
        #memberBody #seminar .txt .conditions {
          font-size: 18px;
          margin-top: 5px; }
        #memberBody #seminar .txt .more {
          width: 247px;
          height: 60px;
          background-color: #fff;
          color: #000;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          position: relative;
          margin-top: 80px; }
          #memberBody #seminar .txt .more:after {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            background-image: url(../images/member/arrow-black.png);
            position: absolute;
            top: 21px;
            right: 15px; }
        #memberBody #seminar .txt .download a {
          font-size: 16px;
          margin-top: 20px;
          display: inline-block;
          color: #fff;
          text-decoration: none;
          position: relative; }
          #memberBody #seminar .txt .download a:after {
            content: "";
            display: inline-block;
            width: 21px;
            height: 22px;
            background-image: url(../images/member/icon-pdf-white.png);
            position: absolute;
            top: -5px;
            right: -25px; }
    #memberBody #merit {
      display: flex;
      justify-content: space-between;
      max-width: 1280px;
      width: 100%;
      margin: 50px auto 0; }
      #memberBody #merit a {
        border: 2px solid #71bd29;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 410px;
        width: 48%;
        min-height: 106px;
        font-size: 18px;
        line-height: 1.8;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        padding: 20px;
        position: relative;
        transition: all .3s;
        margin-right: 25px;
        z-index: 1;
        overflow: hidden;
        box-shadow: 4px 6px 0 #b4e585; }
        #memberBody #merit a:last-child {
          margin-right: 0; }
        #memberBody #merit a:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          background-color: #71bd29;
          transition: all .3s;
          z-index: -1; }
        #memberBody #merit a:hover {
          color: #71bd29; }
          #memberBody #merit a:hover:after {
            right: -100%; }
    #memberBody #membersInfo {
      margin-top: 60px;
      padding-top: 40px;
      border-top: 2px solid #e5e5e5; }
      #memberBody #membersInfo h2 {
        font-size: 24px;
        font-weight: 700;
        text-align: center; }
      #memberBody #membersInfo ul {
        list-style: none;
        display: flex;
        justify-content: center;
        margin: 40px auto 0;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000; }
        #memberBody #membersInfo ul li {
          position: relative;
          padding: 0 3vw; }
          #memberBody #membersInfo ul li:not(:last-of-type):after {
            content: "";
            display: block;
            width: 1px;
            height: 15px;
            background-color: #000;
            position: absolute;
            top: 20px;
            right: 0; }
          #memberBody #membersInfo ul li a {
            display: block;
            font-size: 16px;
            font-weight: 700;
            line-height: 17px;
            text-align: center;
            color: #000;
            text-decoration: none;
            padding: 20px 25px 20px 0;
            background-image: url(../images/member/icon-blank.png);
            background-repeat: no-repeat;
            background-position: right 50%; }
      #memberBody #membersInfo #askMe {
        margin-top: 60px;
        padding-bottom: 30px; }
        #memberBody #membersInfo #askMe h3 {
          font-size: 16px;
          font-weight: 500;
          text-align: center; }
        #memberBody #membersInfo #askMe .contactBtn {
          width: 480px;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          line-height: 1;
          color: #fff;
          font-weight: 700;
          text-decoration: none;
          background-color: #fff;
          transition: all .3s;
          margin: 30px auto 0;
          box-shadow: 4px 6px 0 #d2d2d2;
          border: 2px solid #000;
          position: relative;
          z-index: 1;
          overflow: hidden; }
          #memberBody #membersInfo #askMe .contactBtn:after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: #000;
            z-index: -1;
            position: absolute;
            top: 0;
            right: 0;
            transition: all .3s; }
          #memberBody #membersInfo #askMe .contactBtn:hover {
            color: #000; }
            #memberBody #membersInfo #askMe .contactBtn:hover:after {
              right: -100%; }
  /* ------------------ faq ------------------ */
  #faqList, #priceList {
    border: 1px solid #000;
    padding: 20px 0 0;
    margin-top: 40px; }
    #faqList h3, #priceList h3 {
      font-size: 18px;
      position: relative;
      display: flex;
      cursor: pointer;
      border-bottom: 1px solid #000;
      padding: 0 20px 30px;
      margin-bottom: -1px; }
      #faqList h3:not(:nth-of-type(1)), #priceList h3:not(:nth-of-type(1)) {
        margin-top: 30px; }
      #faqList h3:before, #priceList h3:before {
        content: "";
        display: block;
        width: 25px;
        height: 3px;
        background-color: #000;
        position: absolute;
        top: 21px;
        right: 10px;
        transition: all .4s; }
      #faqList h3:after, #priceList h3:after {
        content: "";
        display: block;
        width: 3px;
        height: 25px;
        background-color: #000;
        position: absolute;
        top: 10px;
        right: 21px;
        transition: all .4s; }
      #faqList h3 .tag, #priceList h3 .tag {
        font-size: 24px;
        font-weight: 700;
        padding: 5px 10px 0;
        margin-right: 20px;
        line-height: 1;
        display: inline-block;
        margin-bottom: -5px;
        transition: all .4s; }
      #faqList h3 .ja, #priceList h3 .ja {
        flex: 1;
        margin-top: 5px; }
      #faqList h3.isOpen .tag, #priceList h3.isOpen .tag {
        background-color: #71bd29;
        color: #fff; }
      #faqList h3.isOpen:before, #priceList h3.isOpen:before {
        transform: rotate(270deg);
        opacity: 0; }
      #faqList h3.isOpen:after, #priceList h3.isOpen:after {
        transform: rotate(270deg); }
    #faqList .faqCat, #faqList .priceCat, #priceList .faqCat, #priceList .priceCat {
      padding: 0 20px 40px;
      display: none; }
      #faqList .faqCat dl, #faqList .priceCat dl, #priceList .faqCat dl, #priceList .priceCat dl {
        margin-top: 30px;
        padding-bottom: 40px;
        border-bottom: 1px solid #000; }
        #faqList .faqCat dl dt, #faqList .priceCat dl dt, #priceList .faqCat dl dt, #priceList .priceCat dl dt {
          font-size: 18px;
          font-weight: 700; }
          #faqList .faqCat dl dt:before, #faqList .priceCat dl dt:before, #priceList .faqCat dl dt:before, #priceList .priceCat dl dt:before {
            content: "Q";
            font-size: 24px;
            font-family: "Allerta Stencil", sans-serif;
            color: #71bd29;
            display: inline-block; }
        #faqList .faqCat dl dd, #faqList .priceCat dl dd, #priceList .faqCat dl dd, #priceList .priceCat dl dd {
          font-size: 15px;
          margin-top: 30px; }
    #faqList .priceCat, #priceList .priceCat {
      padding: 0 30px 50px; }
      #faqList .priceCat dl, #priceList .priceCat dl {
        display: grid;
        grid-template-rows: 50px 1fr;
        grid-template-columns: 1fr 367px;
        padding: 0;
        margin: 0; }
        #faqList .priceCat dl dt, #priceList .priceCat dl dt {
          grid-row: 1 / 2;
          grid-column: 1 / 2;
          padding: 30px 30px 15px 0; }
          #faqList .priceCat dl dt:before, #priceList .priceCat dl dt:before {
            display: none; }
          #faqList .priceCat dl dt span.num, #priceList .priceCat dl dt span.num {
            font-size: 24px;
            font-family: "Allerta Stencil", sans-serif;
            color: #71bd29;
            display: inline-block;
            margin-right: 10px; }
        #faqList .priceCat dl dd, #priceList .priceCat dl dd {
          margin: 0; }
          #faqList .priceCat dl dd.info, #priceList .priceCat dl dd.info {
            grid-row: 2 / 3;
            grid-column: 1 / 2;
            padding: 30px; }
            #faqList .priceCat dl dd.info.col2, #priceList .priceCat dl dd.info.col2 {
              grid-row: 2 / 3;
              grid-column: 1 / 3; }
          #faqList .priceCat dl dd.fee, #priceList .priceCat dl dd.fee {
            grid-row: 1 / 3;
            grid-column: 2 / 3;
            background-color: #e5e5e5;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 700; }
            #faqList .priceCat dl dd.fee span.sten, #priceList .priceCat dl dd.fee span.sten {
              display: inline-block;
              font-size: 24px;
              font-weight: 400; }
  #packPrice {
    margin: 40px auto 0; }
    #packPrice h2 {
      background-color: #efefef;
      font-size: 24px;
      line-height: 1;
      padding: 20px; }
    #packPrice .btns {
      display: flex;
      justify-content: space-between;
      margin-top: 30px; }
      #packPrice .btns a {
        display: block;
        width: 48%;
        border: 2px solid #71bd29;
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        font-weight: 700;
        padding: 20px 60px 20px 20px;
        position: relative;
        transition: all .3s;
        z-index: 1;
        box-shadow: 4px 6px 0 #b4e585; }
        #packPrice .btns a:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-color: #71bd29;
          position: absolute;
          top: 0;
          right: 0;
          z-index: -1;
          transition: all .3s; }
        #packPrice .btns a:hover {
          color: #71bd29; }
          #packPrice .btns a:hover:after {
            width: 0; }
  #indexTop {
    width: 100%;
    height: 355px;
    background-position: center top;
    background-size: cover; }
    #indexTop.club {
      background-image: url(../images/club/index-top.jpg); }
    #indexTop.about {
      background-image: url(../images/about/top-bg.jpg); }
    #indexTop.charity {
      background-image: url(../images/charity/top-img.jpg); }
    #indexTop.noImg {
      height: 100px; }
    #indexTop #pageMenu {
      width: 100%;
      height: 72px;
      background-color: rgba(0, 0, 0, 0.6);
      font-weight: 400; }
      #indexTop #pageMenu h2 {
        font-weight: 400; }
      #indexTop #pageMenu ul {
        width: 100%;
        height: 72px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center; }
        #indexTop #pageMenu ul li {
          font-size: 18px;
          position: relative;
          white-space: nowrap;
          padding-left: 18px;
          color: #fff; }
          #indexTop #pageMenu ul li:not(:last-of-type):after {
            content: "/";
            display: inline-block;
            width: 18px;
            height: 18px; }
          #indexTop #pageMenu ul li a {
            color: #fff;
            text-decoration: none;
            display: inline-block;
            margin-right: 18px;
            transition: all .3s; }
            #indexTop #pageMenu ul li a:hover {
              color: #71bd29; }
    #indexTop h2 {
      font-size: 34px;
      color: #fff;
      max-width: 1200px;
      width: 95%;
      height: 283px;
      margin: 0 auto;
      display: flex;
      align-items: center; }
  #clubTop {
    width: 100%;
    height: 1000px;
    position: relative; }
    #clubTop #photos {
      width: 100%;
      height: 1000px;
      overflow: hidden;
      position: relative;
      list-style: none; }
      #clubTop #photos li {
        position: absolute;
        opacity: 0;
        transition: all .8s; }
        #clubTop #photos li.view {
          opacity: 1;
          transform: rotate(); }
    #clubTop .point {
      width: 570px;
      background-color: #f2f2f2;
      box-shadow: 3px 3px 0 #71bd29;
      padding: 40px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: absolute; }
      #clubTop .point .num {
        width: 40px;
        height: 24px;
        font-size: 24px;
        position: absolute;
        color: #71bd29;
        top: 20px;
        left: 20px; }
      #clubTop .point h3 {
        font-size: 20px;
        text-align: center;
        position: relative;
        padding-bottom: 15px; }
        #clubTop .point h3:after {
          content: "";
          display: block;
          width: 200px;
          height: 2px;
          background-color: #71bd29;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto; }
      #clubTop .point p {
        margin-top: 15px;
        font-size: 14px;
        line-height: 1.8; }
      #clubTop .point#point1 {
        top: -50px;
        right: 10%; }
      #clubTop .point#point2 {
        top: 300px;
        left: 10%; }
      #clubTop .point#point3 {
        bottom: 30px;
        right: 5%; }
  #clubService {
    max-width: 1200px;
    width: 95%;
    margin: 0 auto; }
    #clubService h2 {
      font-size: 34px;
      line-height: 1;
      color: #71bd29;
      text-align: center;
      font-weight: 400; }
      #clubService h2 .sten {
        font-size: 60px;
        display: inline-block;
        padding: 0 10px; }
      #clubService h2 .ja {
        color: #000;
        font-size: 18px;
        display: block;
        margin: 10px auto 0; }
    #clubService ul {
      list-style: none;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px; }
      #clubService ul li {
        background-color: #71bd29;
        color: #fff;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        width: calc(100% / 3);
        padding: 80px 30px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all .6s; }
        #clubService ul li:nth-of-type(1) {
          transition-delay: 0; }
        #clubService ul li:nth-of-type(2) {
          transition-delay: .1s; }
        #clubService ul li:nth-of-type(3) {
          transition-delay: .2s; }
        #clubService ul li:nth-of-type(4) {
          transition-delay: .3s; }
        #clubService ul li:nth-of-type(5) {
          transition-delay: .4s; }
        #clubService ul li:nth-of-type(6) {
          transition-delay: .5s; }
        #clubService ul li:nth-of-type(7) {
          transition-delay: .6s; }
        #clubService ul li:nth-of-type(8) {
          transition-delay: .7s; }
        #clubService ul li.view {
          opacity: 1; }
        #clubService ul li .num {
          width: 45px;
          height: 45px;
          font-size: 34px;
          position: absolute;
          top: 15px;
          right: 15px;
          border-bottom: 2px solid #fff; }
        #clubService ul li p {
          font-size: 18px;
          font-weight: 700; }
          #clubService ul li p span {
            display: inline-block;
            font-size: 14px; }
  #division {
    margin-top: 60px;
    overflow: hidden;
    position: relative; }
    #division #devLogo {
      width: 80px;
      height: 82px;
      position: absolute;
      top: 84px;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 100; }
    #division #screen {
      width: 100%;
      height: 250px; }
      #division #screen .slideImg {
        width: 100%;
        height: 250px;
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: 50% 50%;
        background-attachment: fixed;
        /*
				&:nth-of-type(1){background-image: url(../images/home/top1.jpg);}
				&:nth-of-type(2){background-image: url(../images/home/2_aux.jpg);}
				&:nth-of-type(3){background-image: url(../images/home/4_iiwan.jpg);}
				&:nth-of-type(4){background-image: url(../images/home/4_upcon.jpg);}
				*/ }
        #division #screen .slideImg img {
          transition: all 20s; }
        #division #screen .slideImg.CurrentImg img {
          max-width: none;
          max-height: none;
          transform: scale(1.5); }
    #division h2 {
      font-size: 34px;
      text-align: center;
      color: #fff;
      background-color: #000;
      padding-top: 70px; }
    #division .members {
      background-color: #000;
      margin: 0 auto;
      padding-top: 40px; }
      #division .members:last-of-type {
        padding-bottom: 120px; }
      #division .members dl {
        max-width: 1200px;
        width: 95%;
        margin: 0 auto;
        background-image: url(../images/club/icon-dev.png);
        background-repeat: no-repeat;
        display: flex;
        font-size: 18px;
        color: #fff;
        font-weight: 700;
        padding-left: 40px;
        min-height: 36px; }
        #division .members dl dt {
          position: relative;
          padding-right: 40px; }
          #division .members dl dt:after {
            width: 2px;
            height: 40px;
            content: "";
            display: block;
            background-color: #fff;
            transform: rotate(35deg);
            position: absolute;
            right: 10px;
            top: -5px; }
        #division .members dl dd {
          flex: 1;
          padding-left: 20px; }
      #division .members .priceTable {
        max-width: 1200px;
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;
        background-color: #000; }
      #division .members .price {
        background-color: #fff;
        margin: 0 2px 2px 0;
        width: calc(50% - 2px);
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        padding: 10px 0; }
        #division .members .price:nth-of-type(2) {
          transition-delay: .1s; }
        #division .members .price .sten.price {
          font-size: 40px; }
          #division .members .price .sten.price .op0 {
            opacity: 0; }
      #division .members ul {
        width: 100%;
        display: flex;
        list-style: none; }
        #division .members ul li {
          background-color: #fff;
          margin: 0 2px 2px 0;
          width: calc((100% / 8) - 2px);
          font-size: 12px;
          text-align: center;
          padding: 10px 0; }
          #division .members ul li:nth-of-type(1) {
            transition-delay: 0; }
          #division .members ul li:nth-of-type(2) {
            transition-delay: .1s; }
          #division .members ul li:nth-of-type(3) {
            transition-delay: .2s; }
          #division .members ul li:nth-of-type(4) {
            transition-delay: .3s; }
          #division .members ul li:nth-of-type(5) {
            transition-delay: .4s; }
          #division .members ul li:nth-of-type(6) {
            transition-delay: .5s; }
          #division .members ul li:nth-of-type(7) {
            transition-delay: .6s; }
          #division .members ul li:nth-of-type(8) {
            transition-delay: .7s; }
          #division .members ul li span {
            display: block;
            width: 30px;
            height: 30px;
            margin: 5px auto 0; }
  #seminar {
    margin-top: 60px; }
    #seminar #seminarHead {
      width: 100%;
      min-height: 100px;
      position: relative; }
      #seminar #seminarHead #svgContainer {
        width: 50%;
        height: 100px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0; }
        #seminar #seminarHead #svgContainer svg {
          width: 100%; }
      #seminar #seminarHead #headObject {
        max-width: 700px;
        width: 95%;
        margin: 0 auto;
        position: relative;
        z-index: 5; }
        #seminar #seminarHead #headObject h2 {
          font-size: 34px;
          text-align: center; }
          #seminar #seminarHead #headObject h2 span {
            font-size: 16px;
            display: block;
            margin: 0 auto; }
        #seminar #seminarHead #headObject p {
          font-size: 15px;
          line-height: 1.8;
          margin-top: 30px; }
    #seminar #seminarInfo {
      display: flex;
      max-width: 1200px;
      width: 95%;
      margin: 40px auto 0; }
      #seminar #seminarInfo .img {
        width: 50%;
        position: relative;
        overflow: hidden; }
        #seminar #seminarInfo .img img {
          display: block;
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%); }
      #seminar #seminarInfo .txt {
        background-color: #000;
        color: #fff;
        width: 50%;
        padding: 30px 30px 30px 80px;
        line-height: 1.3;
        transition-delay: .2s; }
        #seminar #seminarInfo .txt h2 {
          font-size: 18px;
          font-weight: 700;
          line-height: 1;
          color: #404040; }
        #seminar #seminarInfo .txt .date {
          font-size: 70px;
          font-weight: 400;
          line-height: 1.4;
          margin-top: 5px; }
        #seminar #seminarInfo .txt .conditions {
          font-size: 18px;
          margin-top: 5px; }
        #seminar #seminarInfo .txt .more {
          width: 247px;
          height: 60px;
          background-color: #fff;
          color: #000;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          position: relative;
          margin-top: 80px; }
          #seminar #seminarInfo .txt .more:after {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            background-image: url(../images/member/arrow-black.png);
            position: absolute;
            top: 21px;
            right: 15px; }
        #seminar #seminarInfo .txt .download a {
          font-size: 16px;
          margin-top: 20px;
          display: inline-block;
          color: #fff;
          text-decoration: none;
          position: relative; }
          #seminar #seminarInfo .txt .download a:after {
            content: "";
            display: inline-block;
            width: 21px;
            height: 22px;
            background-image: url(../images/member/icon-pdf-white.png);
            position: absolute;
            top: -5px;
            right: -25px; }
    #seminar #special {
      max-width: 1200px;
      width: 95%;
      margin: 60px auto 0; }
    #seminar #fiveContents {
      width: 100%;
      list-style: none;
      display: flex;
      border: 2px solid #71bd29; }
      #seminar #fiveContents li {
        width: 20%;
        padding: 20px; }
        #seminar #fiveContents li:nth-of-type(1) {
          transition-delay: 0; }
        #seminar #fiveContents li:nth-of-type(2) {
          transition-delay: .1s; }
        #seminar #fiveContents li:nth-of-type(3) {
          transition-delay: .2s; }
        #seminar #fiveContents li:nth-of-type(4) {
          transition-delay: .3s; }
        #seminar #fiveContents li:nth-of-type(5) {
          transition-delay: .4s; }
        #seminar #fiveContents li:not(:last-of-type) {
          border-right: 2px solid #71bd29; }
        #seminar #fiveContents li .img {
          width: 100%; }
          #seminar #fiveContents li .img img {
            width: 100%;
            height: auto; }
        #seminar #fiveContents li .txt {
          margin-top: 20px; }
          #seminar #fiveContents li .txt h3 {
            font-size: 18px;
            color: #71bd29; }
          #seminar #fiveContents li .txt p {
            margin-top: 10px;
            font-size: 15px;
            line-height: 1.6; }
    #seminar .info {
      display: flex;
      justify-content: space-between;
      margin-top: 30px; }
      #seminar .info ul {
        list-style: none;
        font-size: 12px;
        line-height: 2; }
      #seminar .info .contact a {
        width: 487px;
        height: 70px;
        background-color: #71bd29;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 2px 3px 0 #b4e585;
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        transition: all .3s;
        position: relative; }
        #seminar .info .contact a:after {
          content: "";
          display: block;
          width: 15px;
          height: 15px;
          background-image: url(../images/second/btn-arrow.png);
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-7px); }
        #seminar .info .contact a:hover {
          box-shadow: 4px 6px 0 #b4e585;
          opacity: .8; }
  #rules {
    margin-top: 60px; }
    #rules h2 {
      text-align: center;
      font-size: 30px;
      font-weight: 400;
      background-color: #000;
      color: #fff;
      line-height: 1;
      padding: 20px 0;
      text-transform: uppercase; }
    #rules #rulesInner {
      max-width: 1200px;
      width: 95%;
      margin: 0 auto 0;
      display: flex;
      flex-wrap: wrap; }
      #rules #rulesInner dl {
        width: 25%;
        margin-top: 40px; }
        #rules #rulesInner dl dt {
          font-size: 18px;
          line-height: 1;
          text-transform: uppercase; }
          #rules #rulesInner dl dt span {
            font-size: 30px;
            color: #71bd29;
            display: inline-block;
            padding-bottom: 2px;
            border-bottom: 1px solid #71bd29; }
        #rules #rulesInner dl dd {
          font-size: 18px;
          line-height: 1.6;
          margin-top: 15px;
          font-weight: 700; }
    #rules.seminarRules {
      margin-bottom: 60px;
      border: 1px solid #000;
      padding-bottom: 60px; }
  #jobMap {
    background-color: #e5e5e5;
    border-top: 5px dashed #000;
    border-bottom: 5px dashed #000;
    height: 830px;
    width: 100%;
    position: relative;
    margin-top: 60px;
    padding-top: 65px; }
    #jobMap h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #000;
      color: #fff;
      width: 820px;
      height: 70px;
      font-size: 16px;
      margin: 0 auto;
      position: relative; }
      #jobMap h2:before {
        content: "";
        display: block;
        width: 0;
        height: 70px;
        border-left: 30px solid transparent;
        border-bottom: 70px solid #000;
        position: absolute;
        bottom: 0;
        left: -30px; }
      #jobMap h2:after {
        content: "";
        display: block;
        width: 0;
        height: 70px;
        border-right: 30px solid transparent;
        border-top: 70px solid #000;
        position: absolute;
        top: 0;
        right: -30px; }
      #jobMap h2 .eng, #jobMap h2 .sten {
        font-size: 34px;
        margin-left: 10px; }
      #jobMap h2 .icon {
        width: 20px;
        height: 32px;
        margin-left: 10px; }
    #jobMap #jpmap {
      width: 399px;
      height: 487px;
      background-image: url(../images/club/jobmap-jp.png);
      position: absolute;
      top: 233px;
      left: 0;
      right: 0;
      margin: 0 auto; }
      #jobMap #jpmap .flag {
        width: 20px;
        height: 32px;
        position: absolute;
        overflow: hidden; }
        #jobMap #jpmap .flag img {
          display: block;
          width: 20px;
          height: 32px;
          position: absolute;
          left: 0;
          top: 32px;
          transition: all .4s; }
        #jobMap #jpmap .flag.isView img {
          top: 0; }
        #jobMap #jpmap .flag#flag1 {
          top: 168px;
          left: 272px; }
        #jobMap #jpmap .flag#flag2 {
          top: 214px;
          left: 243px; }
          #jobMap #jpmap .flag#flag2 img {
            transition-delay: .2s; }
        #jobMap #jpmap .flag#flag3 {
          top: 271px;
          left: 249px; }
          #jobMap #jpmap .flag#flag3 img {
            transition-delay: .4s; }
        #jobMap #jpmap .flag#flag4 {
          top: 312px;
          left: 160px; }
          #jobMap #jpmap .flag#flag4 img {
            transition-delay: .6s; }
        #jobMap #jpmap .flag#flag5 {
          top: 345px;
          left: 55px; }
          #jobMap #jpmap .flag#flag5 img {
            transition-delay: .8s; }
    #jobMap .diagram {
      width: 207px;
      height: 154px;
      border: 2px solid #71bd29;
      position: absolute;
      background-color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(0);
      transition: all .4s; }
      #jobMap .diagram.isView {
        transform: scale(1); }
      #jobMap .diagram > img {
        opacity: .7; }
      #jobMap .diagram h3 {
        font-size: 18px;
        line-height: 1;
        color: #fff;
        width: 100%;
        height: 18px;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        font-weight: 400; }
      #jobMap .diagram:after {
        display: block;
        content: "";
        width: 0;
        height: 0;
        border-bottom: 10px solid #71bd29;
        border-right: 10px solid #71bd29;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        position: absolute;
        bottom: 0;
        right: 0; }
      #jobMap .diagram .symbol {
        width: 130px;
        height: 130px;
        position: absolute;
        top: -65px;
        left: -65px;
        z-index: 10; }
        #jobMap .diagram .symbol img {
          width: 100%;
          height: auto; }
      #jobMap .diagram#iwate {
        top: 295px;
        left: calc(50% + 238px); }
      #jobMap .diagram#yamagata {
        top: 540px;
        left: calc(50% + 435px);
        transition-delay: .2s; }
      #jobMap .diagram#tokyo {
        top: 615px;
        left: calc(50% + 108px);
        transition-delay: .4s; }
      #jobMap .diagram#osaka {
        top: 295px;
        right: calc(50% + 115px);
        transition-delay: .6s; }
      #jobMap .diagram#fukuoka {
        top: 555px;
        right: calc(50% + 300px);
        transition-delay: .8s; }
  /* ---------------- join us ------------- */
  .sectJoinUs h2 {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    margin-top: 60px; }
    .sectJoinUs h2 .ja {
      font-size: 16px;
      text-align: center;
      display: block; }
  .sectJoinUs.steps {
    margin-top: 40px; }
    .sectJoinUs.steps h2 {
      padding-bottom: 30px; }
    .sectJoinUs.steps h3 {
      max-width: 1200px;
      width: 95%;
      margin: 0 auto;
      background-color: #efefef;
      font-size: 24px;
      font-weight: 700;
      padding: 20px 40px;
      line-height: 1; }
      .sectJoinUs.steps h3 .eng {
        display: inline-block;
        background-color: #71bd29;
        padding: 5px 40px;
        color: #fff;
        font-weight: 400;
        margin-right: 30px; }
    .sectJoinUs.steps .stepList {
      background-color: #e5e5e5;
      padding: 60px;
      max-width: 1200px;
      width: 95%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      list-style: none; }
      .sectJoinUs.steps .stepList li {
        flex: 1;
        background-color: #fff;
        position: relative;
        font-size: 18px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px; }
        .sectJoinUs.steps .stepList li .num {
          display: block;
          width: 36px;
          height: 40px;
          position: absolute;
          font-size: 36px;
          color: #71bd29;
          line-height: 1;
          border-bottom: 1px solid #71bd29;
          top: -15px;
          right: -10px;
          font-weight: 400; }
        .sectJoinUs.steps .stepList li:not(:last-of-type) {
          margin-right: 50px; }
          .sectJoinUs.steps .stepList li:not(:last-of-type):after {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            transform: rotate(45deg);
            border-top: 4px solid #000;
            border-right: 4px solid #000;
            position: absolute;
            top: 50%;
            right: -30px; }
    .sectJoinUs.steps .cap {
      background-color: #e5e5e5;
      padding: 0px 60px 60px 60px;
      max-width: 1200px;
      width: 95%;
      margin: -45px auto 0;
      font-size: 12px; }
      .sectJoinUs.steps .cap ul {
        list-style: none; }
  #kiyaku #kiyakuScroll {
    max-width: 1200px;
    width: 95%;
    height: 240px;
    border: 2px solid #000;
    margin: 30px auto 0;
    overflow-y: scroll; }
    #kiyaku #kiyakuScroll .kiyakuInner {
      padding: 30px 50px; }
      #kiyaku #kiyakuScroll .kiyakuInner p {
        font-size: 12px; }
  #course {
    width: 90%;
    margin: 40px auto 0; }
    #course p {
      text-align: center;
      margin-top: 30px; }
  #course .btns, #modal .btns {
    display: flex;
    justify-content: center;
    padding: 50px 0; }
    #course .btns .button, #modal .btns .button {
      max-width: 485px;
      width: 40%;
      min-height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      background-color: #fff;
      margin: 0 20px;
      box-shadow: 0 5px 0 #a2ce77;
      position: relative;
      transition: all .3s;
      cursor: pointer;
      box-shadow: 4px 6px 0 #b4e585;
      border: 2px solid #71bd29;
      z-index: 1; }
      #course .btns .button:after, #modal .btns .button:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: #71bd29;
        transition: all .3s; }
      #course .btns .button:hover, #modal .btns .button:hover {
        color: #71bd29; }
        #course .btns .button:hover:after, #modal .btns .button:hover:after {
          width: 0; }
  #modal {
    opacity: 0;
    transition: all .3s;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -999; }
    #modal.isShow {
      align-items: center;
      opacity: 1;
      z-index: 999; }
    #modal .modInner {
      background-color: #fff;
      position: relative;
      max-width: 1200px;
      width: 95%;
      margin: 0 auto;
      padding: 40px 0;
      z-index: 9999; }
      #modal .modInner .closeBtn {
        width: 33px;
        height: 33px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer; }
      #modal .modInner h2 {
        font-size: 34px;
        text-align: center; }
      #modal .modInner p {
        text-align: center;
        margin-top: 30px; }
  section.mailForm {
    max-width: 1200px;
    width: 95%;
    margin: 0 auto; }
    section.mailForm .aform-header, section.mailForm .h2 {
      font-size: 34px;
      font-family: "Oswald", "sans-serif", "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "meiryo";
      text-align: center;
      margin-top: 60px;
      padding-bottom: 20px;
      font-weight: 700; }
    section.mailForm .aform-content dl {
      display: flex;
      border-top: 2px solid #000;
      padding: 30px 0;
      font-size: 15px;
      line-height: 1.8; }
      section.mailForm .aform-content dl dt {
        width: 30%;
        padding-left: 40px; }
        section.mailForm .aform-content dl dt label .aform-required {
          font-size: 10px;
          color: #71bd29;
          border: 1px solid #71bd29;
          padding: 1px 4px;
          line-height: 1;
          display: inline-block;
          margin-left: 5px; }
      section.mailForm .aform-content dl dd {
        flex: 1;
        padding-right: 40px; }
        section.mailForm .aform-content dl dd textarea {
          display: block;
          width: 100%; }
        section.mailForm .aform-content dl dd input.aform-input,
        section.mailForm .aform-content dl dd select {
          width: 100%;
          height: 40px;
          padding: 0 15px; }
          section.mailForm .aform-content dl dd input.aform-input:focus,
          section.mailForm .aform-content dl dd select:focus {
            border: 1px solid #71bd29;
            outline: none; }
        section.mailForm .aform-content dl dd ul {
          list-style: none; }
        section.mailForm .aform-content dl dd .aform-error .error {
          font-size: 12px;
          color: #fff;
          background-color: #f00;
          padding: 5px;
          font-weight: 700; }
        section.mailForm .aform-content dl dd input[type=checkbox], section.mailForm .aform-content dl dd input[type=radio] {
          display: none; }
        section.mailForm .aform-content dl dd .aform-checkbox-ul label {
          position: relative;
          padding-left: 35px;
          display: inline-block; }
          section.mailForm .aform-content dl dd .aform-checkbox-ul label:before {
            content: "";
            display: block;
            width: 15px;
            height: 15px;
            background-color: #fff;
            border: 1px solid #000;
            position: absolute;
            left: 8px;
            top: 6px; }
          section.mailForm .aform-content dl dd .aform-checkbox-ul label:hover {
            cursor: pointer; }
        section.mailForm .aform-content dl dd .aform-checkbox-ul.aform-field-40 label {
          display: inline-block;
          width: 200px; }
        section.mailForm .aform-content dl dd .aform-checkbox-ul.aform-field-40 input[type=text] {
          width: 400px; }
        section.mailForm .aform-content dl dd .aform-checkbox-ul li {
          margin-bottom: 10px; }
        section.mailForm .aform-content dl dd input[type=checkbox] + label:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0; }
        section.mailForm .aform-content dl dd input[type=checkbox]:checked + label:after {
          content: '';
          position: absolute;
          width: 6px;
          height: 15px;
          border-bottom: 1px solid;
          border-right: 1px solid;
          transform: rotate(45deg);
          left: 13px;
          top: 2px; }
        section.mailForm .aform-content dl dd .aform-field-option-text {
          display: inline-block;
          margin-left: 20px; }
        section.mailForm .aform-content dl dd .aform-radio-ul label {
          padding-left: 25px;
          position: relative;
          cursor: pointer; }
          section.mailForm .aform-content dl dd .aform-radio-ul label::before {
            content: '';
            width: 16px;
            height: 16px;
            position: absolute;
            border: 1px solid;
            left: 0;
            top: 2px;
            border-radius: 50%; }
        section.mailForm .aform-content dl dd .aform-radio-ul input[type=radio] + label:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0; }
        section.mailForm .aform-content dl dd .aform-radio-ul input[type=radio]:checked + label:after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #000;
          top: 7px;
          left: 5px; }
    section.mailForm .aform-content .aform-button-area {
      text-align: center;
      padding: 30px 0; }
      section.mailForm .aform-content .aform-button-area input[type=submit] {
        width: 40%;
        height: 70px;
        background-color: #71bd29;
        border-right: 0;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        box-shadow: 4px 6px 0 #a2ce77; }
      section.mailForm .aform-content .aform-button-area input[type=button] {
        width: 25%;
        height: 70px;
        background-color: #ccc;
        border: none;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        box-shadow: 4px 6px 0 #eee;
        margin-right: 10px; }
    section.mailForm.mediaForm .dl_title {
      border-top: 2px solid #fff; }
    section.mailForm.mediaForm .aform-content dl {
      border-top: 2px solid #fff;
      background-color: #f2f2f2; } }
