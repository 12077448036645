s
#secondMain{
	@if $type == sp{
		footer{
			margin-top: 120px;
		}
	}

	@if $type == pc{
		footer{
			margin-top: 180px;
		}
	}
}
#secondHeader{
	@if $type == sp{
		padding-top: 85px;

		#pageName{
			background-color: #1b1b1b;
			height: 42px;
			color: #fff;
			font-size: 18px;
			line-height: 1;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	@if $type == pc{
		padding-top: 205px;

		#pageName{
			background-color: #1b1b1b;
			height: 92px;
			color: #fff;
			font-size: 36px;
			font-weight: 700;
			line-height: 1;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}


header{


	#menuBtn{

		.txt{
			color: #888788;
		}
	}
}

/* ----------------------- works ----------------- */

.secondRead{
	@if $type == sp{
		padding: 50px 0 0;

		h1, .readTitle{
			font-size: 22px;
			text-align: center;
		}

		p{
			width: 90%;
			margin: 30px auto 0;
			font-size: 16px;
			line-height: 1.8;
		}

		.eng{
			display: block;
			font-size: 34px;
		}

		.ja{
			display: block;
			font-size: 16px;
		}
	}

	@if $type == pc{
		padding: 80px 0 0;

		h1, .readTitle{
			font-size: 32px;
			text-align: center;

			.eng{
				display: block;
				font-size: 34px;
			}

			.ja{
				display: block;
				font-size: 16px;
			}
		}

		p{
			max-width: 800px;
			width: 95%;
			margin: 40px auto 0;
			font-size: 16px;
			line-height: 1.8;
			text-align: center;
		}
	}
}

.worksDetail{
	@if $type == sp{
		width: 90%;
		margin: 60px auto 0;

		.thumbnail{
			.slick-track{
				transform:none!important;
				width: 102%!important;
				text-align: right;
				margin-left: -5px;
			}
			.slick-slide{
				overflow: hidden;
				cursor: pointer;
				margin: 10px 5px 0px;
				width: calc(20% - 10px)!important;
				img{
					width: 200px;
					position: relative;
					margin-left: -50px;
					&:hover{
						opacity: 0.75;
					}
				}
			}
		}//thumbnail

		.slide{

			&.slick-dotted.slick-slider{
				margin-bottom: 0;
			}

			.slideItem{
				width: 100%;
				overflow: hidden;
				position: relative;
				height: calc(100vw * 0.6);

				img{
					width: auto;
					height: calc(100vw * 0.6);
					position: absolute;
					left: 50%;
					top: 0;
					transform: translateX(-50%);
				}
			}

			.slick-dots{
				bottom: 15px;
				text-align: right;
				padding-right: 15px;

				li{
					width: 9px;
					height: 9px;

					button{
						width: 9px;
						height: 9px;
						background-color: #fff;
						border-radius: 50%;
					}

					&.slick-active{

						button{
							background-color: $green;
						}
					}
				}
			}
		}

		h2{
			font-size: 16px;
			font-weight: 700;
			line-height: 1.5;
			padding: 10px 10px 10px 0;
		}

		.infoWrap{
			display: none;
		}

		.info{
			display: flex;
			flex-direction: column;
			padding: 30px 0;
			border-bottom: 1px solid #a4a4a4;

			.comment{

				p{
					font-size: 16px;
					line-height: 1.8;
					font-weight: 500;
				}

				a.url{
					display: table;
					font-size: 15px;
					line-height: 1;
					color: #a4a4a4;
					text-decoration: none;
					border-bottom: 1px solid transparent;
					margin-top: 20px;

					&:hover{
						border-bottom: 1px solid #a4a4a4;
					}
				}
			}

			.spec{
				display: table;
				margin-top: 20px;

				dl{
					display: table-row;
					font-size: 14px;

					dt, dd{
						display: table-cell;
						font-weight: 500;
					}

					dt{
						padding-right: 2em;
					}
				}
			}
		}

		.more{

			.btn{
				width: 290px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				border: 2px solid #7a7a7a;
				margin: 20px auto 0;
				cursor: pointer;
				transition: all .3s;

				&:before{
					content: "";
					width: 14px;
					height: 2px;
					background-color: #000;
					display: block;
					position: absolute;
					top: 24px;
					left: 15px;
					transition: all .4s;
				}

				&:after{
					content: "";
					width: 2px;
					height: 14px;
					background-color: #000;
					display: block;
					position: absolute;
					top: 18px;
					left: 21px;
					transition: all .4s;
				}

				&:hover{
					opacity: .7;
				}

				&.isOpened{
					&:before{
						transform: rotate(360deg);
					}

					&:after{
						transform: rotate(-360deg);
						opacity: 0;
					}
				}
			}
		}
	}

	@if $type == pc{
		max-width: 1280px;
		width: 95%;
		margin: 110px auto 0;

		.thumbnail{
			.slick-track{
				transform:none!important;
				width: 100%!important;
				text-align: right;
				margin-left: -5px;
			}
			.slick-slide{
				overflow: hidden;
				cursor: pointer;
				margin: 10px 5px 0px;
				width: 100px!important;
				img{
					width: 200px;
					position: relative;
					margin-left: -50px;
					&:hover{
						opacity: 0.75;
					}
				}
			}
		}//thumbnail

		.slide{

			&.slick-dotted.slick-slider{
				margin-bottom: 0;
			}

			.slideItem{

				img{
					width: 100%;
					height: auto;
				}
			}

			.slick-dots{
				bottom: 15px;
				text-align: right;
				padding-right: 15px;

				li{
					width: 9px;
					height: 9px;

					button{
						width: 9px;
						height: 9px;
						background-color: #fff;
						border-radius: 50%;
					}

					&.slick-active{

						button{
							background-color: $green;
						}
					}
				}
			}
		}

		h2{
			font-size: 20px;
			font-weight: 700;
			line-height: 50px;
			padding: 0 20px 0 0;
			height: 50px;
			background-color: #fff;
			margin-top: -50px;
			position: relative;
			z-index: 100;
			display: table;
		}

		.infoWrap{
			display: none;
		}

		.info{
			display: flex;
			padding: 50px 0;
			border-bottom: 1px solid #a4a4a4;

			.comment{
				flex: 1;
				padding-right: 100px;

				p{
					font-size: 16px;
					line-height: 1.8;
					font-weight: 500;
				}

				a.url{
					display: table;
					font-size: 15px;
					line-height: 1;
					color: #a4a4a4;
					padding: 5px;
					text-decoration: none;
					border-bottom: 1px solid transparent;
					margin-top: 30px;

					&:hover{
						border-bottom: 1px solid #a4a4a4;
					}
				}
			}

			.spec{
				display: table;

				dl{
					display: table-row;
					font-size: 14px;

					dt, dd{
						display: table-cell;
						font-weight: 500;
					}

					dt{
						padding-right: 2em;
					}
				}
			}
		}

		.more{

			.btn{
				width: 290px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				border: 2px solid #7a7a7a;
				margin: 35px auto 0;
				cursor: pointer;
				transition: all .3s;

				&:before{
					content: "";
					width: 14px;
					height: 2px;
					background-color: #000;
					display: block;
					position: absolute;
					top: 24px;
					left: 15px;
					transition: all .4s;
				}

				&:after{
					content: "";
					width: 2px;
					height: 14px;
					background-color: #000;
					display: block;
					position: absolute;
					top: 18px;
					left: 21px;
					transition: all .4s;
				}

				&:hover{
					opacity: .7;
				}

				&.isOpened{
					&:before{
						transform: rotate(360deg);
					}

					&:after{
						transform: rotate(-360deg);
						opacity: 0;
					}
				}
			}
		}
	}
}



/* ---------------- lecture ------------------- */

#lecture{
	@if $type == sp{
		margin: 10px auto 0;
		border-top: 1px solid #ddd;

		.lecMenu{
			margin: 30px auto 0;
			width: 90%;

			.about{
				padding: 10px;

				h2{
					font-size: 18px;
					font-weight: 700;
				}

				p{
					font-size: 15px;
					line-height: 1.8;
					margin-top: 20px;
				}
			}

			.price{
				padding: 30px 0;
				background-color: #ddd;
				border-bottom: 1px solid #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 20px;
				font-weight: 700;
				line-height: 1;

				span{
					font-size: 14px;
					font-family: $ff_ja;
				}
			}
		}

		p.caption{
			font-size: 14px;
			line-height: 1.3;
			width: 90%;
			margin: .5em auto 0;
		}
	}

	@if $type == pc{
		max-width: 1280px;
		width: 95%;
		margin: 60px auto 0;
		border-top: 1px solid #ddd;

		.lecMenu{
			display: flex;

			.about{
				flex: 1;
				border-bottom: 1px solid #ddd;
				padding: 40px 40px 40px 0;

				h2{
					font-size: 22px;
					font-weight: 700;
				}

				p{
					font-size: 15px;
					line-height: 1.8;
					margin-top: 20px;
				}
			}

			.price{
				width: 370px;
				background-color: #ddd;
				border-bottom: 1px solid #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				font-weight: 700;
				line-height: 1;

				span{
					font-family: $ff_ja;
					font-size: 16px;
				}
			}
		}

		p.caption{
			font-size: 15px;
			line-height: 1.3;
			margin-top: .5em;
		}
	}
}


.askMe{
	@if $type == sp{
		width: 90%;
		margin: 40px auto 0;
		padding-bottom: 30px;

		h3{
			font-size: 14px;
			font-weight: 500;
			text-align: center;
		}

		.contactBtn{
			width: 100%;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 16px;
			line-height: 1;
			color: #fff;
			font-weight: 700;
			text-decoration: none;
			background-color: #000;
			margin: 15px auto 0;

			&.green{
				background-color: $green;
			}
		}
	}

	@if $type == pc{
		margin-top: 60px;
		padding-bottom: 30px;

		h3{
			font-size: 16px;
			font-weight: 500;
			text-align: center;
		}

		.contactBtn{
			width: 480px;
			height: 70px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 16px;
			line-height: 1;
			color: #fff;
			font-weight: 700;
			text-decoration: none;
			background-color: #fff;
			border: 2px solid #000;
			transition: all .3s;
			margin: 30px auto 0;
			position: relative;
			box-shadow: 4px 6px 0 #d2d2d2;
			z-index: 1;

			&:after{
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				z-index: -1;
				background-color: #000;
				transition: all .3s;
			}

			&:hover{
				color: #000;

				&:after{
					width: 0;
				}

				.btnArrow{
					svg{
						fill: #000;
					}
				}
			}

			.btnArrow{
				display: block;
				width: 18px;
				height: 18px;
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
				fill:#fff;
				transition: all .3s;

				svg{
					fill: #fff;
				}
			}
		}

		&.col2{
			text-align: center;

			.contactBtn{
				display: inline-flex;
				margin: 30px 15px 0;

				&.green{
					//background-color: $green;
					box-shadow: 4px 6px 0 #b4e585;
					border: 2px solid $green;

					&:after{
						background-color: $green;
					}

					&:hover{
						color: $green;

						&:after{
							width: 0;
						}

						.btnArrow{
							svg{
								fill: $green;
							}
						}
					}
				}
			}
		}
	}
}




#year{
	@if $type == sp{
		display: flex;
		justify-content: space-between;
		list-style: none;
		margin-top: 30px;
		flex-wrap: wrap;

		li{
			width: calc(50% - 2px);
			margin-top: 4px;

			a{
				display: block;
				line-height: 1;
				color: #fff;
				background-color: #000;
				padding: 8px 40px 8px 15px;
				position: relative;
				text-decoration: none;
				transition: all .3s;

				&:after{
					content: "";
					display: block;
					width: 5px;
					height: 5px;
					transform: rotate(45deg);
					border-bottom: 2px solid #fff;
					border-right: 2px solid #fff;
					position: absolute;
					right: 10px;
					top: 12px;
				}
			}
		}
	}

	@if $type == pc{
		display: flex;
		justify-content: center;
		list-style: none;
		margin-top: 40px;

		li{
			margin: 0 3px;

			a{
				display: block;
				line-height: 1;
				color: #fff;
				background-color: #000;
				padding: 12px 40px;
				position: relative;
				text-decoration: none;
				transition: all .3s;
				width: 160px;
				height: 40px;
				text-align: center;

				&:after{
					content: "";
					display: block;
					width: 4px;
					height: 4px;
					transform: rotate(45deg);
					border-bottom: 1px solid #fff;
					border-right: 1px solid #fff;
					position: absolute;
					right: 10px;
					top: 16px;
				}

				&:hover{
					opacity: .7;
				}
			}
		}
	}
}




/* ---------------------------- SECRET SEMINER -------------------- */

#seminarHeader{
	@if $type == sp{
	}

	@if $type == pc{
		display: flex;
	}

	.img{
		@if $type == sp{

			img{
				width: 100%;
				height: auto;
			}
		}

		@if $type == pc{
			width: 50%;
			background-image: url(../images/club/seminar-top.jpg);
			background-size: cover;
			background-position: right top;
		}
	}

	.info{
		@if $type == sp{
			background-color: #000;
			padding: 15px;

			.title{
				color: #404040;
				font-size: 16px;
				font-weight: 700;
			}

			.month{
				color: #fff;
				font-size: 40px;
			}

			.dress{
				font-size: 16px;
				color: #fff;
			}

			.pdf{
				display: inline-block;
				font-size: 12px;
				color: #fff;
				font-weight: 400;
				margin-top: 30px;
				padding-right: 30px;
				min-height: 22px;

				background-image: url(../images/member/icon-pdf-white.png);
				background-repeat: no-repeat;
				background-position: right 50%;
			}
		}

		@if $type == pc{
			width: 50%;
			background-color: #000;
			padding: 50px 0 50px 80px;

			.title{
				color: #404040;
				font-size: 18px;
				font-weight: 700;
			}

			.month{
				color: #fff;
				font-size: 70px;
			}

			.dress{
				font-size: 18px;
				color: #fff;
			}

			.pdf{
				display: inline-block;
				font-size: 16px;
				color: #fff;
				font-weight: 400;
				margin-top: 40px;
				padding-right: 30px;

				background-image: url(../images/member/icon-pdf-white.png);
				background-repeat: no-repeat;
				background-position: right 50%;
			}
		}
	}
}


#schedule{
	background-color: #e5e5e5;

	@if $type == sp{
		padding: 20px 0;

		#scheduleTitle{
			font-size: 22px;
			width: 90%;
			margin: 0 auto;
			position: relative;

			&:after{
				content: "";
				display: block;
				width: 7px;
				height: 7px;
				transform: rotate(45deg);
				border-right: 2px solid #000;
				border-bottom: 2px solid #000;
				position: absolute;
				right: 0;
				top: 35%;
				transition: all .4s;
			}

			&.isOpen{
				&:after{
					transform: rotate(225deg);
				}
			}
		}

		ul{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 90%;
			margin: 0 auto;
			list-style: none;
			padding: 10px 0;
			display: none;

			&:after{
				content: "";
				display: block;
				width: 1px;
				height: 0;
				overflow: hidden;
				clear: both;
			}

			li{
				width: 45%;
				display: flex;
				position: relative;
				margin-top: 15px;
				float: left;


				&:not(:last-of-type){
					&:nth-of-type(odd){

						&:after{
							content: "/";
							display: block;
							width: 10%;
							height: 40px;
							line-height: 40px;
							text-align: center;
							color: #fff;
							position: absolute;
							top: 0;
							right: -20px;
							font-size: 30px;
							font-family: $ff_ja;
						}
					}
				}

				&:nth-of-type(even){
					float: right;
				}


				.day{
					width: 40px;
					height: 40px;
					background-color: #fff;
					font-size: 22px;
					line-height: 40px;
					text-align: center;
				}

				.place{
					background-image: url(../images/club/pointer.png);
					background-repeat: no-repeat;
					background-position: 0 50%;
					font-size: 18px;
					padding-left: 30px;
					height: 40px;
					line-height: 40px;
					margin-left: 15px;
					text-decoration: none;
					color: #000;
				}
			}
		}
	}

	@if $type == pc{
		#scheduleTitle{
			display: none;
		}
		ul{
			display: flex;
			justify-content: center;
			max-width: 1280px;
			width: 90%;
			margin: 0 auto;
			list-style: none;
			padding: 40px 0;

			li{
				display: flex;
				margin-right: 50px;
				position: relative;

				&:not(:last-of-type){

					&:after{
						content: "/";
						display: block;
						width: 50px;
						height: 40px;
						line-height: 40px;
						text-align: center;
						color: #fff;
						position: absolute;
						top: 0;
						right: -50px;
						font-size: 30px;
						font-family: $ff_ja;
					}
				}

				.day{
					width: 40px;
					height: 40px;
					background-color: #fff;
					font-size: 22px;
					line-height: 40px;
					text-align: center;
				}

				.place{
					background-image: url(../images/club/pointer.png);
					background-repeat: no-repeat;
					background-position: 0 50%;
					font-size: 16px;
					padding-left: 34px;
					height: 40px;
					line-height: 40px;
					margin-left: 18px;
					text-decoration: none;
					color: #000;
					transition: all .3s;

					&:hover{
						opacity: .7;
					}
				}
			}
		}
	}
}


#seminarArchive{
	.inner{
		@if $type == sp{
			width: 90%;
			margin: 40px auto 0;
			padding-bottom: 30px;

			h2{
				font-weight: 700;
				font-size: 22px;
				text-align: center;

				span{
					display: block;
					font-size: 14px;
				}
			}


			.archiveItem{
				margin-top: 80px;
				margin-bottom: 35px;
				border-bottom: 2px solid #e5e5e5;
				padding-bottom: 35px;

				.poster{
					width: 100%;
					position: relative;

					img{
						width: 100%;
						height: auto;
					}

					.date{
						position: absolute;
						background-color: $green;
						top: -25px;
						left: 50%;
						transform: translateX(-50%);
						height: 50px;
						line-height: 50px;
						width: 225px;

						.tag{
							display: inline-block;
							color: #fff;
							font-size: 16px;
							transform: rotate(-90deg);
							width: 50px;
							text-align: center;
							height: 16px;
							line-height: 1;
							position: absolute;
							top: 0;
							left: -45px;
							transform-origin: right top;
						}

						.mon{
							display: block;
							font-size: 36px;
							color: #fff;
							height: 50px;
							line-height: 50px;
							text-align: center;
						}
					}

					a{
						color: $green;
						font-size: 13px;
						padding-top: 5px;
					}
				}

				.infoInner{
					display: none;
				}

				.info{

					h3{
						font-size: 24px;
						color: $green;
						font-weight: 400;
						border: 1px solid $green;
						padding: 5px 10px;
						position: relative;

						&:before{
							content: "";
							display: block;
							width: 2px;
							height: 20px;
							background-color: $green;
							position: absolute;
							top: 50%;
							right: 15px;
							transform: translateY(-50%);
							transition: all .4s;
						}

						&:after{
							content: "";
							display: block;
							width: 20px;
							height: 2px;
							background-color: $green;
							position: absolute;
							top: 50%;
							right: 6px;
							transform: translateY(-50%);
							transition: all .4s;
						}

						&.isOpen{
							&:before{
								transform: translateY(-50%) rotate(270deg);
							}
							&:after{
								transform: translateY(-50%) rotate(-270deg);
								opacity: 0;
							}
						}
					}

					.read{
						font-size: 16px;
						font-weight: 700;
						margin-top: 30px;
					}

					h4{
						font-size: 16px;
						margin-top: 20px;
						color: #aaa;
						font-weight: 400;
					}

					.sche{
						display: table;
						list-style: none;
						margin-top: 20px;

						li{
							display: table-row;
							font-size: 12px;

							span{
								display: table-cell;

								&:nth-of-type(1){
									padding-right: 10px;
								}
							}
						}
					}

					.dressCode{
						font-size: 14px;
						margin-top: 20px;
					}
				}
			}
		}

		@if $type == pc{
			max-width: 1280px;
			width: 95%;
			margin: 60px auto 0;
			padding-bottom: 50px;

			h2{
				font-weight: 700;
				font-size: 36px;
				text-align: center;

				span{
					display: block;
					font-size: 16px;
				}
			}


			.archiveItem{
				display: flex;
				margin-top: 80px;
				margin-bottom: 35px;
				border-bottom: 2px solid #e5e5e5;
				padding-bottom: 35px;

				.poster{
					max-width: 624px;
					width: 50%;
					position: relative;
					text-align: center;

					img{
						width: 100%;
						height: auto;
					}

					.date{
						position: absolute;
						background-color: $green;
						top: -25px;
						left: 50%;
						transform: translateX(-50%);
						height: 50px;
						line-height: 50px;
						width: 225px;

						.tag{
							display: inline-block;
							color: #fff;
							font-size: 16px;
							transform: rotate(-90deg);
							width: 50px;
							text-align: center;
							height: 16px;
							line-height: 1;
							position: absolute;
							top: 0;
							left: -45px;
							transform-origin: right top;
						}

						.mon{
							display: block;
							font-size: 36px;
							color: #fff;
							height: 50px;
							line-height: 50px;
							text-align: center;
						}
					}

					a{
						color: $green;
						font-size: 14px;
						padding-top: 10px;
						display: block;
					}
				}

				.info{
					flex: 1;
					padding-left: 100px;

					h3{
						font-size: 34px;
						color: $green;
						font-weight: 400;
					}

					.read{
						font-size: 16px;
						font-weight: 700;
						margin-top: 30px;
					}

					h4{
						font-size: 16px;
						margin-top: 20px;
						color: #aaa;
						font-weight: 400;
					}

					.sche{
						display: table;
						list-style: none;
						margin-top: 20px;

						li{
							display: table-row;
							font-size: 14px;

							span{
								display: table-cell;

								&:nth-of-type(1){
									padding-right: 20px;
								}
							}
						}
					}

					.dressCode{
						font-size: 14px;
						margin-top: 20px;
					}
				}
			}
		}
	}
}




/* ------------------- MEDIA ------------------- */

#media{
	@if $type == sp{
		width: 90%;
		margin: 0 auto;
	}
}

#mediaArchve{
	@if $type == sp{
		margin: 40px auto 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-bottom: 30px;

		.mediaItem{
			width: 46%;
			position: relative;
			margin-top: 20px;


			&.adjust{
				height: 0;
				overflow: hidden;
			}

			.img{
				max-width: 80%;

				img{
					width: 100%;
					height: auto;
				}
			}

			.info{
				background-color: rgba(#fff, .8);
				width: 90%;
				padding: 10px;
				border-right: 2px solid $green;
				border-bottom: 2px solid $green;
				margin-top: -50px;
				margin-left: 10%;
				position: relative;
				z-index: 10;

				.date{
					font-size: 18px;
					line-height: 1;
					color: $green;
					text-align: right;
				}

				p{
					font-size: 14px;
					line-height: 1.8;
					margin-top: 10px;
				}
			}
		}
	}

	@if $type == pc{
		max-width: 1280px;
		width: 95%;
		margin: 0 auto 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding-bottom: 60px;

		.mediaItem{
			width: 30%;
			position: relative;
			margin-top: 100px;

			&.adjust{
				height: 0;
				overflow: hidden;
			}

			.info{
				position: absolute;
				background-color: rgba(#fff, .8);
				width: 300px;
				padding: 20px;
				top: 100px;
				right: 0;
				border-right: 2px solid $green;
				border-bottom: 2px solid $green;

				.date{
					font-size: 24px;
					line-height: 1;
					color: $green;
					text-align: right;
				}

				p{
					font-size: 16px;
					line-height: 1.8;
					font-weight: 700;
					margin-top: 20px;
				}
			}
			.img{
				img{
					border: 1px solid #eee;
				}
			}
		}
	}
}



/* ---------------------- HOW TO USE ------------------- */

#memberBody.howTo{
	@if $type == sp{
		#intro{
			h1{
				font-size: 22px;
				font-weight: 700;
				text-align: center;
			}
		}

		#point9{
			font-feature-settings : "palt" 1;
			margin-top: 20px;

			.point9Item{
				border: 3px solid $green;
				border-width: 0 3px 3px 3px;
				background-image: url(../images/howto/check-bg.png);
				margin-top: 20px;

				.itemHead{
					background-color: $green;
					width: 100%;
					position: relative;
					background-image: url(../images/howto/star-bg.png);
					background-position: right bottom;
					background-repeat: no-repeat;
					color: #fff;

					.num{
						padding-left: 10px;

						.numInner{
							font-size: 24px;
							white-space: nowrap;

							span{
								display: inline-block;
								font-size: 18px;
								margin-right: 10px;
							}
						}
					}

					h2{
						font-size: 16px;
						border-bottom: 2px solid #fff;
						padding: 10px;
						line-height: 1.3;
					}

					h3{
						font-size: 18px;
						padding: 10px;
						line-height: 1.3;
					}
				}

				.itemCont{
					padding: 20px;
					font-size: 15px;

					ul, ol{
						padding-left: 1.5em;
						line-height: 2;

						ul{
							list-style: disc;
						}
					}
				}
			}
		}
	}

	@if $type == pc{

		#intro{
			h1{
				font-size: 32px;
				font-weight: 700;
				text-align: center;
				margin-top: 60px;
			}
		}

		#point9{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			font-feature-settings : "palt" 1;
			margin-top: 20px;

			.point9Item{
				width: 32%;
				border: 3px solid $green;
				border-width: 0 3px 3px 3px;
				background-image: url(../images/howto/check-bg.png);
				margin-top: 40px;

				.itemHead{
					background-color: $green;
					width: 100%;
					min-height: 190px;
					position: relative;
					background-image: url(../images/howto/star-bg.png);
					background-position: right bottom;
					background-repeat: no-repeat;
					color: #fff;

					display: grid;
					grid-template-rows: 25% 1fr;
					grid-template-columns: 63px 1fr;

					.num{
						height: 190px;
						position: relative;

						grid-row: 1 / 3;
						grid-column: 1 / 2;

						.numInner{
							transform: rotate(-90deg);
							transform-origin: left top;
							font-size: 36px;
							white-space: nowrap;
							position: absolute;
							width: 190px;
							top: 190px;
							text-align: center;

							span{
								display: inline-block;
								font-size: 24px;
								margin-right: 10px;
							}
						}
					}

					h2{
						font-size: 16px;
						border-bottom: 3px solid #fff;
						border-left: 3px solid #fff;
						padding: 20px;
						display: flex;
						align-items: center;
						line-height: 1.3;

						grid-row: 1 / 2;
						grid-column: 2 / 3;
					}

					h3{
						border-left: 3px solid #fff;
						font-size: 20px;
						padding: 0 20px;
						display: flex;
						align-items: center;
						line-height: 1.3;

						grid-row: 2 / 3;
						grid-column: 2 / 3;
					}
				}

				.itemCont{
					padding: 20px;
					font-size: 15px;

					ul, ol{
						padding-left: 1.5em;
						line-height: 2;

						ul{
							list-style: disc;
						}
					}
				}
			}
		}
	}
}




/* --------------------- BOOKS -------------------- */

.bookCat{
	@if $type == sp{
		font-size: 22px;
		text-align: center;
		margin-top: 60px;
	}

	@if $type == pc{
		font-size: 34px;
		text-align: center;
		margin-top: 60px;
	}
}



.bookList{
	@if $type == sp{
		width: 90%;
		margin: 0 auto;
		padding-bottom: 30px;

		.bookItem{
			margin-top: 30px;

			.num{
				font-size: 14px;
				line-height: 1;
				color: #fff;
				background-color: $green;
				padding: 5px 10px;

				span{
					font-size: 18px;
				}
			}

			.about{

				h3{
					font-size: 20px;
					color: $green;
				}

				.author{
					font-size: 16px;
					font-weight: 700;
					margin-top: 10px;
				}

				.info{
					font-size: 14px;
					line-height: 1.8;
					margin-top: 15px;
				}
			}

			.bookImg{
				width: 160px;
				order: 1;
				margin: 15px auto 0;

				img{
					border: 1px solid #000;
					width: 100%;
					height: auto;
				}
			}

			.buyBtn{
				width: 100%;
				order: 2;
				text-align: center;
				padding: 10px 0;

				a{
					display: inline-block;
					width: 160px;
					height: 40px;
					background-color: #000;
					color: #fff;
					font-weight: 700;
					text-align: center;
					line-height: 40px;
					text-decoration: none;
					position: relative;
					transition: all .3s;

					&:after{
						content: "";
						display: block;
						width: 6px;
						height: 6px;
						transform: rotate(45deg);
						border-top: 2px solid #fff;
						border-right: 2px solid #fff;
						position: absolute;
						top: 16px;
						right: 15px;
					}
				}
			}
		}
	}

	@if $type == pc{
		max-width: 1280px;
		width: 95%;
		margin: 0 auto 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: 60px;

		.bookItem{
			width: 48%;
			position: relative;
			padding-left: 70px;
			display: flex;
			flex-wrap: wrap;
			margin-top: 80px;
			position: relative;
			padding-bottom: 80px;

			&:before{
				width: 2px;
				height: 300px;
				content: "";
				display: block;
				background-color: $green;
				position: absolute;
				top: 0;
				left: 0;
			}

			&:after{
				width: 300px;
				height: 2px;
				content: "";
				display: block;
				background-color: $green;
				position: absolute;
				bottom: 0;
				left: 40px;
			}

			.num{
				font-size: 34px;
				line-height: 1;
				color: #fff;
				background-color: $green;
				position: absolute;
				width: 185px;
				height: 50px;
				transform: rotate(-90deg);
				transform-origin: top left;
				top: 185px;
				left: 0;
				line-height: 50px;
				text-align: center;

				span{
					font-size: 36px;
				}
			}

			.about{
				flex: 1;
				order: 0;
				padding-right: 30px;

				h3{
					font-size: 24px;
					color: $green;
				}

				.author{
					font-size: 16px;
					font-weight: 700;
					margin-top: 15px;
				}

				.info{
					font-size: 14px;
					line-height: 1.8;
					margin-top: 20px;
				}
			}

			.bookImg{
				width: 160px;
				order: 1;

				img{
					border: 1px solid #000;
					width: 100%;
					height: auto;
				}
			}

			.buyBtn{
				width: 100%;
				order: 2;
				text-align: right;
				padding: 0 0 20px;
				position: absolute;
				height: 60px;
				left: 0;
				bottom: 0;

				a{
					display: inline-block;
					width: 160px;
					height: 40px;
					background-color: #000;
					color: #fff;
					font-weight: 700;
					text-align: center;
					line-height: 40px;
					text-decoration: none;
					position: relative;
					transition: all .3s;
					border: 2px solid #000;
					background-color: #fff;
					z-index: 1;
					box-shadow: 2px 3px 0 #d2d2d2;

					&:before{
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						right: 0;
						z-index: -1;
						background-color: #000;
						transition: all .3s;
					}

					&:after{
						content: "";
						display: block;
						width: 6px;
						height: 6px;
						transform: rotate(45deg);
						border-top: 2px solid #fff;
						border-right: 2px solid #fff;
						position: absolute;
						top: 16px;
						right: 15px;
					}

					&:hover{
						color: #000;

						&:before{
							width: 0;
						}

						&:after{
							animation: buyArw 2s infinite;
							border-color: #000;
						}

						@keyframes buyArw{
							50%{
								right: 5px;
							}
						}
					}
				}
			}
		}
	}
}


/* --------------- sns ---------------------- */

#sns{
	@if $type == sp{
		width: 90%;
		margin: 0 auto 0;

		#snsList{
			list-style: none;

			.snsLink{
				width: 100%;
				//border: 1px solid #000;
				display: flex;
				margin-top: 40px;
				padding-bottom: 15px;
				border-bottom: 1px solid #000;

				.img{
					max-width: 298px;
					width: 40%;

					img{
						width: 100%;
						height: auto;
						border: 1px solid #f2f2f2;
					}
				}

				.txt{
					flex: 1;
					margin-left: 10px;
//					border-left: 1px solid #000;
					padding: 0 15px;
					position: relative;
					min-height: 100%;
					height: 100%;

					p{
						font-size: 16px;
						font-weight: 700;
						text-indent: -1.1em;
						padding-left: 2em;

						.num{
							font-size: 20px;
							font-weight: 400;
							color: $green;
							display: inline-block;
							padding-right: .1em;
						}
					}

					.icons{
						display: flex;
						list-style: none;
						margin-top: 15px;

						li{
							margin-right: 10px;
							width: 30px;
							height: 30px;

							a{
								display: block;
								transition: all .3s;

								img{
									width: 100%;
									height: auto;
								}
							}
						}
					}
				}
			}
		}
	}

	@if $type == pc{
		max-width: 1200px;
		width: 95%;
		margin: 60px auto 0;

		#snsList{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			list-style: none;

			.snsLink{
				width: 48%;
				//border: 1px solid #000;
				display: flex;
				margin-top: 60px;

				.img{
					width: 298px;
					border: 1px solid #f2f2f2;
				}

				.txt{
					flex: 1;
					border-left: 1px solid #f2f2f2;
					padding: 30px 40px;
					position: relative;

					p{
						font-size: 18px;
						font-weight: 700;
						text-indent: -1.1em;
						padding-left: 1.3em;

						.num{
							font-size: 24px;
							font-weight: 400;
							color: $green;
							display: inline-block;
							padding-right: .1em;
						}
					}

					.icons{
						display: flex;
						list-style: none;
						position: absolute;
						position: absolute;
						left: 30px;
						bottom: 40px;

						li{
							margin-right: 10px;

							a{
								display: block;
								transition: all .3s;

								&:hover{
									opacity: .7;
								}
							}
						}
					}
				}
			}
		}
	}
}



/* ----------------- contact ------------------ */

.contactBtn{
	@if $type == sp{
		width: 90%;
		margin: 10px auto 0;
		padding: 20px 0 20px;
		background-color: #e5e5e5;

		a{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 90%;
			margin: 0 auto 0;
			background-color: $green;
			color: #fff;
			font-size: 16px;
			font-weight: 700;
			text-decoration: none;
			box-shadow: 4px 6px 0 #b4e585;
			position: relative;
			transition: all .3s;
			padding: 20px;

			&:not(:nth-of-type(1)){
				margin-top: 20px;
			}

			&:after{
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				background-image: url(../images/second/btn-arrow.png);
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
			}

			&:hover{
				opacity: .7;
			}
		}
	}
	@if $type == pc{
		max-width: 1200px;
		width: 95%;
		margin: 40px auto 0;
		display: flex;
		justify-content: center;
		background-color: #e5e5e5;
		padding: 40px 0 35px;

		a{
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 1%;
			max-width: 48%;
			width: 485px;
			min-height: 70px;
			background-color: #fff;
			color: #fff;
			font-size: 18px;
			font-weight: 700;
			text-decoration: none;
			box-shadow: 4px 6px 0 #b4e585;
			border: 2px solid #71bd29;
			position: relative;
			transition: all .3s;
			z-index: 1;
			overflow: hidden;

			&:after{/*
				content: "";
				display: block;
				width: 15px;
				height: 15px;
				background-image: url(../images/second/btn-arrow.png);
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
				*/
				content: "";
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				background-color: $green;
				z-index: -1;
				transition: all .3s;
			}

			&:hover{
				color: $green;

				&:after{
					right: -100%;
				}
			}
		}
	}
}



/* ---------------- charity ----------------- */

#charityArchive{

	.slick-dots{
		bottom: 15px;
		text-align: right;
		padding-right: 15px;

		li{
			width: 9px;
			height: 9px;

			&.slick-active{
				button{
					background-color: $green;
				}
			}

			button{
				width: 9px;
				height: 9px;
				background-color: #fff;
				border-radius: 50%;
			}
		}
	}


	@if $type == sp{
		width: 90%;
		margin: 0 auto;
		padding-top: 20px;

		.charityList{
			width: 100%;
			margin-top: 40px;
			padding-bottom: 30px;
			border-bottom: 1px solid #e5e5e5;

			.img{
				width: 100%;

				img{
					width: 100%;
					height: auto;
				}
			}

			.txt{

				h2{
					font-size: 30px;
					font-weight: 700;
				}

				.info{
					font-size: 16px;
					line-height: 1.8;
					margin-top: 40px;
				}
			}

			.link{
				font-size: 15px;
				color: #a4a4a4;
				margin-top: 40px;
				display: inline-block;
				position: relative;
				text-decoration: none;

				&:hover{
					text-decoration: underline;
				}

				.blank{
					width: 15px;
					height: 15px;
					display: block;
					position: absolute;
					top: 0;
					right: -20px;

					svg{
						width: auto;
						height: 13px;
						fill: #a4a4a4;
					}
				}
			}

			.movie{
				margin-top: 40px;
				background-color: #e5e5e5;
				padding: 15px;
				display: block;

				.movInfo{
					padding-right: 20px;
					margin-top: 15px;

					&:first-child{
						margin-top: 0;
					}

					.title{
						background-image: url(../images/common/icon-movie.png);
						background-repeat: no-repeat;
						background-position: 0 5px;
						padding-left: 30px;
						font-size: 15px;
					}

					.url{
						color: #a4a4a4;
						font-size: 15px;
						text-decoration: none;

						&:hover{
							text-decoration: underline;
						}
					}
				}

				.tn{
					width: 100px;

					img{
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}

	@if $type == pc{
		max-width: 1200px;
		width: 95%;
		margin: 0 auto;
		padding-top: 20px;

		.charityList{
			width: 100%;
			display: flex;
			margin-top: 70px;
			padding-bottom: 60px;
			border-bottom: 1px solid #e5e5e5;

			.img{
				width: 640px;

				.slick-dots{
					top: 390px;
				}
			}

			.txt{
				flex: 1;
				padding-left: 110px;

				h2{
					font-size: 30px;
					font-weight: 700;
				}

				.info{
					font-size: 16px;
					line-height: 1.8;
					margin-top: 40px;
				}
			}

			.link{
				font-size: 15px;
				color: #a4a4a4;
				margin-top: 40px;
				display: inline-block;
				position: relative;
				text-decoration: none;

				&:hover{
					text-decoration: underline;
				}

				.blank{
					width: 15px;
					height: 15px;
					display: block;
					position: absolute;
					top: 0;
					right: -20px;

					svg{
						width: auto;
						height: 13px;
						fill: #a4a4a4;
					}
				}
			}

			.movie{
				margin-top: 40px;
				background-color: #e5e5e5;
				padding: 35px;
				display: block;

				.movInfo{
					padding-right: 20px;
					margin-top: 20px;

					&:first-child{
						margin-top: 0;
					}

					.title{
						background-image: url(../images/common/icon-movie.png);
						background-repeat: no-repeat;
						background-position: 0 5px;
						padding-left: 30px;
						font-size: 15px;
					}

					.url{
						color: #a4a4a4;
						font-size: 15px;
						text-decoration: none;

						&:hover{
							text-decoration: underline;
						}
					}
				}

				.tn{
					width: 140px;
				}
			}
		}
	}
}




/* --------------- base layout -------------- */

main.aboutIndex{
	@if $type == pc{
		background-image: url(../images/second/baku.png);
		background-repeat: no-repeat;
		background-position: 95% 632px;
	}
}

.txtCont{
	@if $type == sp{
		width: 90%;
		margin: 40px auto 0;
		padding-bottom: 30px;

		h2{
			font-size: 22px;
			font-weight: 400;
			text-align: center;

			.ja{
				font-size: 15px;
				font-weight: 700;
				display: block;
				margin: 0 auto;
			}
		}

		h3{
			font-size: 20px;
			font-weight: 700;
			text-align: center;
			margin-top: 30px;
		}

		p{
			font-size: 15px;
			line-height: 1.8;
			margin-top: 40px;

			&.tac{
			//	text-align: center;
			}
		}

		.tableLayout{
			border-bottom: 2px solid #000;
			margin-top: 40px;

			dl{
				border-top: 2px solid #000;
				padding: 15px 0;
				font-size: 15px;
				line-height: 1.8;

				dt{
					font-weight: 700;
				}

				dd{

					&.flex{

						&>span{
							display: block;

							&:not(:nth-of-type(1)){
								margin-top: 1em;
							}

							&.map{
								width: 100%;

								img{
									width: 100%;
									height: auto;
								}
							}
						}
					}
				}
			}
		}

		a.btn{
			background-color: #000;
			display: inline-block;
			color: #fff;
			text-decoration: none;
			line-height: 1;
			padding: 5px 35px 5px 15px;
			position: relative;
			margin-top: 10px;

			&:after{
				display: block;
				content: "";
				width: 15px;
				height: 15px;
				background-image: url(../images/second/btn-arrow.png);
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
			}
		}
	}

	@if $type == pc{
		max-width: 1200px;
		width: 95%;
		margin: 60px auto 0;
		padding-bottom: 30px;

		&.aboutIndex{
		}

		h2{
			font-size: 34px;
			font-weight: 400;
			text-align: center;

			.ja{
				font-size: 15px;
				font-weight: 700;
				display: block;
				margin: 0 auto;
			}
		}

		h3{
			font-size: 30px;
			font-weight: 700;
			text-align: center;
			margin-top: 40px;
		}

		p{
			font-size: 15px;
			line-height: 1.8;
			margin-top: 40px;

			&.tac{
				text-align: center;
			}
		}

		.tableLayout{
			border-bottom: 2px solid #000;
			margin-top: 40px;

			dl{
				display: flex;
				border-top: 2px solid #000;
				padding: 30px 0;
				font-size: 15px;
				line-height: 1.8;

				dt{
					width: 30%;
					padding-left: 40px;
				}

				dd{
					flex: 1;
					padding-right: 40px;

					&.flex{
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;

						&>span{
							width: 48%;

							&.map{
								width: 100%;
								margin-top: 50px;

								img{
									max-width: 100%;
									width: auto;
									height: auto;
								}
							}
						}
					}
				}
			}
		}

		a.btn{
			background-color: #000;
			display: inline-block;
			color: #fff;
			text-decoration: none;
			line-height: 1;
			padding: 5px 35px 5px 15px;
			position: relative;
			margin-top: 10px;

			&:after{
				display: block;
				content: "";
				width: 15px;
				height: 15px;
				background-image: url(../images/second/btn-arrow.png);
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
			}
		}
	}
}
.basic_list{
	list-style:none;
	@if $type == sp{
		width: 90%;
		margin: .5em auto 0;
	}
	@if $type == pc{
		margin-top: 20px;
	}

	li{
		@if $type == sp{
			padding-left: 20px;
			position: relative;

			&::before{
				content: '';
				position: absolute;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				top: 7px;
				left: 3px;
				background-color: #71bd29;
			}
		}
		@if $type == pc{
			padding-left: 20px;
			position: relative;

			&::before{
				content: '';
				position: absolute;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				top: 10px;
				left: 3px;
				background-color: #71bd29;
			}
		}

	}
}



@import "team";
@import "member";
@import "club";
@import "mailForm";


.sample{
	@if $type == sp{
	}

	@if $type == pc{
	}
}
