.team{
	@if $type == sp{
		.inner{
			width: 90%;
			margin: 30px auto 0;

			h2{
				grid-row: 1;
				grid-column: 2;
				font-size: 22px;
				line-height: 1;
				background-image: url(../images/ryumurao-team/name-bg-gray.png);
				background-repeat: no-repeat;
				background-position: 90% 0;

				.kana{
					display: block;
					font-size: 18px;
					border-top: 1px solid #000;
					border-bottom: 1px solid #000;
					padding: 10px 0;
					margin-top: 10px;
				}
			}

			.img{
				margin-top: 10px;

				img{
					width: 100%;
					height: auto;
				}
			}

			.info{
				margin-top: 1em;

				p{
					font-size: 15px;
					line-height: 1.8;
				}

				.web{
					text-align: right;
					font-size: 15px;
					font-weight: 700;
					margin-top: 40px;

					a{
						color: #737373;
						font-size: 18px;
					}
				}
			}
		}
	}

	@if $type == pc{
		.inner{
			max-width: 1280px;
			width: 95%;
			margin: 40px auto 0;
			display: grid;
			grid-template-rows: 150px 1fr;
			grid-template-columns: 50% 50%;
			
			h2{
				grid-row: 1;
				grid-column: 2;
				font-size: 32px;
				line-height: 1;
				padding-left: 40px;
				background-image: url(../images/ryumurao-team/name-bg-gray.png);
				background-repeat: no-repeat;
				background-position: 225px 0;

				.kana{
					display: block;
					font-size: 24px;
					border-top: 1px solid #000;
					border-bottom: 1px solid #000;
					padding: 20px 0;
					margin-top: 15px;
				}
			}
			
			.img{
				grid-row: 1 / 3;
				grid-column: 1 / 2;
				
				img{
					width: 100%;
					height: auto;
				}
			}
			
			.info{
				grid-row: 2;
				grid-column: 2;
				padding-left: 40px;

				p{
					font-size: 15px;
					line-height: 1.8;
				}
				
				.web{
					text-align: right;
					font-size: 15px;
					font-weight: 700;
					margin-top: 40px;
					
					a{
						color: #737373;
						font-size: 18px;
					}
				}
			}
		}
	}
}


.team2col{
	background-color: #e5e5e5;
	
	@if $type == sp{
		padding: 0px 0 30px;
		margin-top: 40px;

		.inner{
			width: 90%;
			margin: 0 auto 0;
			padding-top: 1px;

			.member{
				background-color: #fff;
				background-position: right top;
				background-repeat: no-repeat;
				background-size: auto 110px;
				width: 100%;
				padding: 15px;
				margin-top: 30px;

				h2{
					font-size: 22px;
					line-height: 1;
					height: 100px;
					padding-top: 40px;
					background-image: url(../images/ryumurao-team/name-bg-gray.png);
					background-repeat: no-repeat;
					background-position: 90px 0;

					span{
						font-family: $ff_ja;
						font-size: 15px;
						display: block;
						margin-top: 5px;
					}
				}

				p{
					margin-top: 20px;
				}

				&#kentaro{
					background-image: url(../images/ryumurao-team/kentaro.jpg);
				}

				&#miyuki{
					background-image: url(../images/ryumurao-team/miyuki.jpg);
				}
			}
		}
	}

	@if $type == pc{
		padding: 60px 0;
		margin-top: 80px;
		
		.inner{
			max-width: 1280px;
			width: 95%;
			margin: 0 auto 0;
			display: flex;
			justify-content: space-between;
			
			.member{
				background-color: #fff;
				background-size: auto 100%;
				//background-position: right top;
				background-position: calc(100% + 25px) top;
				background-repeat: no-repeat;
				width: 48%;
				padding: 30px;
				
				h2{
					font-size: 22px;
					line-height: 1;
					height: 80px;
					background-image: url(../images/ryumurao-team/name-bg-gray.png);
					background-repeat: no-repeat;
					background-position: 90px 0;
					padding-top: 16px;
					
					span{
						font-family: $ff_ja;
						font-size: 16px;
						display: block;
						margin-top: 15px;
					}
				}
				
				p{
					width: 45%;
					margin-top: 15px;
				}
				
				&#kentaro{
					background-image: url(../images/ryumurao-team/kentaro.jpg);
				}
				
				&#miyuki{
					background-image: url(../images/ryumurao-team/miyuki.jpg);
				}
			}
		}
	}
	@media screen and (max-width:900px) and (min-width:600px){
		padding: 0px 0 30px;
		margin-top: 40px;

		.inner{
			display:block;
			width: 90%;
			margin: 0 auto 0;
			padding-top: 1px;

			.member{
				background-color: #fff;
				background-position: right top;
				background-repeat: no-repeat;
				background-size: auto 110px;
				width: 100%;
				padding: 15px;
				margin-top: 30px;

				h2{
					font-size: 22px;
					line-height: 1;
					height: 100px;
					padding-top: 40px;
					background-image: url(../images/ryumurao-team/name-bg-gray.png);
					background-repeat: no-repeat;
					background-position: 90px 0;

					span{
						font-family: $ff_ja;
						font-size: 15px;
						display: block;
						margin-top: 5px;
					}
				}

				p{
					width: 100%;
					margin-top: 20px;
				}

				&#kentaro{
					background-image: url(../images/ryumurao-team/kentaro.jpg);
				}

				&#miyuki{
					background-image: url(../images/ryumurao-team/miyuki.jpg);
				}
			}
		}
	}
}


#ourTeam{
	@if $type == sp{
		margin-top: 40px;

		.inner{
			width: 90%;
			margin: 0 auto;

			h2{
				font-size: 22px;
				font-weight: 400;
				text-align: center;
			}

			.teamTable{
				margin-top: 20px;
				border-top: 1px solid #ddd;
				border-left: 1px solid #ddd;
				border-right: 1px solid #ddd;
				width: 100%;

				.our{
					border-bottom: 1px solid #ddd;

					a{
						color: #000;
						text-decoration: none;
						display: block;
						padding: 15px;

						.logo{
							width: 134px;
							text-align: center;
							margin: 0 auto;
						}

						.txt{
							margin-top: 15px;

							h3{
								font-size: 16px;
								display: table;
								padding-right: 30px;
								margin: 0 auto;
								background-image: url(../images/member/icon-blank.png);
								background-repeat: no-repeat;
								background-position: right 50%;
							}
							
							p{
								font-size: 14px;
								margin-top: 15px;
								line-height: 1.8;
							}
						}
					}
				}
			}
		}
	}

	@if $type == pc{
		margin-top: 80px;
		
		.inner{
			max-width: 1280px;
			width: 95%;
			margin: 0 auto;
			
			h2{
				font-size: 28px;
				font-weight: 400;
				text-align: center;
			}
			
			.teamTable{
				margin-top: 40px;
				border-top: 1px solid #ddd;
				border-left: 1px solid #ddd;
				display: flex;
				width: 100%;
				
				.our{
					width: 50%;
					border-right: 1px solid #ddd;
					border-bottom: 1px solid #ddd;
					
					a{
						color: #000;
						text-decoration: none;
						display: block;
						display: flex;
						align-items: center;
						padding: 30px;
						position: relative;
						overflow: hidden;
						z-index: 1;
						
						&:after{
							content: "";
							display: block;
							width: 100%;
							height: 100%;
							background-color: $green;
						//	opacity: .3;
							transition: all .3s ease-out;
							position: absolute;
							top: 0;
							left: -100%;
							z-index: -1;
						}
						
						&:hover{
							color: #fff;
							
							.svg{
								fill: #fff;
							}
							
							&:after{
								left: 0;
							}
						}

						.logo{
							width: 134px;
							height: 100px;
							padding-left: 20px;
							
							.svg{
								width: 100px;
								height: 100px;
							}
						}

						.txt{
							flex: 1;
							padding-left: 25px;

							h3{
								font-size: 22px;
								display: inline-block;
								
								.iconBlank{
									width: 17px;
									margin-left: 10px;
									
									.svg{
										width: auto;
										height: 17px;
									}
								}
							}
							
							p{
								font-size: 15px;
							}
						}
					}
				}
			}
		}
	}
}








.sample{
	@if $type == sp{
	}

	@if $type == pc{
	}
}
