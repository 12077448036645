#memberHeader{

	@if $type == sp{
		padding-top: 70px;
	}

	@if $type == pc{
		padding-top: 205px;
	}
	
	#pageName{
		background-color: $green;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		color: #fff;
		font-weight: 700;
		line-height: 1;
	}
	
	@if $type == sp{
		#pageName{
			height: 70px;
			font-size: 22px;
			padding-left: calc((20px + 43px) / 2);

			&:after{
				content: "";
				display: block;
				width: 43px;
				height: 44px;
				background-image: url(../images/member/title-img.png);
				background-size: 43px auto;
				margin-left: 20px;
			}
		}
	}

	@if $type == pc{
		#pageName{
			height: 155px;
			font-size: 44px;
			padding-left: calc((40px + 85px) / 2);
			
			&:after{
				content: "";
				display: block;
				width: 85px;
				height: 88px;
				background-image: url(../images/member/title-img.png);
				margin-left: 40px;
			}
		}
	}
}

#memberBody{
	@if $type == sp{
		width: 90%;
		margin: 0 auto;
	}

	@if $type == pc{
		max-width: 1280px;
		width: 95%;
		margin: 0 auto;
	}
		
	@if $type == sp{
		#intro{
			padding-top: 30px;

			h2{
				font-size: 22px;
				text-align: center;
				font-weight: 400;

				span{
					display: block;
					text-align: center;
					font-size: 15px;
				}
			}

			p{
				font-size: 15px;
				line-height: 1.8;
				margin: 20px auto 0;
				display: table;
			}
		}
		.toMenu{
			    display: block;
				margin: 20px auto;
				width: 100%;
				text-align: center;
			
			a{
				display: block;
				text-decoration: none;
				cursor: pointer;
				padding: 10px;
				transition: all .3s;
				color: #000;
				font-weight: 700;
				border: 2px solid #71bd29;
				position: relative;
				
				&::after{
					content: "";
					display: block;
					width: 18px;
					height: 18px;
					background-image: url(../images/member/arrow-black.png);
					position: absolute;
					right: 20px;
					top: 50%;
					-webkit-transform: translateY(-50%);
					transform: translateY(-50%);
				}
				
			}
		}
	}

	@if $type == pc{
		#intro{
			padding-top: 50px;
			
			h2{
				font-size: 34px;
				text-align: center;
				font-weight: 400;
				
				span{
					display: block;
					text-align: center;
					font-size: 15px;
				}
			}
			
			p{
				font-size: 15px;
				line-height: 1.8;
				margin: 30px auto 0;
				display: table;
			}
		}
		
		.toMenu{
			    display: block;
				margin: 20px auto;
				width: 480px;
				text-align: center;
			
			a{
				display: block;
				text-decoration: none;
				cursor: pointer;
				padding: 20px;
				transition: all .3s;
				color: #fff;
				border: 2px solid $green;
				position: relative;
				font-weight: 700;
				font-size: 18px;
				overflow: hidden;
				z-index: 1;
				box-shadow: 4px 6px 0 #b4e585;
				
				&::after{
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					background-color: $green;
					position: absolute;
					right: 0;
					top: 0;
					transition: all .3s;
					z-index: -1;
				}

				&:hover{
					color: $green;
					
					&:after{
						right: -100%;
					}
				}
			}
		}
	}
	
	
	#loginForm{
		@if $type == sp{
			width: 100%;
			background-color: #e5e5e5;
			padding: 40px 0 0;
			margin-top: 60px;

			#pass{

				h2{
					color: $green;
					font-size: 22px;
					font-weight: 700;
					text-align: center;

					span{
						font-size: 14px;
						font-weight: 700;
						padding-left: 30px;
						color: #000;
						display: block;
					}
				}

				form{
					display: block;
					margin: 20px auto 0;

					.inputTxt{
						width: 90%;
						margin: 0 auto;
						padding-bottom: 20px;
					}

					input[type = password]{
						width: 100%;
						height: 50px;
						outline: none;
						border: 2px solid #d0d0d0;
					}
				}

				#login{
					background-color: #fff;
					text-align: center;
					padding: 15px 0;

					button{
						width: 100%;
						height: 40px;
						color: #fff;
						background-color: #000;
						border: none;
					}
				}
			}
		}

		@if $type == pc{
			width: 100%;
			background-color: #e5e5e5;
			padding: 40px 0 0;
			margin-top: 60px;
			
			#pass{
				
				h2{
					color: $green;
					font-size: 34px;
					font-weight: 700;
					text-align: center;
					
					span{
						font-size: 14px;
						font-weight: 700;
						padding-left: 30px;
						color: #000;
					}
				}
				
				form{
					display: block;
					margin: 40px auto 0;
					
					.inputTxt{
						max-width: 644px;
						width: 100%;
						margin: 0 auto;
						padding-bottom: 30px;
					}
					
					input[type = password]{
						width: 100%;
						height: 50px;
						outline: none;
						border: 2px solid #d0d0d0;
					}
				}
				
				#login{
					background-color: #fff;
					text-align: center;
					padding: 30px 0;
					
					button{
						width: 485px;
						height: 70px;
						color: #fff;
						background-color: #000;
						border: none;
						cursor: pointer;
						transition: all .3s;
						box-shadow: 4px 6px 0 #d2d2d2;
						font-weight: 700;
						
						&:hover{
							opacity: .7;
						}
					}
				}
			}
		}
	}
	
		
	#seminar{
		@if $type == sp{
			display: flex;
			flex-direction: column;

			.img{
				order: 1;
				width: 50%;
				position: relative;
				overflow: hidden;

				img{
					display: block;
					min-width: 100%;
					min-height: 100%;
					width: auto;
					height: auto;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
			}

			.txt{
				order: 0;
				background-color: #000;
				color: #fff;
				width: 100%;
				padding: 20px;
				line-height: 1.3;

				h2{
					font-size: 26px;
					font-weight: 700;
					line-height: 1;
				}

				.date{
					font-size: 22px;
					font-weight: 700;
					line-height: 1.4;
					margin-top: 10px;
				}

				.conditions{
					font-size: 16px;
					margin-top: 15px;
				}

				.more{
					width: 100%;
					height: 40px;
					background-color: #fff;
					color: #000;
					text-decoration: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					position: relative;
					margin-top: 40px;

					&:after{
						content: "";
						display: block;
						width: 18px;
						height: 18px;
						background-image: url(../images/member/arrow-black.png);
						position: absolute;
						top: 50%;
						right: 15px;
						transform: translateY(-9px);
					}
				}

				.download{

					a{
						font-size: 14px;
						margin-top: 10px;
						display: inline-block;
						color: #fff;
						text-decoration: none;
						position: relative;

						&:after{
							content: "";
							display: inline-block;
							width: 21px;
							height: 22px;
							background-image: url(../images/member/icon-pdf-white.png);
							position: absolute;
						}
					}
				}
			}
		}

		@if $type == pc{
			display: flex;
			
			.img{
				width: 50%;
				position: relative;
				overflow: hidden;
				
				img{
					display: block;
					min-width: 100%;
					min-height: 100%;
					width: auto;
					height: auto;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
			}
			
			.txt{
				background-color: #000;
				color: #fff;
				width: 50%;
				padding: 30px 30px 30px 80px;
				line-height: 1.3;
				
				h2{
					font-size: 18px;
					font-weight: 700;
					line-height: 1;
					color: #404040;
				}
				
				.date{
					font-size: 70px;
					font-weight: 700;
					line-height: 1.4;
					margin-top: 5px;
				}
				
				.conditions{
					font-size: 18px;
					margin-top: 5px;
				}
				
				.more{
					width: 247px;
					height: 60px;
					background-color: #fff;
					color: #000;
					text-decoration: none;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					position: relative;
					margin-top: 80px;
					
					&:after{
						content: "";
						display: block;
						width: 18px;
						height: 18px;
						background-image: url(../images/member/arrow-black.png);
						position: absolute;
						top: 21px;
						right: 15px;
					}
				}
				
				.download{
					
					a{
						font-size: 16px;
						margin-top: 20px;
						display: inline-block;
						color: #fff;
						text-decoration: none;
						position: relative;

						&:after{
							content: "";
							display: inline-block;
							width: 21px;
							height: 22px;
							background-image: url(../images/member/icon-pdf-white.png);
							position: absolute;
							top: -5px;
							right: -25px;
						}
					}
				}
			}
		}
	}
		
	#merit{
		@if $type == sp{
			width: 100%;
			margin: 10px auto 0;

			a{
				border: 2px solid $green;
				display: flex;
				align-items: center;
				width: 100%;
				font-size: 16px;
				line-height: 1.8;
				font-weight: 700;
				color: #000;
				text-decoration: none;
				padding: 10px;
				position: relative;
				margin-top: 20px;

				&:after{
					content: "";
					display: block;
					width: 18px;
					height: 18px;
					background-image: url(../images/member/arrow-black.png);
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		@if $type == pc{
			display: flex;
			justify-content: space-between;
			max-width: 1280px;
			width: 100%;
			margin: 50px auto 0;
			
			a{
				border: 2px solid $green;
				display: flex;
				align-items: center;
				justify-content: center;
				max-width: 410px;
				width: 48%;
				min-height: 106px;
				font-size: 18px;
				line-height: 1.8;
				font-weight: 700;
				color: #fff;
				text-decoration: none;
				padding: 20px;
				position: relative;
				transition: all .3s;
				margin-right: 25px;
				z-index: 1;
				overflow: hidden;
				box-shadow: 4px 6px 0 #b4e585;
				
				&:last-child{
					margin-right: 0;
				}

				&:after{
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					background-color: $green;
					transition: all .3s;
					z-index: -1;
				}
				
				&:hover{
					color: $green;
					
					&:after{
						right: -100%;
					}
				}
			}
		}
	}
	
	#membersInfo{
		@if $type == sp{
			margin-top: 40px;
			padding-top: 30px;
			border-top: 2px solid #e5e5e5;

			h2{
				font-size: 22px;
				font-weight: 700;
				text-align: center;
			}

			ul{
				list-style: none;
				margin: 20px auto 0;
				//border-top: 1px solid #000;
				//border-bottom: 1px solid #000;

				li{
					position: relative;
					margin-top: 10px;

					a{
						display: block;
						font-size: 16px;
						font-weight: 700;
						line-height: 17px;
						color: #000;
						text-decoration: none;
						padding: 15px 25px 15px 10px;
						background-image: url(../images/member/icon-blank.png);
						background-repeat: no-repeat;
						background-position: 97% 50%;
						border: 1px solid #000;
					}
				}
			}

			#askMe{
				margin-top: 40px;
				padding-bottom: 30px;

				h3{
					font-size: 14px;
					font-weight: 500;
					text-align: center;
				}

				.contactBtn{
					width: 100%;
					height: 60px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					font-size: 16px;
					line-height: 1;
					color: #fff;
					font-weight: 700;
					text-decoration: none;
					background-color: #000;
					margin: 15px auto 0;
				}
			}
		}

		@if $type == pc{
			margin-top: 60px;
			padding-top: 40px;
			border-top: 2px solid #e5e5e5;
			
			h2{
				font-size: 24px;
				font-weight: 700;
				text-align: center;
			}
			
			ul{
				list-style: none;
				display: flex;
				justify-content: center;
				margin: 40px auto 0;
				border-top: 1px solid #000;
				border-bottom: 1px solid #000;
				
				li{
					position: relative;
					padding: 0 3vw;
					
					&:not(:last-of-type){
						&:after{
							content: "";
							display: block;
							width: 1px;
							height: 15px;
							background-color: #000;
							position: absolute;
							top: 20px;
							right: 0;
						}
					}
					
					a{
						display: block;
						font-size: 16px;
						font-weight: 700;
						line-height: 17px;
						text-align: center;
						color: #000;
						text-decoration: none;
						padding: 20px 25px 20px 0;
						background-image: url(../images/member/icon-blank.png);
						background-repeat: no-repeat;
						background-position: right 50%;
					}
				}
			}

			#askMe{
				margin-top: 60px;
				padding-bottom: 30px;

				h3{
					font-size: 16px;
					font-weight: 500;
					text-align: center;
				}
				
				.contactBtn{
					width: 480px;
					height: 70px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
					line-height: 1;
					color: #fff;
					font-weight: 700;
					text-decoration: none;
					background-color: #fff;
					transition: all .3s;
					margin: 30px auto 0;
					box-shadow: 4px 6px 0 #d2d2d2;
					border: 2px solid #000;
					position: relative;
					z-index: 1;
					overflow: hidden;
					
					&:after{
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						background-color: #000;
						z-index: -1;
						position: absolute;
						top: 0;
						right: 0;
						transition: all .3s;
					}
					
					&:hover{
						color: #000;
						
						&:after{
							right: -100%;
						}
					}
				}
			}
		}
	}
}


/* ------------------ faq ------------------ */


#faqList, #priceList{
	@if $type == sp{
		border: 1px solid #000;
			margin-top: 40px;

		h3{
			font-size: 18px;
			position: relative;
			border-bottom: 1px solid #000;
			padding: 0 0 5px;
			margin-bottom: -1px;

			&:not(:nth-of-type(1)){
				margin-top: 10px;
			}

			&:before{
				content: "";
				display: block;
				width: 25px;
				height: 3px;
				background-color: #000;
				position: absolute;
				top: 31px;
				right: 10px;
				transition: all .4s;
			}

			&:after{
				content: "";
				display: block;
				width: 3px;
				height: 25px;
				background-color: #000;
				position: absolute;
				top: 20px;
				right: 21px;
				transition: all .4s;
			}

			.tag{
				font-size: 18px;
				font-weight: 700;
				padding: 5px 10px;
				line-height: 1;
				display: block;
				transition: all .4s;
			}

			.ja{
				flex: 1;
				margin-top: 5px;
				display: block;
				padding: 0 10px;
			}

			&.isOpen{

				.tag{
					background-color: $green;
					color: #fff;
				}

				&:before{
					transform: rotate(270deg);
					opacity: 0;
				}

				&:after{
					transform: rotate(270deg);
				}
			}
		}

		.faqCat, .priceCat{
			padding: 0 20px 40px;
			display: none;

			dl{
				margin-top: 30px;
				padding-bottom: 40px;

				//&:not(:last-of-type){
				border-bottom: 1px solid #000;
				//}

				dt{
					font-size: 16px;
					font-weight: 700;

					&:before{
						content: "Q";
						font-size: 20px;
						font-family: $ff_sten;
						color: $green;
						display: inline-block;
					}
				}

				dd{
					font-size: 15px;
					margin-top: 30px;
				}
			}
		}

		.priceCat{

			dl{

				dt{

					span.num{
						font-size: 20px;
						font-family: $ff_sten;
						color: $green;
						display: inline-block;
					}

					&:before{
						display: none;
					}
				}

				dd{
					
					&.fee{
						background-color: #e5e5e5;
						padding: 20px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 18px;
						font-weight: 700;

						span{
							&.sten{
								display: inline-block;
								font-size: 24px;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}

	@if $type == pc{
		border: 1px solid #000;
		padding: 20px 0 0;
		margin-top: 40px;
		
		h3{
			font-size: 18px;
			position: relative;
			display: flex;
			cursor: pointer;
			border-bottom: 1px solid #000;
			padding: 0 20px 30px;
			margin-bottom: -1px;
			
			&:not(:nth-of-type(1)){
				margin-top: 30px;
			}
			
			&:before{
				content: "";
				display: block;
				width: 25px;
				height: 3px;
				background-color: #000;
				position: absolute;
				top: 21px;
				right: 10px;
				transition: all .4s;
			}
			
			&:after{
				content: "";
				display: block;
				width: 3px;
				height: 25px;
				background-color: #000;
				position: absolute;
				top: 10px;
				right: 21px;
				transition: all .4s;
			}
			
			.tag{
				font-size: 24px;
				font-weight: 700;
				padding: 5px 10px 0;
				margin-right: 20px;
				line-height: 1;
				display: inline-block;
				margin-bottom: -5px;
				transition: all .4s;
			}
			
			.ja{
				flex: 1;
				margin-top: 5px;
			}
			
			&.isOpen{
				
				.tag{
					background-color: $green;
					color: #fff;
				}
				
				&:before{
					transform: rotate(270deg);
					opacity: 0;
				}
				
				&:after{
					transform: rotate(270deg);
				}
			}
		}
		
		.faqCat, .priceCat{
			padding: 0 20px 40px;
			display: none;
			
			dl{
				margin-top: 30px;
				padding-bottom: 40px;
				
				//&:not(:last-of-type){
					border-bottom: 1px solid #000;
				//}
				
				dt{
					font-size: 18px;
					font-weight: 700;
					
					&:before{
						content: "Q";
						font-size: 24px;
						font-family: $ff_sten;
						color: $green;
						display: inline-block;
					}
				}
				
				dd{
					font-size: 15px;
					margin-top: 30px;
				}
			}
		}

		.priceCat{
				padding: 0 30px 50px;

			dl{
				display: grid;
				grid-template-rows: 50px 1fr;
				grid-template-columns: 1fr 367px;
				padding: 0;
				margin: 0;

				dt{
					grid-row: 1 / 2;
					grid-column: 1 / 2;
					padding: 30px 30px 15px 0;

					&:before{
						display: none;
					}
					
					span.num{
						font-size: 24px;
						font-family: $ff_sten;
						color: $green;
						display: inline-block;
						margin-right: 10px;
					}
				}

				dd{
					margin: 0;
					
					&.info{
						grid-row: 2 / 3;
						grid-column: 1 / 2;
						padding: 30px;
						
						&.col2{
							grid-row: 2 / 3;
							grid-column: 1 / 3;
						}
					}
					
					&.fee{
						grid-row: 1 / 3;
						grid-column: 2 / 3;
						background-color: #e5e5e5;
						padding: 20px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 18px;
						font-weight: 700;
						
						span{
							&.sten{
								display: inline-block;
								font-size: 24px;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}
}

#priceList{
	@if $type == sp{

		h3{
			padding: 0 0;

			.tag{
				padding: 20px 0 20px 10px;
			}

			&:before{
				top: 26px;
			}

			&:after{
				top: 15px;
			}

			.ja{
				display: none;
			}
		}
	}
}


#packPrice{
	@if $type == sp{
		margin: 30px auto 0;

		h2{
			background-color: #efefef;
			font-size: 16px;
			line-height: 1;
			padding: 10px;
		}

		.btns{

			a{
				margin-top: 20px;
				display: block;
				width: 100%;
				border: 2px solid $green;
				color: #000;
				text-decoration: none;
				font-size: 14px;
				font-weight: 700;
				padding: 10px 30px 10px 10px;
				position: relative;
				transition: all .3s;

				&:after{
					content: "";
					display: block;
					width: 18px;
					height: 18px;
					background-image: url(../images/second/btn-arrow-green.png);
					position: absolute;
					right: 10px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	@if $type == pc{
		margin: 40px auto 0;
		
		h2{
			background-color: #efefef;
			font-size: 24px;
			line-height: 1;
			padding: 20px;
		}
		
		.btns{
			display: flex;
			justify-content: space-between;
			margin-top: 30px;
			
			a{
				display: block;
				width: 48%;
				border: 2px solid $green;
				color: #fff;
				text-decoration: none;
				font-size: 18px;
				font-weight: 700;
				padding: 20px 60px 20px 20px;
				position: relative;
				transition: all .3s;
				z-index: 1;
				box-shadow: 4px 6px 0 #b4e585;
				
				&:after{
					content: "";
					display: block;
					width: 100%;
					height: 100%;
					background-color: $green;
					position: absolute;
					top: 0;
					right: 0;
					z-index: -1;
					transition: all .3s;
				}
				
				&:hover{
					color: $green;
					
					&:after{
						width: 0;
					}
				}
			}
		}
	}
}




.sample{
	@if $type == sp{
	}

	@if $type == pc{
	}
}
