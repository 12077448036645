section.mailForm{
	@if $type == sp{
		width: 90%;
		margin: 0 auto;

		.aform-header, .h2{
			font-size: 22px;
			font-family: $ff_eng;
			text-align: center;
			margin-top: 30px;
			padding-bottom: 10px;
			font-weight: 700;
		}

		.aform-content{

			dl{
				border-top: 2px solid #000;
				padding: 15px 0;
				font-size: 15px;
				line-height: 1.8;

				dt{
					border-bottom: 1px solid $green;

					label{

						.aform-required{
							font-size: 10px;
							color: $green;
							border: 1px solid $green;
							padding: 1px 4px;
							line-height: 1;
							display: inline-block;
							margin-left: 5px;
						}
					}
				}

				dd{
					padding-top: 10px;
					
					textarea{
						display: block;
						width: 100%;
					}

					input.aform-input,
					select{
						width: 100%;
						height: 40px;
						padding: 0 15px;

						&:focus{
							border: 1px solid $green;
							outline: none;
						}
					}

					ul{
						list-style: none;
					}
					
					.aform-error{
						
						.error{
							font-size: 12px;
							color: #fff;
							background-color: #f00;
							padding: 5px;
							font-weight: 700;
						}
					}
					input[type = checkbox],input[type = radio]{
						display: none;
					}
					.aform-checkbox-ul{
						label{
							position: relative;
							padding-left: 35px;
							display: inline-block;

							&:before{
								content: "";
								display: block;
								width: 15px;
								height: 15px;
								background-color: #fff;
								border: 1px solid #000;
								position: absolute;
								left: 8px;
								top: 6px;
							}
							&:hover{
								cursor: pointer;
							}
						}
						&.aform-field-40{
							label{
								display: block;
							}
							input[type = text]{
								display: block;
								width: calc(100% - 35px);
								margin-left: 35px;
							}
						}
						li{
							display: block;
							width: 100%;
							margin-bottom: 10px;
						}
					} 
					input[type = checkbox] + label:after{
						content: '';
						position: absolute;
						width: 0;
						height: 0;
					}
					input[type = checkbox]:checked + label:after{
						content: '';
						position: absolute;
						width: 6px;
						height: 15px;
						border-bottom: 1px solid;
						border-right: 1px solid;
						transform: rotate(45deg);
						left: 13px;
						top: 2px;
					}
					.aform-field-option-text{
						display: inline-block;
					}
					.aform-radio-ul{
						label{
							padding-left: 25px;
							position: relative;
							cursor: pointer;
							
							&::before{
								content: '';
								width: 16px;
								height: 16px;
								position: absolute;
								border: 1px solid;
								left: 0;
								top: 2px;
								border-radius: 50%;
							}
						}
						input[type = radio] + label:after{
							content: '';
							position: absolute;
							width: 0;
							height: 0;
						}
						input[type = radio]:checked + label:after{
							content: '';
							position: absolute;
							width: 8px;
							height: 8px;
							border-radius: 50%;
							background-color: #000;
							top: 7px;
							left: 5px;
						}
					}
				}
			}

			.aform-button-area{
				text-align: center;
				padding: 30px 0;

				input[type = submit]{
					width: 100%;
					height: 50px;
					background-color: $green;
					border-right: 0;
					border: none;
					color: #fff;
					font-size: 18px;
					font-weight: 700;
					box-shadow: 0 5px 0 #a2ce77;
					margin-bottom: 10px;
				}

				input[type = button]{
					width: 100%;
					height: 50px;
					background-color: #ccc;
					border: none;
					color: #fff;
					font-size: 18px;
					font-weight: 700;
					box-shadow: 4px 6px 0 #eee;
					margin-bottom: 10px;
				}
			}
		}
	}

	@if $type == pc{
		max-width: 1200px;
		width: 95%;
		margin: 0 auto;

		.aform-header, .h2{
			font-size: 34px;
			font-family: $ff_eng;
			text-align: center;
			margin-top: 60px;
			padding-bottom: 20px;
			font-weight: 700;
		}

		.aform-content{
			
			dl{
				display: flex;
				border-top: 2px solid #000;
				padding: 30px 0;
				font-size: 15px;
				line-height: 1.8;
				
				dt{
					width: 30%;
					padding-left: 40px;
					
					label{
						
						.aform-required{
							font-size: 10px;
							color: $green;
							border: 1px solid $green;
							padding: 1px 4px;
							line-height: 1;
							display: inline-block;
							margin-left: 5px;
						}
					}
				}
				
				dd{
					flex: 1;
					padding-right: 40px;
					
					textarea{
						display: block;
						width: 100%;
					}
					
					input.aform-input,
					select{
						width: 100%;
						height: 40px;
						padding: 0 15px;
						
						&:focus{
							border: 1px solid $green;
							outline: none;
						}
					}
					
					ul{
						list-style: none;
					}

					.aform-error{

						.error{
							font-size: 12px;
							color: #fff;
							background-color: #f00;
							padding: 5px;
							font-weight: 700;
						}
					}
					input[type = checkbox],input[type = radio]{
						display: none;
					}
					.aform-checkbox-ul{
						label{
							position: relative;
							padding-left: 35px;
							display: inline-block;

							&:before{
								content: "";
								display: block;
								width: 15px;
								height: 15px;
								background-color: #fff;
								border: 1px solid #000;
								position: absolute;
								left: 8px;
								top: 6px;
							}
							&:hover{
								cursor: pointer;
							}
						}
						&.aform-field-40{
							label{
								display: inline-block;
								width: 200px;
							}
							input[type = text]{
								width: 400px;
							}
						}
						li{
							margin-bottom: 10px;
						}
					} 
					input[type = checkbox] + label:after{
						content: '';
						position: absolute;
						width: 0;
						height: 0;
					}
					input[type = checkbox]:checked + label:after{
						content: '';
						position: absolute;
						width: 6px;
						height: 15px;
						border-bottom: 1px solid;
						border-right: 1px solid;
						transform: rotate(45deg);
						left: 13px;
						top: 2px;
					}
					.aform-field-option-text{
						display: inline-block;
						margin-left: 20px;
					}
					.aform-radio-ul{
						label{
							padding-left: 25px;
							position: relative;
							cursor: pointer;
							
							&::before{
								content: '';
								width: 16px;
								height: 16px;
								position: absolute;
								border: 1px solid;
								left: 0;
								top: 2px;
								border-radius: 50%;
							}
						}
						input[type = radio] + label:after{
							content: '';
							position: absolute;
							width: 0;
							height: 0;
						}
						input[type = radio]:checked + label:after{
							content: '';
							position: absolute;
							width: 8px;
							height: 8px;
							border-radius: 50%;
							background-color: #000;
							top: 7px;
							left: 5px;
						}
					}
				}
			}
			
			.aform-button-area{
				text-align: center;
				padding: 30px 0;
				
				input[type = submit]{
					width: 40%;
					height: 70px;
					background-color: $green;
					border-right: 0;
					border: none;
					color: #fff;
					font-size: 18px;
					font-weight: 700;
					box-shadow: 4px 6px 0 #a2ce77;
				}

				input[type = button]{
					width: 25%;
					height: 70px;
					background-color: #ccc;
					border: none;
					color: #fff;
					font-size: 18px;
					font-weight: 700;
					box-shadow: 4px 6px 0 #eee;
					margin-right: 10px;
				}
			}
		}
	}
	&.mediaForm{
		.dl_title{
			border-top: 2px solid #fff;
		}
		.aform-content{
			dl{
				border-top: 2px solid #fff;
				background-color: #f2f2f2;
				
			}
		}
	}
}



.sample{
	@if $type == sp{
	}

	@if $type == pc{
	}
}
